import React, { useState,useEffect,useContext } from 'react';
import './miner.scss';
import GoodsItem from '../../components/GoodsItem/GoodsItem';
import FreeMiningItem from '../../components/FreeMiningItem/FreeMiningItem';
import lsRequest from '../../utils/lsRequest';
import NoData from '../../components/NoData/NoData';
import { LanguageContext } from '../../contexts/LanguageContext';
import GoodsDetailTitle from '../../components/GoodsDetailTitle/GoodsDetailTitle';

import Iconimg0 from '../../assets/miner-sale-0.webp';
import Iconimg1 from '../../assets/miner-sale-1.webp';
import Iconimg2 from '../../assets/miner-sale-2.webp';
import Iconimg3 from '../../assets/miner-sale-3.webp';

const InitIconImg = ({index})=>{
    switch (index) {
        case 0:
            return <img className='miner-icon' src={Iconimg0} alt='' />
            break;
        case 1:
            return <img className='miner-icon' src={Iconimg1} alt='' />
            break;
        case 2:
            return <img className='miner-icon' src={Iconimg2} alt='' />
            break;
        default:
            return <img className='miner-icon' src={Iconimg3} alt='' />
            break;
    }
}

export default function Miner(){
    const [pType,setPtype] = useState(1);//pType:1矿机 2 算力
    const [minerList,setMinerList] = useState([]);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getMinerList = async()=>{
        const resData = await lsRequest('/powerPackage/getPackageList','POST',{packageType:pType,l:language.requestType});
        if(resData.data&&resData.data.list.length>0){
            setMinerList(resData.data.list);
        }
        
    }

    useEffect(()=>{
        getMinerList()
    },[])

    return (
        <div className='body-content miner'>
            <div className='h-w1300'>
                <GoodsDetailTitle title={language.purchaseMiners} />
                <div className='miner-step'>
                    {
                        language.minerDesc.map((item,index)=>{
                            return <div className='miner-step-item' key={index}>
                                <div className='miner-step-title'>
                                    <InitIconImg index={index} />
                                    <h4 className='title'>{item.title}</h4>
                                </div>
                                <p className='desc'>{item.desc}</p>
                            </div>
                        })
                    }
                </div>
                {
                    minerList.length>0?<div className='goods-list'>
                        {
                            minerList.map((item,index)=>{
                                return <div className='goods-item' key={index}>
                                    {item.isNewBuy===1?<FreeMiningItem info={item} packageType={pType} language={language} />:<GoodsItem info={item} packageType={pType} language={language} />}
                                </div>
                            })
                        }
                    </div>:<div style={{marginTop:'100px'}}><NoData language={language} /></div>
                }
            </div>

        </div>
    )
}