import React,{useEffect, useState,useContext} from 'react';
import { useParams,Link } from 'react-router-dom';
import './withdrawal.scss';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import WalletTags from '../../components/WalletTags/WalletTags';
import { Select,InputNumber,Button, message,Breadcrumb } from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import AddAddressModal from '../../components/AddAddressModal/AddAddressModal';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Withdrawal(){
    const [tags,setTags] = useState([]);
    const [tagsIndex,setTagsIndex] = useState(0);
    const [chainInfo,setChainInfo] = useState({});
    const [openModal,setOpenModal] = useState(false);
    const [addressList,setAddressList] = useState([]);
    const [newAddress,setNewAddress] = useState('');
    const [newAmount,setNewAmount] = useState('');
    const [actualAmount,setActualAmount] = useState(0);
    const [accountInfo,setAccountInfo] = useState({});

    const params = useParams();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const changeTags = (index)=>{
        setTagsIndex(index);
        setChainInfo(tags[index]);
        getCoinAddress(tags[index].chainName);
    }

    const getAccountInfo = async()=>{
        const resData = await lsRequest('/memberAccount/getMemberAccountList','POST',{l:language.requestType},token);

        if(resData){
            let newArr = resData.data.list.filter(item=>item.coinName===params.coinName);
            setAccountInfo(newArr[0]);
        }
    }

    const getCoinChain = async()=>{
        const resData = await lsRequest('/coinInfo/getCoinChain','POST',{
            coinName:params.coinName,
            l:language.requestType
        },token);

        if(resData&&resData.data.list.length>0){
            let arr = resData.data.list.map((item,index)=>{
                item.title = item.chainName;
                item.index = index;
                return item;
            })
            setChainInfo(arr[tagsIndex]);
            setTags(arr);
            getCoinAddress(arr[tagsIndex].chainName);
        }
    }

    const getCoinAddress = async(chainName)=>{
        const resData = await lsRequest('/memberCoinAddress/myCoinAddress','POST',{
            coinName:params.coinName,
            chainName,
            l:language.requestType,
            addressType:1//1内转
        },token);

        if(resData){
            let arr = resData.data.list.map((item)=>{
                item.value = item.addressValue;
                item.lable = item.addressName;
                return item;
            })
            setAddressList(arr);
        }
    }

    const getAddrData=async(addr,note)=>{
        let cname = tags[tagsIndex].chainName
        const resData = await lsRequest('/memberCoinAddress/addCoinAddress','POST',{
            coinName:params.coinName,
            chainName:cname,
            addressType:1,//1内转
            addressName:note,
            addressValue:addr,
            l:language.requestType
        },token);

        if(resData){
            getCoinAddress(cname);
        }
    }

    const selectChange = (value) => {
        if(value){
            setNewAddress(value);
        }
        else{
            setNewAddress('');
        }
    };

    const onChangeNum = (value) => {
        let num = 0;
        if(value>chainInfo.withdrawFee){
            num = formatDecimal(value - chainInfo.withdrawFee,2);
        }else{
            message.info(language.withdralQuantityTips);
        }
        setNewAmount(value);
        setActualAmount(num);
    };

    const getModalState=(status)=>{
        setOpenModal(status)
    }

    const withdrawApply = async()=>{
        if(!newAddress){
            message.info(language.selectWalletAddressTips);
            return false;
        }
        if(newAmount>Number(accountInfo.usableAmount)){
            message.info(language.balanceTips);
            return false;
        }
        if(newAmount<Number(accountInfo.withdrawMinAmount)){
            message.info(language.minimumNumberTips+accountInfo.withdrawMinAmount);
            return false;
        }

        const resData = await lsRequest('/withdraw/withdrawApply','POST',{
            coinName:params.coinName,
            transAmount:newAmount,
            address:newAddress,
            chainId:tags[tagsIndex].id,
            l:language.requestType
        },token);

        if(resData){
            message.success(language.successfulApplication);
            getAccountInfo();
        }
    }

    useEffect(()=>{
        getCoinChain();
        getAccountInfo();
    },[])

    return (
        <div style={{padding:'15px 50px'}}>
            <Breadcrumb
                items={[
                    {title:language.dashboardTitle, },
                    {title: <Link to={'/dashboard/wallet'}>{language.myWalletTxt}</Link>,},
                    {title:language.withdrawalTxt,}
                ]}
            />
            <p className='deposit-title'>
                <i className='bitfont bitqianbao'></i>
                {params.coinName} {language.withdrawalTxt}
            </p>
            <div className='withdraw-box'>
                <WalletTags tags={tags} tagsIndex={tagsIndex} getTagesIndex={changeTags} />
                <div className='select-address'>
                    <Select
                        allowClear
                        style={{ width: '100%',}}
                        placeholder={language.selectWalletAddressTips}
                        defaultValue={[]}
                        onChange={selectChange}
                        options={addressList}
                    />
                    <PlusCircleOutlined className='add-btn' onClick={()=>getModalState(true)} />
                </div>
                <div style={{marginTop:'15px'}}>
                    <div className='ipt-amount'>
                        <span>{language.withdrawalsNum}</span>
                        <InputNumber
                            style={{width:'150px',textAlign:'left'}} 
                            min={accountInfo.withdrawMinAmount} 
                            max={100000000} 
                            placeholder={language.numberTxt}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g,'')} 
                            onChange={onChangeNum}
                        />
                    </div>
                    <p className='withdraw-tips'>{language.withdrawMinimumAmount} <span className='amount'>{accountInfo.withdrawMinAmount}</span></p>
                    <p className='withdraw-tips'>{language.walletBalance} <span className='amount'>{accountInfo.usableAmount}</span></p>
                    <p className='withdraw-tips'>{language.gasFee} <span className='amount'>{chainInfo.withdrawFee}</span></p>
                    <p className='withdraw-tips'>{language.actualReceipt} <span className='big-amount'>{actualAmount}</span></p>
                    <Button shape="round" type='primary' style={{width:'200px',marginTop:'15px'}} onClick={()=>withdrawApply()}>
                        {language.confirmBtnTxt}
                    </Button>
                </div>
            </div>

            <AddAddressModal coinName={params.coinName} isShow={openModal} setAddrData={getAddrData} SetHandleCancel={getModalState} language={language} />
        </div>
    )
}