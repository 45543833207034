import React from 'react';
import './bitcoupon.scss';
import {Tabs,Carousel,ConfigProvider } from 'antd';

import MyPurchases from './MyPurchases/MyPurchases';

import ToSubscribe from './ToSubscribe/ToSubscribe';

const curRef = React.createRef();
const tabItems = [
    {
      key: '0',
      label: 'My Sale'
    },
    {
      key: '1',
      label: 'My Purchases',
    },
    {
        key:'2',
        label:'My Coupons'
    },
    {
        key:'3',
        label:'To Subscribe'
    }
];

export default function BitCoupon(){

    const tabChange = (index) => {
        curRef.current.goTo(index);
    };

    return (
        <ConfigProvider
                theme={{
                components:{
                    Tabs:{
                        itemActiveColor:'#c00',
                        itemHoverColor:'#c00',
                        itemSelectedColor:'#c00',
                        inkBarColor:'#c00',
                        fontWeightStrong:true
                    },
                    Table:{
                        footerColor:'#c00',
                        footerBg:'#c00',
                        fontWeightStrong:450
                    }
                }
            }}
        >
            <Tabs defaultActiveKey="0" size='large' items={tabItems} onChange={tabChange} />
            <Carousel dots={false} ref={curRef} waitForAnimate={true}>
                <div>
                    My Sales
                </div>
                <div>
                    <MyPurchases list={[]} />
                </div>
                <div>
                    My Coupon
                </div>
                <div>
                    <ToSubscribe list={[]} />
                </div>
            </Carousel>
        </ConfigProvider>
    )
}