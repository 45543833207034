import btcImg from '../assets/icon-btc.png';
import ethImg from '../assets/icon-eth.png';
import filImg from '../assets/icon-fil.png';
import usdtImg from '../assets/icon-usdt.png';

const initCoinImg = (coinName)=>{
    let name = coinName.toLowerCase();
    switch (name) {
        case 'btc':
            return <img style={{width:'28px'}} src={btcImg} alt='' />
        case 'usdt':
            return <img style={{width:'28px'}} src={usdtImg} alt='' />
        case 'fil':
            return <img style={{width:'28px'}} src={filImg} alt='' />
        case 'eth':
            return <img style={{width:'28px'}} src={ethImg} alt='' />
        default:
            return <span>{coinName}</span>
            break;
    }
}

export default initCoinImg;