import React,{useEffect, useState,useContext} from 'react';
import { useParams,Link } from 'react-router-dom';
import './deposit.scss';
import { QRCode,message,Breadcrumb } from 'antd'
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import WalletTags from '../../components/WalletTags/WalletTags';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Deposit(){
    const [tags,setTags] = useState([]);
    const [tagsIndex,setTagsIndex] = useState(0);
    const [depositInfo,setDepositInfo] = useState({});

    const params = useParams();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    // Copy Success
    message.config({
        top: 300,
        duration: 2,
        contentPadding:'15px 50px'
    });
    
    const handleCopy = () => {
        message.success({
            content:language.copySuccess,
        });
    }

    const changeTags = (index)=>{
        setTagsIndex(index);
        setDepositInfo(tags[index]);
    }

    const getDepositAddress = async()=>{
        const resData = await lsRequest('/coinDepositAddress/getDepositAddress','POST',{
            coinName:params.coinName,
            l:language.requestType
        },token);

        if(resData&&resData.data.list.length>0){
            let arr = resData.data.list.map((item,index)=>{
                item.title = item.chainTypeName;
                item.index = index;
                return item;
            })
            setDepositInfo(arr[tagsIndex]);
            setTags(arr);
        }
    }

    useEffect(()=>{
        getDepositAddress()
    },[])

    return (
        <div style={{padding:'15px 50px'}}>
            <Breadcrumb
                items={[
                    {title:language.dashboardTitle, },
                    {title: <Link to={'/dashboard/wallet'}>{language.myWalletTxt}</Link>,},
                    {title:language.depositTxt,}
                ]}
            />
            <p className='deposit-title'>
                <i className='bitfont bitqianbao'></i>
                {params.coinName} {language.depositTxt}
            </p>
            <div className='deposit-box'>
                <WalletTags tags={tags} tagsIndex={tagsIndex} getTagesIndex={changeTags} />
                <QRCode
                    errorLevel="H"
                    value={depositInfo.address}
                    icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                />
                <p className='deposit-addr'>
                    {depositInfo.address}
                    <CopyToClipboard text={'WAQ4FZ'} onCopy={() => { handleCopy('WAQ4FZ') }}>
                        <i className='bitfont bitfuzhi'></i>
                    </CopyToClipboard>
                </p>
            </div>

        </div>
    )
}