import React from 'react';
import { Table,ConfigProvider,Empty } from 'antd';

const tableHeader = [
    {
        title:'Name',
        dataIndex:'name',
        value:'name'
    },
    {
        title:'Type',
        dataIndex:'type',
        value:'type'
    },
    {
        title:'Coupons Total Amount',
        dataIndex:'camount',
        value:'camount'
    },
    {
        title:'Total Order Amount',
        dataIndex:'oamount',
        value:'oamount'
    },
    {
        title:'Status',
        dataIndex:'status',
        value:'status',
        filters:[
            {
                text:'All',
                value:'all'
            },
            {
                text:'Unpaid',
                value:'unpaid'
            },
            {
                text:'Completed',
                value:'completed'
            },
            {
                text:'Canceled',
                value:'canceled'
            }
        ],
        filterSearch:true,
        filterMultiple:false
    }
]

const customEmptyText = `Please click the "Subscribe Now" to subscribe to the coupon you want!`;

export default function MyPurchases({list}){

    const locale =()=>{
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={customEmptyText} /> 
    };

    return (
        <>
            <div className='common-page-header'>
                <div className='title' style={{fontWeight:'normal'}}>OrderList Fee</div>
                <div className='header-btn-group'>
                    <button className='last-btn'>Buy</button>
                </div>
            </div>
            <ConfigProvider renderEmpty={locale}>
                <Table size='small' columns={tableHeader} dataSource={list} />
            </ConfigProvider>
            
        </>
    )
}