import React,{useState,useEffect,useContext} from 'react';
import {Button,Checkbox,Modal,message} from 'antd';
import './loginModal.scss';
import {useWeb3Modal} from '@web3modal/wagmi/react';
import { useDisconnect,useAccount,useSignMessage} from 'wagmi';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import loginBg from '../../assets/login_bg.png';
import baseName from '../../utils/baseName';



const LoginModal = ({isShow,SetHandleCancel,language,setToLoginStatus,setToSignupStatus})=>{
    const [agreeStatus,setAgreeStatus] = useState(false);
    const [isRequesting,setIsRequesting] = useState(false);
    const {toggleAuth} = useContext(AuthContext);

    const {open} = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { address} = useAccount();
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
        message: 'huanyinghaozidenglu'
    })

    const connectWallet= ()=>{
        if(!agreeStatus){
            message.info(language.agreeUserAgreementTips);
            return false;
        }
        open();
    }
    const disconnectWallect = ()=>{
        disconnect();
    }

    const onChange = (e) => {
        setAgreeStatus(e.target.checked);
    };

    const handleCancel = (state) => {
        SetHandleCancel(state)
    };


    const toSign = ()=>{
        if(!address){
            message.info(language.connectWalletTips);
            return false;
        }

        if(!agreeStatus){
            message.info(language.agreeUserAgreementTips);
            return false;
        }

        signMessage();
        setIsRequesting(true);

    }

    const toLogin = async()=>{
        setIsRequesting(false);
        let reqData = {
            address:address,
            signature:data,
            message:'huanyinghaozidenglu'
        };

        const resData = await lsRequest('/login','POST',reqData);
        
        if(resData){
            message.success(language.loginSuccessfulTips);
            resData.data.oldAddress = address;
            setTimeout(() => {
                handleCancel(false);
                toggleAuth(resData.data);
            }, 2000);
        }
    }

    useEffect(()=>{
        if(isSuccess&&isRequesting){
            toLogin();
        }
    },[isSuccess])

    return (
        <>
            <Modal zIndex={99} open={isShow} onCancel={()=>handleCancel(false)} centered footer={null} width={700}>
                <div className="login-modal">
                    <div>
                        <img className='login-bg' src={loginBg} alt="" />
                    </div>
                    <div className="login-content">
                        <div className="login-title">{language.loginTo} {baseName}</div>
                        {
                            !address?<Button onClick={connectWallet} className="connect-btn" shape='round' type="primary" size='large' block>{language.connectWalletTxt}</Button>:
                            <>
                                <div className="login-continue">
                                    <span className='bitfont bityonghutouxiang'></span>
                                    {address}
                                    <Button size='small' className="dis" shape='round' onClick={disconnectWallect}>{language.DISCONNECT}</Button>
                                </div>
                                <Button onClick={()=>toSign()} className="connect-btn" shape='round' type="primary" size='large' block>{language.continueTxt}</Button>
                            </>
                        }
                        <div className="login-check">
                            <Checkbox className="check" onChange={onChange}></Checkbox>
                            {language.haveReadAgree} <span className="ruler">{language.userLoginAgreement}</span>; 
                        </div>
                        <div className="login-footer">
                            {language.NotMember}?<Button className="sign-up" type="link" onClick={()=>{
                                setToLoginStatus(false);
                                setToSignupStatus(true);
                            }} >{language.signupNow}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LoginModal;