import React,{useEffect, useState,useContext} from 'react';
import { Carousel,ConfigProvider } from 'antd'; 
import './home.scss';
import InitTab from './Tab/Tab';
import TabContent from './TabContent/TabContent';
import WorldLeader from './WorldLeader/WorldLeader';
import AppDownload from './AppDownload/AppDownload';
import NeedHelp from './NeedHelp/NeedHelp';
import Partners from './Partners/Partners';
import FreeTrial from '../../components/FreeTrial/FreeTrial';
import TabTelegram from '../../components/TelegramEmail/TelegramEmail';
import NoData from '../../components/NoData/NoData';
import lsRequest from '../../utils/lsRequest';
import baseUrl from '../../utils/baseUrl';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Home(){
    const [tabIndex,setTabIndeex] = useState(0);
    const [advList,setAdvList] = useState([]);
    const [cloudList,setCloudList] = useState([]);
    const [miningList,setMiningList] = useState([]);

    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getTabIndex = (index)=>{
        setTabIndeex(index);
    }

    const getAdv = async()=>{
        const resData = await lsRequest('/adv','POST',{l:language.requestType});
        if(resData){
            if(resData.data){
                setAdvList(resData.data);
            }
           
        }
        
    }

    const getCouldList = async()=>{
        const resData = await lsRequest('/recommendIndex','POST',{packageType:2,l:language.requestType});
        if(resData){
            setCloudList(resData.data);
        }
        
    }

    const getMiningList = async()=>{
        const resData = await lsRequest('/recommendIndex','POST',{packageType:1,l:language.requestType});
        if(resData){
            setMiningList(resData.data);
        }
        
    }

    useEffect(()=>{
        getAdv();
        getCouldList();
        getMiningList();
    },[])

    return (
        <ConfigProvider
            theme={{
                token: {
                    Carousel:{
                        dotWidth:40,
                        dotHeight:4,
                        dotActiveWidth:45
                    }
                },
            }}
        >
            <div className="home-banner">
                <Carousel autoplay>
                    {
                        advList.length>0?advList.map((item,index)=>{
                            return <div key={index}>
                                <img className='bannerStyle' src={`${baseUrl+item.pic}`} alt={item.title} />
                            </div>
                        }):<div>
                            <NoData txt={language.bannerTips} />
                        </div>
                    }
                </Carousel>
            </div>
            <div className='ls-box'>
                <InitTab tabIndex={tabIndex} setTabIndex = {getTabIndex} language={language} />
                <TabContent cloudList={cloudList} miningList={miningList} tabIndex={tabIndex} language={language} />
                <TabTelegram />
                {/* <FreeTrial language={language} /> */}
                <WorldLeader language={language} />
                {/* <AppDownload language={language} /> */}
                <NeedHelp language={language} />
                <Partners language={language} />
            </div>
        </ConfigProvider>
    )
}