import React,{ useContext } from 'react';
import './language.css';
import { LanguageContext } from '../../contexts/LanguageContext';
import {Dropdown} from 'antd';



const LanguageToggle = ()=>{
	const {toggleLanguage,langType,en,hk} = useContext(LanguageContext);
	const language = langType?en:hk;

	const items = [
		{
			key: '100',
			label: (<div className='lang-box' onClick={()=>toggleLanguage('en')}>
				<span className='lang-item'>
					<span className='earth-icon earth-icon-en'></span>
				</span>
				English
			</div>)
		},
		{
			key: '101',
			label: (<div className='lang-box' onClick={()=>toggleLanguage('hk')}>
				<span className='lang-item'>
					<span className='earth-icon earth-icon-hk'></span>
				</span>
				繁體中文
			</div>)
		}
	];
	
	return(
		<>
			<Dropdown
				menu={{
					items,
					selectable: true
				}}
				placement="bottom"
				arrow
			>
				<div className='lang-switcher'>
					<span className={`earth-icon earth-icon-${langType}`}></span>
					<span className='bitfont bitxialajiantou'></span>
				</div>
			</Dropdown>
		</>
		
	)
}

export default LanguageToggle;