import React,{useState,useEffect,useContext} from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import CloudFullInfo from './CloudFullInfo/CloudFullInfo';
import MinerFullInfo from './MinerFullInfo/MinerFullInfo';
import GoodsDetailTitle from '../../components/GoodsDetailTitle/GoodsDetailTitle';
import StaticOutput from '../../components/StaticOutput/StaticOutput';
import ProductDetail from '../../components/ProductDetail/ProductDetail';
import Faq from '../../components/Faq/Faq';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import lsRequest from '../../utils/lsRequest';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { message } from 'antd';

export default function Full(){
    const [detaiInfo,setDetailInfo] = useState({});
    const [minerPrice,setMinerPrice] = useState(0);
    const [servicePrice,setServicePrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);
    const [minerNum,setMinerNum] = useState(1);
    const [serviceDay,setServiceDays] = useState(0);

    const navigate = useNavigate();
    const params = useParams();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getDetailInfo = async()=>{
        const {data} = await lsRequest('/powerPackage/getMinerPackageDetail','POST',{minerPackageId:params.pid,l:language.requestType});
        let num = data.packageType==2?data.calPower:1;
        setMinerNum(num);
        setServiceDays(data.minElectricDay);
        setDetailInfo(data);
    }

    const initPrice = (num,days)=>{
        if(detaiInfo.packageType==2){
            // 算力
            setMinerPrice(formatDecimal(num*detaiInfo.minerFee/detaiInfo.calPower,3)); 
        }
        else{
            // 矿机
            setMinerPrice(formatDecimal(num*detaiInfo.minerFee,3)); 
        }
        setServicePrice(formatDecimal(num*detaiInfo.serviceFee*days,3));
        setTotalPrice(formatDecimal(parseFloat(minerPrice) + parseFloat(servicePrice) + parseFloat(detaiInfo.minerPositionFee),3));
    }

    const getMinerPower=(num)=>{
        setMinerNum(num);
    }

    const getServiceDays=(days)=>{
        setServiceDays(days);
    }

    const toPayOrder=()=>{
        if(!token){
            message.info(language.loginTips);
            return false;
        }
        let orderData={
            amount:minerNum,
            days:serviceDay,
            minerPrice:minerPrice,
            servicePrice:servicePrice,
            total:totalPrice,
            positionFee:detaiInfo.minerPositionFee
        }
        orderData = JSON.stringify(orderData);

        localStorage.setItem('orderData',orderData);

        navigate(`/fullPay/${detaiInfo.package_id}`);
    }

    useEffect(()=>{
        getDetailInfo();
    },[])

    useEffect(()=>{
        initPrice(minerNum,serviceDay);
    },[detaiInfo,minerPrice,servicePrice,minerNum,serviceDay])

    return (
        <div className='h-w1300' style={{paddingBottom:'50px'}}>
            {
                detaiInfo.packageType===2?<CloudFullInfo 
                setMinerPower={getMinerPower} 
                setServiceDays = {getServiceDays}
                toPay = {toPayOrder}
                info={detaiInfo} 
                minerPrice={minerPrice} 
                servicePrice={servicePrice} 
                totalPrice={totalPrice} 
                language={language}
            />:<MinerFullInfo 
                setMinerPower={getMinerPower} 
                setServiceDays = {getServiceDays}
                toPay = {toPayOrder}
                info={detaiInfo} 
                minerPrice={minerPrice} 
                servicePrice={servicePrice} 
                totalPrice={totalPrice} 
                language={language}
            />
            }
            
            <GoodsDetailTitle title={language.productIntroduction} />
            {/* <StaticOutput /> */}
            <ProductDetail info={detaiInfo} language={language} />
            <GoodsDetailTitle title={language.FAQsTxt} />
            <Faq language={language} />
            <GoodsDetailTitle title={language.disclaimerTxt} />
            <Disclaimer language={language} />
        </div>
    )
}