import React, { useEffect,useContext, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { Table,Breadcrumb } from 'antd';
import { LanguageContext } from '../../contexts/LanguageContext';
import OrderStatus from '../../utils/orderStatus';

export default function OrderDetail(){
    const [orderInfo,setOrderInfo] = useState();

    const {token} = useContext(AuthContext);
    const params = useParams();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'name',
            value:'name',
            align:'right',
            render:(_,{name})=>(
                <p style={{marginRight:'30px',color:'#666'}}>{name}</p>
            )
        },
        {
            title:language.DataTxt,
            dataIndex:'data',
            value:'data',
            render:(_,{value})=>(
                <p style={{marginLeft:'30px',fontSize:'16px'}}>{value}</p>
            )
        }
    ]
    
    const getOrderDetail = async()=>{
        const {data} = await lsRequest('/memberPower/getMinerDetail','POST',{
            memberMinerId:params.id
        },token);
        
        if(data){
            let newArr = [
                {
                    name:language.OrderNo, value:data.orderSerial
                },
                {
                    name:language.MinerName, value:data.minerName
                },
                {
                    name:language.orderStatus, value: <OrderStatus status={data.minerStatus} language={language} />
                },
                {
                    name:language.CurrencyType, value:data.coinName
                },
                {
                    name:language.CreationTime, value:data.createDate
                },
                {
                    name:language.ShelfDate, value:data.shelfDate
                },
                {
                    name:language.Dateofexcavation, value:data.digStartDate
                },
                {
                    name:language.QuantityTxt, value:data.orderType===2?data.totalCalPower+' '+data.powerUnit:data.goodsNumber+' '+language.unitTxt
                },
                {
                    name:language.totalPower, value:data.totalCalPower+data.powerUnit
                },
                {
                    name:language.minerFeeTxt, value:'$ '+data.minerFee
                },
                {
                    name:language.orePrice, value:'$ '+data.minerPositionFee
                },
                {
                    name:data.orderType===2?language.serviceFeeTxt:language.electricityCosts, value:'$ '+data.prepayElectricFee
                },
                {
                    name:language.TotalFee, value:'$ '+data.totalFee
                }
            ];
            setOrderInfo(newArr)
        }
    }

    useEffect(()=>{
        getOrderDetail();
    },[])

    return (
        <div>
            <Breadcrumb
                items={[
                    {title:  language.dashboardTitle},
                    {title: <Link to={'/dashboard/myorders'}>{language.myOrderTitle}</Link>},
                    {title: language.detailsTxt },
                ]}
            />
            <Table 
                style={{marginTop:'30px',width:'500px'}}
                rowKey={'value'}  
                bordered  
                size='small'
                columns={tableHeader} 
                dataSource={orderInfo} 
                pagination={false}
                showHeader={false}
            />
        </div>
    )
}