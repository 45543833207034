import React,{useEffect, useState,useContext} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './orepay.scss';
import { Checkbox,Table,message} from 'antd';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import CanUseCoupon from '../../components/CanUseCoupon/CanUseCoupon';
import NoCoupons from '../../components/NoCoupons/NoCoupons';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';
import VerifyTipsModal from '../../components/VerifyTipsModal/VerifyTipsModal';
import ConfrimPayModal from '../../components/ConfirmPayModal/ConfirmPayModal';

export default function OrePay(){
    const [orderInfo,setOrderInfo] = useState([]);
    const [isOpenVerify, setIsOpenVerify] = useState(false);
    const [isOpenPay,setIsOpenPay] = useState(false);
    const [totalOldPrice,setTotalOldPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);
    const [agreeStatus,setAgreeStatus] = useState(false);
    const [useCoupontList,setUseCoupontList] = useState([]);
    const [amountCoupon,setAmountCoupon] = useState(0);//优惠券优惠金额
    const [cId,setCId] = useState('');

    const {token} = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const orderData = JSON.parse(localStorage.getItem('oreInfoData'));

    const getOrderInfo = async()=>{
        const {data} = await lsRequest('/oreLocation/getOreDetail','POST',{oreId:params.id,l:language.requestType});
        setTotalPrice(orderData.orePrice);
        setTotalOldPrice(orderData.orePrice);
        getCanuseCoupon(params.id,orderData.amount);
        let newArr = [
            {
                id:0,
                name:data.oreName,
                price:data.oreCoinFee,
                amount:orderData.amount+language.copyTxt,
                days:data.periodDays+language.daysTxt,
                total:'$'+orderData.orePrice
            }
        ]
        setOrderInfo(newArr);
    }

    const agreeChange = (e)=>{
        setAgreeStatus(e.target.checked);
    }

    const showOpenVerify = () => {
        setIsOpenVerify(true);
    };
    const handleVerifyOk = () => {
        setIsOpenVerify(false);
    };
    const handleVerifyCancel = () => {
        setIsOpenVerify(false);
    };

    const toPayOrder=()=>{
        if(!agreeStatus){
            message.info(language.minerAgreeTips);
            return false;
        }
        setIsOpenPay(true);
    }

    const submitOrder=async()=>{
        // 支付验证身份
        const resData = await lsRequest('/oreLocation/checkBuyAuthority','POST',{
            oreId:params.id,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            confirmOrder(resData.data);
        }
    }

    const confirmOrder = async(billId)=>{
        const resData = await lsRequest('/oreLocation/submitOreWithBalance','POST',{
            goodsNum:orderData.amount,
            couponId:cId,
            oreId:params.id,
            coinName:'USDT',
            l:language.requestType,
            IsUsedRedPacket:1
        },token);

        if(resData&&resData.code===200){
            message.success(language.paymentSuccessful);
            setIsOpenPay(false);
            setTimeout(() => {
                navigate('/dashboard/oreorder');
            }, 2000);
            
        }
    }

    const getCanuseCoupon = async(goodsNumber)=>{
        const {data} = await lsRequest('/oreLocation/canCouponList','POST',{
            oreId:params.id,
            goodsNumber,
            l:language.requestType
        },token);

        if(data.list&&data.list.length>0){
            setUseCoupontList(data.list);
        }
    }

    const initCouponAmount = (obj,total)=>{
        switch (obj.couponType) {
            case 1:
                return formatDecimal(total*(1-obj.couponDiscount),3);
            case 2:
                return obj.couponDiscount
            default:
                return 0
        }
    }

    const getCouponId = (id)=>{
        cId==id?setCId(''):setCId(id);
    }

    const getCouponPrice = ()=>{
        if(cId){
            let newObj= useCoupontList.filter((item)=>cId===item.id);
            let amount = initCouponAmount(newObj[0],totalOldPrice);
            let newPrice = formatDecimal(totalOldPrice-amount,3)
            setAmountCoupon(amount);
            setTotalPrice(newPrice);
        }
        else{
            setAmountCoupon(0);
            setTotalPrice(totalOldPrice);
        }
    }

    useEffect(()=>{
        getOrderInfo();
    },[]);

    useEffect(()=>{
        getCouponPrice();
    },[cId])

    return (
        <div className='ore-pay h-w1300 body-content'>
            <div className='p-info-left'>
                <h3 className='pay-info-title'> {language.orderInformationTitle}</h3>
                <Table rowKey={'id'} size='small' pagination={false} columns={language.orderInfoDataHeader} dataSource={orderInfo} bordered/>
                <p className='pay-info-output'>{language.couponTxt}</p>
                <div className='coupon-list'>
                    {
                        useCoupontList.length>0?<div className='can-coupon-item'>
                            {
                                useCoupontList.map((item,index)=>{
                                    return <CanUseCoupon language={language} cId={cId} setCouponId={getCouponId} key={index} info={item} />
                                })
                            }
                        </div>:<div style={{display:'flex',justifyContent:'center'}}>
                            <NoCoupons language={language} />
                        </div>
                    }
                </div>
            </div>
            <div className='p-info-right'>
                <div className='p-info-right-item'>
                    <span className='lab'>{language.orePrice}：</span>
                    <div className='price'>${orderData.orePrice}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{language.couponTxt}：</span>
                    <div className='price'>-${amountCoupon}</div>
                </div>
                <div className='p-info-right-total'>
                    <span className='lab'>{language.orderTotal}：</span>
                    <div className='price'>${totalPrice}</div>
                </div>
                <div className='accept-ruler'>
                    <Checkbox className="check" onChange={agreeChange}>{language.acceptTxt}</Checkbox>
                    {language.minerAgree}
                </div>
                <div className='sub-order-btn ls-btn' onClick={()=>toPayOrder()}>Submit</div>
            </div>

            <ConfrimPayModal 
                isShow={isOpenPay} 
                SetConfrimPayStatus={setIsOpenPay} 
                submitOrder={()=>submitOrder()} 
                totalPrice={totalPrice} 
                language={language} 
            />
            <VerifyTipsModal 
                isOpenVerify={isOpenVerify} 
                handleVerifyCancel={handleVerifyCancel} 
                handleVerifyOk={handleVerifyOk} 
                language={language} 
            />
        </div>
    )
}