import React,{useState,useContext, useEffect} from 'react';
import './kycbase.scss';
import { Link,useNavigate } from 'react-router-dom';
import {Breadcrumb, Button, Input,Upload,message,Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FrontBg from '../../assets/icon_zm.png';
import BackBg from '../../assets/icon_fm.png';
import baseUrl from '../../utils/baseUrl';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/JPEG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

export default function KycBase(){
    const [frontLoading, setFrontLoading] = useState(false);
    const [backLoading, setBackLoading,] = useState(false);
    const [frontImageUrl, setFrontImageUrl] = useState();
    const [backImageUrl, setBackImageUrl] = useState();
    const [name,setName] = useState('');
    const [idCard,setIdCard] = useState('');
    const [frontFile,setFrontFile] = useState('');
    const [backFile,setBackFile] = useState('');
    const [kycInfo,setKycInfo] = useState({});
    const [kycStatus,setKycStatus] = useState(0);

    const {token} = useContext(AuthContext);
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const frontImgChange = async(info) => {
        if (info.file.status === 'uploading') {
            setFrontFile(info.file);
            setFrontLoading(true);
            return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
            setFrontLoading(false);
            setFrontImageUrl(baseUrl+info.file.response.data.list[0].url);
        }

        if(info.file.status==='error'){
            message.error('Upload Failed')
            setFrontLoading(false);
        }
    };

    const backImgChange = (info) => {
        if (info.file.status === 'uploading') {
            setBackFile(info.file);
            setBackLoading(true);
            return;
        }
        if (info.file.status === 'done') {
          setBackLoading(false);
          setBackImageUrl(baseUrl+info.file.response.data.list[0].url);
        }
        if(info.file.status==='error'){
            message.error('Upload Failed')
            setBackLoading(false);
        }
    };

    const uploadFront = (
        <div>
          {frontLoading ? <LoadingOutlined />:<img style={{marginTop:'-10px'}} src={FrontBg} alt='' />}
        </div>
    );

    const uploadBack = (
        <div>
          {backLoading ? <LoadingOutlined />: <img style={{marginTop:'-10px'}} src={BackBg} alt='' />}
        </div>
    );

    const changeName = (e)=>{
        setName(e.target.value)
    }

    const changeIdCard = (e)=>{
        setIdCard(e.target.value);
    }

    const onContinue= async()=>{
        let regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

        if(!name){
            message.info('Enter your name');
            return false;
        }
        if(!idCard){
            message.info('Enter your ID number');
            return false;
        }
        if(!regIdCard.test(idCard)){
            message.info('Incorrect identity format');
            return false;
        }
        if(!frontImageUrl){
            message.info('Upload the front photo of your ID card');
            return false;
        }
        if(!backImageUrl){
            message.info('Upload a photo of the reverse side of your ID card');
            return false;
        }


        const resData = await lsRequest('/kycInfo/addKycInfo','POST',{
            realName:name.trim(),
            idCard:idCard.trim(),
            idCardPic:frontImageUrl,
            idCardBackPic:backImageUrl
        },token);
            
        if(resData){
            if(resData.code===200){
                getMemberInfo();
            }
        }
    }

    const getMemberInfo = async()=>{
        const resData = await lsRequest('/memberInfo','POST',{},token);
        if(resData){
            setKycInfo(resData.data);
        }
    }

    const initKycStatus = ()=>{
        let status;
        if(kycInfo.authStatus===1){
            status = 1
        }
        else if(kycInfo.kycStatus==1){
            status = 1
        }
        else{
            status = 0
        }
        setKycStatus(status);
    }

    useEffect(()=>{
        getMemberInfo();
    },[])

    useEffect(()=>{
        if(kycInfo.address){
            initKycStatus();
        }
    },[kycInfo]);

    return (
        <div className='body-content kyc-base' style={{padding:'15px 0'}}>
            <Breadcrumb
                items={[
                    {title: language.dashboardTitle },
                    {title: <Link to={'/dashboard/kyc'}>{language.kycVerification}</Link>},
                    {title: language.basicVerification}
                ]}
            />
            <div className='kyc-title'>
                <span className='kyc-title-txt'>{language.basicVerification}</span>
                <Button shape='round' size='small' className='kyc-title-rules'>KYC {language.rulesTxt}</Button>
            </div>
            <div className='kyc-tabs'>
                <div className={`kyc-tabs-item ${kycStatus>=0?'active':''}`}> <span className='steps'>01</span></div>
                <div className={`kyc-tabs-item ${kycStatus===1?'active':''}`}> <span className='steps'>02</span></div>
            </div>
            {
                kycStatus===0?<div className='step1'>
                    <p className='idcard-title'>
                        <span className='star'>*</span>{language.uploadIDPhoto}:
                    </p>
                    <div className='idcard-tags'>
                        <div className='idcard-tags-item active'>{language.IDCard}</div>
                    </div>
                    <p className='idcard-desc'>{language.IDCardDesc}</p>
                    <div className='idcard-name'>
                        <Input onChange={changeName} size='large' placeholder={language.enterName} />
                    </div>
                    <div className='idcard-name'>
                        <Input maxLength={20} onChange={changeIdCard} size='large' placeholder={language.enterIDnumber} />
                    </div>
                    <div className='upload-idcard'>
                        <div>
                            <Upload
                                name="imageFile"
                                data={frontFile}
                                listType="picture-card"
                                showUploadList={false}
                                action={`${baseUrl}/v1/kycInfo/uploadPic`}
                                beforeUpload={beforeUpload}
                                onChange={frontImgChange}
                                headers={{token:token,loginType:'1'}}
                                method="POST"
                            >
                                {
                                    frontImageUrl ? (
                                    <div className='idimg-box'>
                                        <img
                                            src={frontImageUrl}
                                            alt="avatar"
                                            style={{
                                            width: '100%',
                                            }}
                                        />
                                    </div>
                                    ) : (
                                        uploadFront
                                    )
                                }
                            </Upload>
                            <p style={{marginLeft:'75px',fontSize:'14px'}}>{language.frontTxt}</p>
                        </div>

                        <div>
                            <Upload
                                name="imageFile"
                                data={backFile}
                                listType="picture-card"
                                showUploadList={false}
                                action={`${baseUrl}/v1/kycInfo/uploadPic`}
                                beforeUpload={beforeUpload}
                                onChange={backImgChange}
                                headers={{token:token,loginType:'1'}}
                                method="POST"
                            >
                                {
                                    backImageUrl ? (
                                        <div className='idimg-box'>
                                            <img
                                                src={backImageUrl}
                                                alt="avatar"
                                                style={{
                                                width: '100%',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        uploadBack
                                    )
                                }
                            </Upload>
                            <p style={{marginLeft:'80px',fontSize:'14px'}}>{language.backTxt}</p>
                        </div>
                    </div>

                    <p className='upload-desc'>
                        {language.uploadDescList.map((item,index)=>{
                            return <p key={index}>
                                {item.txt}
                                {item.imgSize&&<span className='size-tips'>{item.imgSize}</span>}
                            </p>
                        })}
                    </p>

                    <Button onClick={()=>onContinue()} style={{width:'200px',marginTop:'30px'}} shape='round' size='large' type='primary'>{language.continueTxt}</Button>
                </div>:<div className='step2'>
                    <Result
                        status="success"
                        title={language.resultTitle}
                        subTitle={language.resultContent}
                        extra={[
                        <Button shape='round' onClick={()=>navigate('/dashboard/kyc')}>{language.backPage}</Button>
                        ]}
                    />
                </div>
            }
            
            
        </div>
    )
}