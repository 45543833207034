import React from 'react';
import './confirmpaymodal.scss';
import {Button, Modal} from 'antd';

export default function ConfrimPayModal({isShow,SetConfrimPayStatus,submitOrder,totalPrice,language}){

    return (
        <>
            <Modal open={isShow} closeIcon={false}  centered footer={null} width={460}>
                <div className="confrim-pay-modal">
                    <div className="confrim-pay-content">
                        <div className='pay-tips'>{language.PaymentAlerts}</div>
                        <div className='confrim-pay-title'>
                            {language.orderCosts}
                            <span className='pay-amount'>${totalPrice}</span>
                            , {language.confirmPay}
                        </div>
                        <div className='confrim-pay'>
                            <Button className='confrim-btn' shape='round' size='large' onClick={()=>SetConfrimPayStatus(false)}>
                                {language.cancelTxt}
                            </Button>
                            <Button className='confrim-btn' shape='round' type="primary" size='large' onClick={submitOrder}>
                                {language.confirmTxt}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}