import React, { useEffect,useContext, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { Table,Breadcrumb } from 'antd';
import { LanguageContext } from '../../contexts/LanguageContext';
import OrderStatus from '../../utils/orderStatus';
import formatDecimal from '../../utils/formateDecimal';

const Title1 = ({type,language})=>{
    switch (parseInt(type)) {
        case 1:
            return <Link to={'/dashboard/hosting'}>{language.myMiner}</Link>
        case 2:
            return <Link to={'/dashboard/hashrate'}>{language.myHashrateTxt}</Link>
        case 3:
            return <Link to={'/dashboard/myorders'}>{language.myOrderTitle}</Link>
        default:
            break;
    }
}

export default function OrderProfitDetail(){
    const [orderInfo,setOrderInfo] = useState();

    const {token} = useContext(AuthContext);
    const params = useParams();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'name',
            value:'name',
            align:'right',
            render:(_,{name})=>(
                <p style={{marginRight:'30px',color:'#666'}}>{name}</p>
            )
        },
        {
            title:language.DataTxt,
            dataIndex:'data',
            value:'data',
            render:(_,{value})=>(
                <p style={{marginLeft:'30px',fontSize:'16px'}}>{value}</p>
            )
        }
    ]
    
    const getOrderDetail = async()=>{
        const resData = await lsRequest('/memberPower/getMinerDetail','POST',{
            memberMinerId:params.id
        },token);
        
        

        if(resData.data){
            let data = resData.data;
            data.costRecovery = formatDecimal((data.todayCoinAmount/data.totalFee)*100,2)+'%';
            
            let newArr = [
                {
                    name:language.OrderNo, value:data.orderSerial
                },
                {
                    name:language.MinerName, value:data.minerName
                },
                {
                    name:language.orderStatus, value: <OrderStatus status={data.minerStatus} language={language} />
                },
                {
                    name:language.CurrencyType, value:data.coinName
                },
                {
                    name:language.CreationTime, value:data.createDate
                },
                {
                    name:language.ShelfDate, value:data.shelfDate
                },
                {
                    name:language.Dateofexcavation, value:data.digStartDate
                },
                {
                    name:language.QuantityTxt, value:data.orderType===2?data.totalCalPower+' '+data.powerUnit:data.goodsNumber+' '+language.unitTxt
                },
                {
                    name:language.totalPower, value:data.totalCalPower+data.powerUnit
                },
                {
                    name:language.minerFeeTxt, value:'$ '+data.minerFee
                },
                {
                    name:language.orePrice, value:'$ '+data.minerPositionFee
                },
                {
                    name:data.orderType===2?language.serviceFeeTxt:language.electricityCosts, value:'$ '+data.prepayElectricFee
                },
                {
                    name:data.orderType===2?language.RemainingServiceFee:language.RemainingElectricityFee, value:<span style={{color:'#c00'}}>${data.remainElectricFee}</span>
                },
                {
                    name:language.TotalFee, value:'$ '+data.totalFee
                },
                {
                    name:language.CumulativeOutput,value:data.totalCoinAmount
                },
                {
                    name:data.orderType===2?language.LatestOutput:language.costRecovery,value:data.orderType===2?data.todayCoinAmount:data.costRecovery
                }
            ];
            setOrderInfo(newArr)
        }
    }

    useEffect(()=>{
        getOrderDetail();
    },[])

    return (
        <div>
            <Breadcrumb
                items={[
                    {title:  language.dashboardTitle},
                    {title: <Title1 type={params.type} language={language} />},
                    {title: language.detailsTxt },
                ]}
            />
            <Table 
                style={{marginTop:'30px',width:'500px'}}
                rowKey={'value'}  
                bordered  
                size='small'
                columns={tableHeader} 
                dataSource={orderInfo} 
                pagination={false}
                showHeader={false}
            />
        </div>
    )
}