import React,{useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import './footer.scss';
import baseName from '../../utils/baseName';
import { LanguageContext } from '../../contexts/LanguageContext';
import { Tooltip } from 'antd';

import fLogo from '../../assets/logo_o.png';

export default function Footer(){
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
    
    return (
        <div className='ls-footer'>
            <div className="footer-box h-w1300">
                <div className='f-left'>
                    <span className='f-logo' onClick={()=>navigate('/')}>
                        <img className='logo' src={fLogo} alt='' />
                    </span>
                    <p className='f-desc'>{language.footerDesc}</p>
                    <div className='f-icon-list'>
                        {/* <span className='icon-item'>
                            <i className='bitfont bittelegram'></i>
                        </span> */}
                        <a href='https://t.me/hash_box' target='\_blank' className='icon-item'>
                            <i className='bitfont bittelegram'></i>
                        </a>
                    </div>
                </div>
                <ul className='f-right'>
                    <li className='f-right-item'>
                        <h5 className='f-title'>{language.productTxt}</h5>
                        <p className='f-link'>
                            <Tooltip placement="top" title={language.NotYetOpen} arrow={true}>
                                {language.cloudMiningTitle}
                            </Tooltip>
                        </p>
                        <p className='f-link'>
                            
                            <Tooltip placement="top" title={language.NotYetOpen} arrow={true}>
                                {language.minerTitle}
                            </Tooltip>
                        </p>
                        <p className='f-link' onClick={()=>navigate('/orepos')}>{language.rentalMinersTxt} </p>
                    </li>
                    <li className='f-right-item'>
                        <h5 className='f-title'>{language.companyTxt}</h5>
                        <p className='f-link' onClick={()=>navigate('/about')}>{language.aboutTxt} {baseName}</p>
                    </li>
                    {/* <li className='f-right-item'>
                        <h5 className='f-title'>{language.FAQNewsTxt}</h5>
                        <p className='f-link' onClick={()=>navigate('/news')}>{language.newsTitle}</p>
                    </li> */}
                    <li className='f-right-item'>
                        <h5 className='f-title'>{language.supportTxt}</h5>
                        <p className='f-link' onClick={()=>navigate('/help')}>{language.helpCenterTxt}</p>
                        <p className='f-link'>{language.termsService}</p>
                        <p className='f-link'>{language.ContactEmail}：hashbox33@gmail.com</p>
                    </li>
                </ul>
            </div>
            <div className="f-copyright h-w1300 ">Copyright © {baseName}</div>
        </div>
    )
}