import React, { useEffect, useState,useContext } from 'react';
import { Link,useParams } from 'react-router-dom';
import './orderdailyearning.scss';
import {Breadcrumb,Table,Select,DatePicker} from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';

const Title2 = ({type,language})=>{
    switch (parseInt(type)) {
        case 1:
            return <span>{language.minerProfit}</span>
        case 2:
            return <span>{language.cloudProfit}</span>
        default:
            break;
    }
}

const Title1 = ({type,language})=>{
    switch (parseInt(type)) {
        case 1:
            return <Link to={'/dashboard/hosting'}>{language.myMiner}</Link>
        case 2:
            return <Link to={'/dashboard/hashrate'}>{language.myHashrateTxt}</Link>
        default:
            break;
    }
}

export default function OrderDailyEarning(){
    const [dailyList,setDailyList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
    const params = useParams();

    const tableHeader = [
        {
            title:language.DateTxt,
            dataIndex:'date',
            value:'date',
            align:'center'
        },
        {
            title:language.dailyEarningsTxt,
            dataIndex:'earnings',
            value:'earnings',
            align:'center'
        }
    ]

    const getDailyList = async()=>{
        const resData = await lsRequest('/coinDigInfo/getDigRecord','POST',{
            coinName:params.coinName,
            orderId:params.id,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            setDailyList(resData.data.list);
        }
    }

    useEffect(()=>{
        getDailyList();
    },[])

    return (
        <div style={{padding:'15px 0'}}>
            <Breadcrumb
                items={[
                    {title: language.dashboardTitle},
                    {title: <Title1 type={params.type} language={language} />},
                    {title: <Title2 type={params.type} language={language} />}
                ]}
            />
            <div className='daily-earning-title'>
                <div className='d-e-title'><Title2 type={params.type} language={language} /></div>
            </div>
            <Table 
                style={{marginTop:'20px'}}
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                rowKey={'id'}
                columns={tableHeader} 
                dataSource={dailyList} 
                size="small" 
            />
        </div>
    )
}