import React from 'react';
import './wallet.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import cardBg from '../../../assets/card-bg.png';

export default function Wallet({winfo,language}){
    const navigate = useNavigate();

    const toDeposit = ()=>{
        navigate('/dashboard/deposit/'+winfo.coinName);
    }

    const toWithdrawal = ()=>{
        navigate('/dashboard/withdrawal/'+winfo.coinName);
    }

    return (
        <div className='wallet-box'>
            <div className='wallet-point'>
                {winfo.usableAmount} 
                <span className='unit'>{winfo.coinName}</span>
            </div>
            <div className='wallet-no'>≈ {winfo.usableAmount2Cny} cny</div>
            <img className='wallet-bg' src={cardBg} alt='' />
            <div className='btn-group'>
                {winfo.depositStatus===1&&<Button onClick={()=>toDeposit()} className='wallet-btn' shape="round" size='small'>{language.depositTxt}</Button>}
                {winfo.withdrawStatus===1&&<Button onClick={()=>toWithdrawal()} className='wallet-btn' shape="round" size='small'>{language.withdrawalTxt}</Button>}
            </div>
        </div>
    )
}