import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { Card, List,Breadcrumb} from 'antd';
import OreDigStatus from '../../components/OreDigStatus/OreDigStatus';

const PaybackState = ({status,language})=>{
    switch (status) {
        case 1:
            return <span style={{color: '#4bc267'}}>{language.Returned}</span>;
        default:
            return <span style={{color:'#f36b64'}}>{language.NotReturned}</span>;
    }
}

const ColorTxt = ({txt})=>{
    return <span style={{color: '#c00'}}>${txt}</span>;
}

export default function OreOrderDetails(){

    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const oreOrDetail = JSON.parse(localStorage.getItem('oreOrderDetails'))||{};
    
    const data = [
        {
          title:language.nameTxt,
          content:oreOrDetail.packageName
        },
        {
            title:language.dateTxt,
            content:oreOrDetail.createDate
        },
        {
            title:language.MiningDays,
            content:oreOrDetail.periodDays+language.daysTxt
        },
        {
          title:language.QuantityTxt,
          content:oreOrDetail.actualBuyNumber
        },
        {
            title:language.orePrice,
            content:'$'+oreOrDetail.actualBaseFee
          },
        {
          title:language.actualPaymentTxt,
          content:<ColorTxt txt={oreOrDetail.actualPayFee} />
        },
        {
          title:language.orderStatus,
          content:<OreDigStatus language={language} status={oreOrDetail.digStatus} />
        },
        {
            title:language.paybackStateTxt,
            content:<PaybackState language={language} status={oreOrDetail.isBeBack} />
        },
        {
          title:language.CumulativeOutput,
          content:oreOrDetail.totalAmount
        },
        {
            title:language.YesterdayOutput,
            content:oreOrDetail.yesterdayDugAmount
        },
        {
            title:language.MiningRemaining,
            content:oreOrDetail.remainTimes+language.daysTxt
        },
        {
            title:language.MiningStartTimes,
            content:oreOrDetail.digStartDate
        },
        {
            title:language.MiningEndTimes,
            content:oreOrDetail.digEndDate
        },
        
    ];


    return (
        <div className='ore-order-detail'>
            <Breadcrumb
                items={[
                    {title: language.dashboardTitle},
                    {title: <Link to={'/dashboard/oreOrder'}>{language.myMinesTxt}</Link>},
                    {title: language.OrderDetail}
                ]}
            />
            <div style={{marginTop:'30px'}}>
                <List
                    grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 4,
                    }}
                    dataSource={data}
                    renderItem={(item) => (
                    <List.Item>
                        <Card title={item.title}>{item.content}</Card>
                    </List.Item>
                    )}
                />
            </div>
        </div>
    )
}