import React, { useState,useEffect,useContext } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Converted from '../../components/Converted/Converted';
import { Table,Button } from 'antd';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import OrderStatus from '../../utils/orderStatus';
import { LanguageContext } from '../../contexts/LanguageContext';
import formatDecimal from '../../utils/formateDecimal';

export default function Hosting(){
    const [miningList,setMingingList] = useState([]);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');
    const [currencyLen,setCurrencyLen] = useState(2);
    const [totalPower,setTotalPower] = useState([{},{},{remain_amount:0,totalGoodsNumber:0,totalDigAmount:0}]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
    const navigate = useNavigate();

    const [convertData,setConvertData] = useState({
        type:2,
        titleTips:language.myMinerConvertedTips
    });

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'minerName',
            value:'minerName',
            align:'center'
        },
        {
            title:language.dateTxt,
            dataIndex:'orderSerial',
            value:'orderSerial',
            align:'center'
        },
        {
            title:language.QuantityTxt,
            dataIndex:'goodsNumber',
            value:'goodsNumber',
            align:'center',
            render:(_,{goodsNumber})=>(
                <span>{goodsNumber}{language.unitTxt}</span>
            )
        },
        {
            title:language.totalCosts,
            dataIndex:'totalFee',
            value:'totalFee',
            align:'center',
            render:(_,{totalFee})=>(
                <span>${totalFee}</span>
            )
        },
        {
            title:language.runStatus,
            dataIndex:'minerStatus',
            value:'minerStatus',
            align:'center',
            render:(_,{minerStatus})=>(
                <OrderStatus language={language} status={minerStatus} />
            )
        },
        {
            title:language.profitTxt,
            dataIndex:'totalCoinAmount',
            value:'totalCoinAmount',
            align:'center',
            render:(_,{totalCoinAmount})=>(
                <span style={{color:'#c00'}}>{totalCoinAmount}</span>
            )
        },
        {
            title:language.costRecovery,
            dataIndex:'costRecovery',
            value:'costRecovery',
            align:'center',
            render:(_,{costRecovery})=>(
                <span style={{color:'#c00'}}>{costRecovery}</span>
            )
        },
        {
            title:language.actionTxt,
            dataIndex:'action',
            value:'action',
            align:'center',
            render:(_,item)=>(
                <>
                    <p>
                        <Link to={'/dashboard/orderearning/'+item.id+'/'+item.coinName+'/'+item.orderType}>
                            <Button size='small'>{language.checkProfit}</Button>
                        </Link>
                        
                    </p>
                    <p style={{marginTop:'10px'}}>
                        <Link to={'/dashboard/orderProfit/'+item.id+'/'+item.orderType}>
                            <Button size='small'>{language.checkDetail}</Button>
                        </Link>
                    </p>
                </>
            )
        }
    ]


    const selectCurrencyChange = (value) => {
        setCurrency(value);
    };


    const getCoinInfo = async()=>{
        const resData = await lsRequest('/coinInfo/getDigCoinInfo','POST',{l:language.requestType},'');

        if(resData&&resData.data.list.length>0){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain;
                obj.len = item.withdrawLength;
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
            setCurrencyLen(newArr[0].len);
        }
    }

    const getTotalCalPower = async()=>{
        const resData = await lsRequest('/memberPower/getMyTotalCalPower','POST',{
            coinName:currency,
            l:language.requestType
        },token);
        if(resData){
            setTotalPower(resData.data);
        }
    }

    const getMyOrders = async()=>{
        const resData = await lsRequest('/memberPower/getMemberMinerList','POST',{
            orderType:1,
            minerStatus:'',
            coinName:currency,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.data&&resData.data.list.length>0){
            let data = resData.data;
            let newData = data.list.map((item,index)=>{
                item.costRecovery = formatDecimal((item.todayCoinAmount/item.totalFee)*100,2)+'%';
                return item;
            })
            setMingingList(newData);
        }
    }

    useEffect(()=>{
        getCoinInfo();
    },[])

    useEffect(()=>{
        if(currency){
            getTotalCalPower();
            getMyOrders();
        }
    },[currency])

    return (
        <div>
            <Converted  
                info={convertData} 
                currency={currency} 
                currencyList={currencyList}
                selectCurrencyChange={selectCurrencyChange}
                totalPower = {totalPower}
                currencyLen={currencyLen}
                language={language}
            />
            <p style={{padding:'20px 0'}}>{language.miningList}</p>
            <Table 
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                dataSource={miningList} 
                columns={tableHeader}
                size="small" 
            />
        </div>
    )
}