import React from 'react';
import './tab.scss'



export default function InitTab({tabIndex,setTabIndex,language}){
    const changeTab = (e)=>{
        setTabIndex(e);
    }

    const tabData = [
        {
            text:language.cloudMiningTitle
        },
        {
            text:language.minerTitle
        },
        {
            text:language.RentalTypeMineSite
        },
        {
            text:language.SelfcontainedMineLevel
        }
    ]

    return (
        <div className='ls-tab h-w1300'>
            {tabData.map((item,index)=>{
                return(
                    <div key={index} onClick={()=>changeTab(index)} className={["ls-tab-item ",tabIndex===index?"active":"action-item"].join('')}>
                        {index===0&&<div className="mining icon"></div>}
                        {index===1&&<div className="rental icon"></div>}
                        {index===2&&<div className="sales icon"></div>}
                        {index===3&&<div className="hosting icon"></div>}
                        <div>{item.text}</div>
                    </div>
                )
            })}
        </div>
    )
}