import axios from "axios";
import {message} from 'antd';
import baseUrl from "./baseUrl";

const lsAxios = axios.create({
    baseURL:baseUrl+'/v1',
    timeout:8000,
});


lsAxios.interceptors.request.use(config=>{
    return config;
});

lsAxios.interceptors.response.use(response=>{
    return response;
},err=>{
    message.error(err.message);
    console.log('Interface Access Failure',err);
})

export default lsAxios;