import React from 'react';
import './converted.scss';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import { Tooltip,Switch,ConfigProvider,Select } from 'antd';
import initCoinImg from '../../utils/initCoinImg';
import initCoinTxt from '../../utils/initCoinTxt';
import initToCoinNum from '../../utils/initToCoinNum';


export default function Converted({info,currency,currencyList,selectCurrencyChange,totalPower,currencyLen,language}){
    const changeSwitch = (e)=>{
        console.log(e)
    }


    return (
        <div className='converted'>
            <div className='module-title'>
                <div>
                    {info.type===1?language.myHashrateConverted:language.myMinerConverted}
                    <Tooltip placement="top" title={info.titleTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'8px',cursor:'pointer'}} />
                    </Tooltip>
                </div>
                <div style={{display:'flex',alignItems:'center',marginLeft:'50px',fontSize:'14px',color:'#666'}}>
                    {language.currencyTxt}：
                    <Select
                        style={{ width: '100px',}}
                        placeholder="Please select currency"
                        defaultValue={currency}
                        key={currency}
                        onChange={selectCurrencyChange}
                        options={currencyList}
                    />
                </div>
            </div>
            <div className='hashrate-hosting'>
                <h3 className='amount'>$ {totalPower[1].remain_amount}</h3>
                <p className='btc-amount'>≈ {initToCoinNum(totalPower[1].remain_amount,totalPower[3],currencyLen)} {currency}</p>
            </div>
            <div className='owner-box'>
                <div className='name-box'>
                    <div className='bit-box'>
                        {initCoinImg(currency)}
                        <span className='coinname'>{initCoinTxt(currency)}</span>
                    </div>
                    {info.type===1&&<div className='auto-pay'>
                        <span style={{marginRight:'10px'}}>{language.autoPayTxt}</span>
                        <ConfigProvider
                            theme={{
                                components:{
                                    Switch:{
                                        colorPrimary:'#c00',
                                        colorPrimaryHover:'#c00'
                                    }
                                }
                            }}
                        >
                            <Switch checkedChildren={language.SwitchOnTxt} unCheckedChildren={language.SwitchOffTxt} onChange={changeSwitch} defaultChecked={false} />
                        </ConfigProvider>
                        
                    </div>}
                </div>
                <div className='owner-bottom'>
                    <div>
                        <div className='latest-con'>
                            {language.latestOutput}
                            <Tooltip placement="top" title={language.EarningsTips} arrow={true}>
                                <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'8px',cursor:'pointer'}} />
                            </Tooltip>
                        </div>
                        <div className='latest-btc'>{totalPower[1].totalDigAmount} {currency}</div>
                    </div>
                    <div>
                        <div className='owned-th-lab'>
                            {info.type===1?language.hashrateOwned:language.minersOwned}
                        </div>
                        <div className='owned-th'>{info.type===1?totalPower[1].totalCalPower:totalPower[2].totalGoodsNumber} {info.type===1?'TH/s':language.unitTxt}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}