import React from 'react';
import './cloudlist.scss';
import FreeMiningItem from '../../../components/FreeMiningItem/FreeMiningItem';
import GoodsItem from '../../../components/GoodsItem/GoodsItem';
import FreeTrial from '../../../components/FreeTrial/FreeTrial';
import NoData from '../../../components/NoData/NoData';

export default function CloudList({cloudList,packageType,language}){
    return (
        <div className='cloud-list'>
            <div className='h-w1300'>
                <p className='cloud-list-tips'>{language.cloudMiningPageDesc}</p>

                {
                    cloudList.length>0?<div className='goods-list'>
                        {
                            cloudList.map((item,index)=>{
                                return <div className='goods-item' key={index}>
                                    {item.isNewBuy===1?<FreeMiningItem info={item} packageType={packageType} language={language} />:<GoodsItem info={item} packageType={packageType} language={language} />}
                                </div>
                            })
                        }
                    </div>:<div style={{marginTop:'100px'}}><NoData language={language} /></div>
                }
                
                <FreeTrial language={language} />

            </div>
        </div>
    )
}