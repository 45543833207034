import React, { useState } from 'react';
import './servicefee.scss';
import {Table} from 'antd'

const tableHeader = [
    {
        title:'Hashrate Name',
        dataIndex:'name',
        value:'name'
    },
    {
        title:'Hashrate Number',
        dataIndex:'number',
        value:'number'
    },
    {
        title:'Running Status',
        dataIndex:'status',
        value:'status'
    },
    {
        title:'Running',
        dataIndex:'running',
        value:'running'
    },
    {
        title:'Available',
        dataIndex:'available',
        value:'available'
    },
    {
        title:'Operation',
        dataIndex:'operation',
        value:'operation'
    }
]

export default function ServiceFee(){

    const [serviceList,setServiceList] = useState([]);

    return (
        <div>
            <div className='common-page-header'>
                <div className='title'>Service Fee</div>
                <div className='header-btn-group'>
                    <button className='first-btn'>History</button>
                    <button className='last-btn'>Pay In Bulk</button>
                </div>
            </div>

            <Table size='small' columns={tableHeader} dataSource={serviceList} />
        </div>
    )
}