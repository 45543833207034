import React,{createContext,Component} from 'react'
import {en} from '../utils/en';
import {hk} from '../utils/hk'

export const LanguageContext = createContext();

class LanguageContextProvider extends Component{
	state = {
		langType:localStorage.getItem('langType')||'hk',
		en,
		hk
	}
	
	toggleLanguage = (data)=>{
		localStorage.setItem('langType',data);
		this.setState({
			langType:data
		})
	}
	
	render(){
		return(
			<LanguageContext.Provider value = {{...this.state,toggleLanguage:this.toggleLanguage}}>
				{this.props.children}
			</LanguageContext.Provider>
		)
	}
}

export default LanguageContextProvider;