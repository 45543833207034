import React,{useEffect, useState,useContext} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './fullpay.scss';
import { Checkbox,Table,message,Tabs,Carousel} from 'antd';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import CanUseCoupon from '../../components/CanUseCoupon/CanUseCoupon';
import NoCoupons from '../../components/NoCoupons/NoCoupons';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';
import VerifyTipsModal from '../../components/VerifyTipsModal/VerifyTipsModal';
import ConfrimPayModal from '../../components/ConfirmPayModal/ConfirmPayModal';

const curRef = React.createRef();

export default function FullPay(){
    const [orderInfo,setOrderInfo] = useState([]);
    const [packageInfo,setPackageInfo] = useState({});
    const [isOpenVerify, setIsOpenVerify] = useState(false);
    const [isOpenPay,setIsOpenPay] = useState(false);
    const [totalOldPrice,setTotalOldPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);
    const [agreeStatus,setAgreeStatus] = useState(false);
    const [useMinerCoupontList,setUseMinerCoupontList] = useState([]);
    const [useServiceCoupontList,setUseServiceCoupontList] = useState([]);
    const [minerfeeCoupon,setMinerfeeCoupon] = useState(0);//优惠券优惠金额
    const [cId,setCId] = useState('');

    const {token} = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const orderData = JSON.parse(localStorage.getItem('orderData'));

    const getOrderInfo = async()=>{
        const resData = await lsRequest('/powerPackage/getMinerPackageDetail','POST',{minerPackageId:params.id});
        if(resData&&resData.data){
            let data = resData.data;
            setPackageInfo(data);
            setTotalPrice(orderData.total);
            setTotalOldPrice(orderData.total);
            getCanuseCoupon(data.package_id,orderData.amount);
            let newArr = [
                {
                    id:0,
                    name:data.package_name,
                    price:data.packageType==2?data.perFee+'/'+data.calPowerUnit+'/'+language.daysTxt:'$'+data.minerFee+'/'+language.unitTxt,
                    amount:data.packageType==2?orderData.amount+data.calPowerUnit:orderData.amount+language.unitTxt+`(${data.calPower}${data.calPowerUnit}/${language.unitTxt})`,
                    days:data.periodDays+language.daysTxt,
                    total:'$'+orderData.minerPrice
                },
                {
                    id:1,
                    name:data.packageType==2?language.serviceFeeTxt:language.electricityCosts,
                    price:data.packageType==2?'$'+data.serviceFee+'/'+data.calPowerUnit+'/'+language.daysTxt:'$'+data.serviceFee+'/'+language.unitTxt+'/'+language.daysTxt,
                    amount:data.packageType==2?orderData.amount+data.calPowerUnit:orderData.amount+language.unitTxt+`(${data.calPower}${data.calPowerUnit}/${language.unitTxt})`,
                    days:orderData.days+language.daysTxt,
                    total:'$'+orderData.servicePrice
                }
            ]
            setOrderInfo(newArr);
        }
        
    }

    const agreeChange = (e)=>{
        setAgreeStatus(e.target.checked);
    }

    const showOpenVerify = () => {
        setIsOpenVerify(true);
    };
    const handleVerifyOk = () => {
        setIsOpenVerify(false);
    };
    const handleVerifyCancel = () => {
        setIsOpenVerify(false);
    };

    const toPayOrder=()=>{
        if(!agreeStatus){
            message.info(language.submitAgreeTips);
            return false;
        }
        setIsOpenPay(true);
    }

    const submitOrder=async()=>{
        const resData = await lsRequest('/memberBill/createBill','POST',{
            goodsNumber:orderData.amount,
            buyElectricDay:orderData.days,
            couponId:cId,
            minerId:params.id,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            confirmOrder(resData.data);
        }
    }

    const confirmOrder = async(billId)=>{
        const resData = await lsRequest('/memberPower/submitOrderWithBalance','POST',{
            billIdStr:billId,
            coinName:'USDT',
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            message.success(language.paymentSuccessful);
            setTimeout(() => {
                navigate('/dashboard/myorders');
            }, 2000);
            
        }
    }

    const getCanuseCoupon = async(powerPackageId,goodsNumber)=>{
        const {data} = await lsRequest('/coupon/getCanUseCouponList','POST',{
            powerPackageId,
            goodsNumber,
            l:language.requestType
        },token);

        if(data.list&&data.list.length>0){
            setUseMinerCoupontList(data.list);
        }
    }

    const tabChange = (index) => {
        curRef.current.goTo(index);
    };

    const initCouponAmount = (obj,total)=>{
        switch (obj.couponType) {
            case 1:
                return formatDecimal(total*(1-obj.couponDiscount),3);
            case 2:
                return obj.couponDiscount
            default:
                return 0
        }
    }

    const getCouponId = (id)=>{
        cId==id?setCId(''):setCId(id);
    }

    const getCouponPrice = ()=>{
        if(cId){
            let newObj= useMinerCoupontList.filter((item)=>cId===item.id);
            let amount = initCouponAmount(newObj[0],totalOldPrice);
            let newPrice = formatDecimal(totalOldPrice-amount,3)
            setMinerfeeCoupon(amount);
            setTotalPrice(newPrice);
        }
        else{
            setMinerfeeCoupon(0);
            setTotalPrice(totalOldPrice);
        }
    }

    useEffect(()=>{
        getOrderInfo();
    },[]);

    useEffect(()=>{
        getCouponPrice();
    },[cId])

    return (
        <div className='pay-info h-w1300 body-content'>
            <div className='p-info-left'>
                <h3 className='pay-info-title'> {language.orderInformationTitle}</h3>
                {/* <p className='pay-info-output'>Order Information</p> */}
                <Table rowKey={'id'} size='small' pagination={false} columns={language.orderInfoDataHeader} dataSource={orderInfo} bordered/>
                <p className='pay-info-notes'>
                    <i className='notes-icon'></i>
                    {packageInfo.packageType===2?language.fullpayeCloudNote:language.fullpayeMinerNote}
                </p>
                <p className='pay-info-output'>{language.couponTxt}</p>
                <div className='coupon-list'>
                    <Tabs defaultActiveKey="0" size='large' items={language.fullpayTab} onChange={tabChange} />
                    <Carousel dots={false} ref={curRef} waitForAnimate={true}>
                        <div>
                            {
                                useMinerCoupontList.length>0?<div className='can-coupon-item'>
                                    {
                                        useMinerCoupontList.map((item,index)=>{
                                            return <CanUseCoupon language={language} cId={cId} setCouponId={getCouponId} key={index} info={item} />
                                        })
                                    }
                                </div>:<div style={{display:'flex',justifyContent:'center'}}>
                                    <NoCoupons language={language} />
                                </div>
                            }
                        </div>
                        <div>
                            {
                                useServiceCoupontList.length>0?<div className='can-coupon-item'>
                                    {
                                        useServiceCoupontList.map((item,index)=>{
                                            return <CanUseCoupon language={language} cId={cId} setCouponId={getCouponId} key={index} info={item} />
                                        })
                                    }
                                </div>:<div style={{display:'flex',justifyContent:'center'}}>
                                    <NoCoupons language={language} />
                                </div>
                            }
                            
                        </div>
                    </Carousel>
                </div>
            </div>
            <div className='p-info-right'>
                <div className='p-info-right-item'>
                    <span className='lab'>{packageInfo.packageType===2?language.hashrateFeeTxt:language.minerFeeTxt}：</span>
                    <div className='price'>${orderData.minerPrice}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{language.orePrice}：</span>
                    <div className='price'>${orderData.positionFee}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{packageInfo.packageType===2?language.serviceFeeTxt:language.electricityCosts}：</span>
                    <div className='price'>${orderData.servicePrice}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{language.minerFeeCoupon}：</span>
                    <div className='price'>-${minerfeeCoupon}</div>
                </div>
                <div className='p-info-right-total'>
                    <span className='lab'>{language.orderTotal}：</span>
                    <div className='price'>${totalPrice}</div>
                </div>
                <div className='accept-ruler'>
                    <Checkbox className="check" onChange={agreeChange}>{language.acceptTxt}</Checkbox>
                    {language.acceptAgreeTxt}
                </div>
                <div className='sub-order-btn ls-btn' onClick={()=>toPayOrder()}>{language.submitTxt}</div>
            </div>

            <ConfrimPayModal 
                isShow={isOpenPay} 
                SetConfrimPayStatus={setIsOpenPay} 
                submitOrder={()=>submitOrder()} 
                totalPrice={totalPrice} 
                language={language} 
            />

            <VerifyTipsModal 
                isOpenVerify={isOpenVerify} 
                handleVerifyCancel={handleVerifyCancel}
                handleVerifyOk={handleVerifyOk} 
                language={language} 
            />
        </div>
    )
}