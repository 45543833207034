import lsAxios from './axioxRequest';
import {message} from 'antd';

const lsRequest = async (url,method,data,token)=>{
    let res = await lsAxios({
        url,
        headers:{loginType:'1',token:token?token:''},
        method,
        data:new URLSearchParams(data)
    })
    if(res){
        if(res.data.code===200){
            return res.data;
        }
        else if(res.data.code===501){
            message.info(res.data.msg);
            localStorage.clear();
        }
        else{
            message.info(res.data.msg);
            return null;
        }
    }
    else{
        return null;
    }
}

export default lsRequest;