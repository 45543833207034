import React, { useEffect, useState,useContext } from 'react';
import './help.scss';
import { LanguageContext } from '../../contexts/LanguageContext';


export default function Help(){
    const {langType,en,hk} = useContext(LanguageContext);
	// const language = langType==='en'?en:hk;

    return (
        <div className='body-content about-box-body' style={{backgroundColor:'#edeef4'}}>
            {
                langType==='hk'?<div className='h-w1300'>
                    <h3 className='title'>幫助中心</h3>
                    <h3 className='about-desc'>
                        一、有關礦位銷售和收益
                    </h3>
                    <p className='about-desc'>
                        目前的礦位銷售是在礦場正式開啟挖礦前的礦機機位早鳥預售，主要有以下兩種產品：
                    </p>
                    <p className='about-desc'>
                        1、適用出租用礦位的用戶
                    </p>
                    <p className='about-desc'>
                        每個機位的投資金額為 600 美元，投資期為礦場開始正式挖礦起的一年、二年或三年，由用户自行选择。礦位投资后，將用於出租，每年的年收益為16%，即 96 美元獲利。該金額將分為每天的份額打入用戶的賬戶，持續至結束之日。周期結束後，將采用回购方式，由 hashbox 原價回購礦位。
                    </p>
                    <p className='about-desc'>
                        2、適用於自用型礦位的用戶
                    </p>
                    <p className='about-desc'>
                        每個機位的價格為 600 美元，有效期為該機器雲算力開始使用後的三年，在有效期內，用戶可以享受電費 0.057 美分/KW/H 的折扣價格（原價為 0.075）。
                    </p>
                    <p className='about-desc'>
                        到期後，機位所有權歸還 hashbox 所有。
                    </p>
                    <h3 className='about-desc'>
                        二、關於提現：
                    </h3>
                    <p className='about-desc'>
                        提現為每個月1次，申請之日之後的 T+2 天到賬。
                    </p>
                </div>:<div className='h-w1300'>
                    <h3 className='title'>Help Center</h3>
                    <h3 className='about-desc'>
                        1. Regarding Mining Slot Sales and Returns
                    </h3>
                    <p className='about-desc'>
                        The current sale of mining slots involves early bird pre-sales of machine positions before the formal mining operation begins at the mining site. There are mainly two types of products:
                    </p>
                    <p className='about-desc'>
                        (1) **For Users Renting Mining Slots**
                    </p>
                    <p className='about-desc'>
                    Each slot requires an investment of $600, with investment periods of one, two, or three years from the start of formal mining, chosen by the user.
   After investing in a mining slot, it will be used for renting, yielding an annual return of 16%, i.e., a profit of $96.
   The profit will be distributed into the user's account daily, continuing until the end of the chosen period. At the end of the period, a buyback method will be employed, with hashbox repurchasing the slot at the original price.
                    </p>
                    <p className='about-desc'>
                        (2) **For Users of Self-Use Mining Slots**
                    </p>
                    <p className='about-desc'>
                    Each slot is priced at $600, valid for three years from the start of cloud computing power usage on the machine. During this period, users can enjoy a discounted electricity fee of $0.057 per KW/H (original price $0.075).
                    </p>
                    <p className='about-desc'>
                        Upon expiration, ownership of the slot reverts to hashbox.
                    </p>
                    <h3 className='about-desc'>
                        2. About Withdrawals:
                    </h3>
                    <p className='about-desc'>
                    Withdrawals are processed once per month, and the funds will be credited to the user's account on T+2 days from the date of application.
                    </p>
                </div>
            }
        </div>
    )
}