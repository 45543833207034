import React from 'react';
import { Carousel } from 'antd'; 
import './tabContent.scss';
import GoodsItem from '../../../components/GoodsItem/GoodsItem';
import FreeMiningItem from '../../../components/FreeMiningItem/FreeMiningItem';
import TabMiner from '../../../components/TabMinerSales/TabMiner';
import TabMinerHosting from '../../../components/TabMinerHosting/TabMinerHosting';
import NoData from '../../../components/NoData/NoData';

const curRef = React.createRef();

export default function TabContent({tabIndex,cloudList,miningList,language}){
    setTimeout(()=>{
        curRef.current.goTo(tabIndex);
    },100)
    return (
        <div className="tab-content h-w1300">
            <Carousel dots={false} ref={curRef} waitForAnimate={true}>
                <div>
                    <p className='tab-tips'>{language.cloudMiningHomeNotes} </p>
                    {
                      cloudList.length>0?<div className="goods-box">
                        {cloudList.map((item,index)=>{
                            return <div key={index} className="good-item-box">
                                    {item.isNewBuy===1?<FreeMiningItem info={item} packageType={2} language={language} />:<GoodsItem info={item} packageType={2} language={language} />}
                                </div>
                            })
                        }
                    </div>:<NoData language={language} />
                    }
                    
                    {/* <button className='more-btn'>
                        {language.moreTxt}<span className='bitfont bitgengduo'></span>
                    </button> */}
                </div>
                <div>
                    <p className='tab-tips'>{language.minerHomeNotes}</p>
                    {
                       miningList.length>0? <div className="goods-box">
                            {miningList.map((item,index)=>{
                                return <div key={index} className="good-item-box">
                                        {item.isNewBuy===1?<FreeMiningItem info={item} packageType={2} language={language} />:<GoodsItem info={item} packageType={2} language={language} />}
                                    </div>
                                })
                            }
                        </div>:<NoData language={language} />
                    }
                    {/* <button className='more-btn'>
                        {language.moreTxt} <span className='bitfont bitgengduo'></span>
                    </button> */}
                </div>
                <div>
                    <TabMiner language={language} />
                </div>
                <div>
                    <TabMinerHosting language={language} />
                </div>
        </Carousel>
        </div>
    )
}