import React, { useEffect, useState ,useContext } from 'react';
import CloudTab from './CloudTab/CloudTab';
import CloudList from './CloudList/CloudList';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function CloudMining(){
    const [pType,setPtype] = useState(2);//pType:1矿机 2 算力
    const [cloudList,setCloudList] = useState([]);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getCouldList = async()=>{
        const resData = await lsRequest('/powerPackage/getPackageList','POST',{packageType:pType,l:language.requestType});
        if(resData.data&&resData.data.list.length>0){
            setCloudList(resData.data.list);
        }
        
    }

    useEffect(()=>{
        getCouldList()
    },[])

    return (
        <div className='body-content' style={{backgroundColor:'#edeef4'}}>
            <CloudTab language={language} />
            <CloudList cloudList={cloudList} packageType={pType} language={language} />
        </div>
    )
}