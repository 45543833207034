import React from 'react';
import './faq.scss';
import { Collapse, theme } from 'antd';

export default function Faq({language}){
    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: '1px solid #dcdcdc',
    };
    const getItems = language.FAQsList;
    
    return (
        <div style={{marginTop:'30px'}}>
            <Collapse
                size='large'
                bordered={false}
                expandIconPosition='end'
                accordion={true}
                style={{
                    background: token.colorBgContainer,
                }}
                items={getItems(panelStyle)}
            />
        </div>
    )
}