import React,{useEffect, useState,useContext} from 'react';
import CouponItem from '../../components/CouponItem/CouponItem';
import { LanguageContext } from '../../contexts/LanguageContext';
import banneren from '../../assets/tasken.jpg';
import bannerhk from '../../assets/taskhk.jpg';
import './coupon.scss';
import NoCoupons from '../../components/NoCoupons/NoCoupons';

export default function Coupon(){
    const [couponList, setCouponList] = useState([]);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
    
    useEffect(()=>{
        console.log(couponList.length)
    },[])

    return (
        <div className='body-content coupon-box-body' style={{backgroundColor:'#edeef4'}}>
            <div className='h-w1300'>
                {/* banner img */}
                <img className='banner' src={language.languageType==='en'?banneren:bannerhk} alt='' />

                {/* coupon list */}
                <div className='coupon-box' >
                    <div className='coupon-box-item'>
                        <h3 className='coupon-title'>
                            <span className='left-icon'></span>
                            {language.rewardsTxt}
                            <p className='coupon-desc'>{language.rewardDesc}</p>
                        </h3>
                        <div className='coupon-claim-item'>
                            { 
                                couponList.length>0?couponList.map((item,index)=>{
                                    return <CouponItem key={index} info={{
                                            title: item.title,
                                            price: item.price,
                                            isClaim: item.isClaim
                                        }} />
                                }):<div className='no-coupons-box' style={{display:'flex',justifyContent:'center',flex:1}}>
                                    <NoCoupons language={language} />
                                </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}