import React from 'react';
import './canusecoupon.scss';
import {RightCircleOutlined} from '@ant-design/icons';

const CouponAmount =({info})=>{
    switch (info.couponType) {
        case 1:
            return info.couponDiscount*100+'% off'
        case 2:
            return '$'+info.couponDiscount
        case 3:
            return info.couponDiscount+'T'
        default:
            return 0
    }
}

const CouponCondition = ({info})=>{
    switch (info.couponType) {
        case 3:
            return info.couponCondition+'T';
        default:
            return '$'+info.couponCondition;
    }
}

export default function CanUseCoupon({info,setCouponId,cId}){
    const selectedCoupon=(id)=>{
        setCouponId(id);
    }
    return (
        <div className={`use-coupon-box ${cId===info.id?'selected':''}`} onClick={()=>selectedCoupon(info.id)}>
            <div className='use-coupon-left'>
                <div className='amount'>
                    <CouponAmount info={info} />
                </div>
                <div className='condition'>Applicable over <CouponCondition info={info} /></div>
            </div>
            <div className='use-coupon-right'>
                {/* <div className='use-ruler'>
                    Rules <RightCircleOutlined />
                </div> */}
                <div className='use-ruler'>{info.couponName}</div>
                <div className='use-date'>
                    Valid until {info.endTime}
                </div>
            </div>
        </div>
    )
}