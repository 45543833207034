import React, { useState,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './accountsetting.scss';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import {UserOutlined} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message } from 'antd';
import { LanguageContext } from '../../contexts/LanguageContext';


export default function AccountSetting(){
    const [walletAddress,setWalletAddress] = useState(true);
    const [memberInfo,setMemberInfo] = useState({});
    const [defaultCurrency,setDefaultCurrency] = useState('');

    const {token} = useContext(AuthContext);
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const handleWalletAddress = ()=>{
        setWalletAddress(!walletAddress);
    }

    const handleCopy = () => {
        message.success({
            content:language.copySuccess,
        });
    }

    const getMemberInfo = async()=>{
        const resData = await lsRequest('/memberInfo','POST',{l:language.requestType},token);
        if(resData){
            let addr = resData.data.address;
            resData.data.useName =addr?`${addr.slice(0,3)}***${addr.slice(addr.length-3,addr.length)}`:'';
            setMemberInfo(resData.data);
        }
    }

    const getCurrencyInfo = async()=>{
        const resData = await lsRequest('/memberAccount/getMemberAccountList','POST',{l:language.requestType},token);

        if(resData&&resData.data.list.length>0){
            setDefaultCurrency(resData.data.list[0].coinName);
        }
    }

    useEffect(()=>{
        getMemberInfo();
        getCurrencyInfo();
    },[])

    return (
        <>
            <p className='account-set-title'>{language.accountSetting}</p>
            <div className='account-info'>
                <div className='account-info-item' style={{flex:2}}>
                    <div className='account-name'>
                        <UserOutlined style={{marginRight:'5px'}} />
                        {memberInfo.useName}
                    </div>
                    <div className='account-uid'>
                        {language.addressTxt}: {memberInfo.address}
                        <CopyToClipboard text={memberInfo.address} onCopy={() => { handleCopy() }}>
                            <i className='bitfont bitfuzhi'></i>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='account-info-item' style={{flex:1}}>
                    <div className='withdraw-verify'>
                        {language.withdrawalVerification}
                    </div>
                    <div className='withdraw-verify-icon' onClick={()=>navigate('/dashboard/kyc')}>
                        <span className={`bitfont bitemishenfenzheng2 ${memberInfo.authStatus==1?'active':''}`}></span>
                    </div>
                </div>
            </div>
            <div className={`account-com-content ${walletAddress?'':'hidebox'}`}>
                <div className='account-com-title'>
                    <h3 className='com-title'>{language.addressManagement}</h3>
                    <span className={`bitfont bitxialajiantou ${walletAddress?'':'rotate'}`} onClick={handleWalletAddress}></span>
                </div>
                <div className="account-com-box">
                    <div className='icon-box'>
                        <i className='bitfont bitqianbao'></i>
                    </div>
                    <div style={{flex:1}}>
                        <h5 className='com-box-title'>{language.addressManagementTitle}</h5>
                        <p className='com-box-desc'>{language.addressManagementDesc}</p>
                    </div>
                    <button className='com-box-btn' onClick={()=>navigate('/dashboard/addrmanage/'+defaultCurrency)}>{language.manageBtnTxt}</button>
                </div>
            </div>
        </>
    )
}