import React,{useState} from 'react';
import './login.scss';
import LoginModal from './LoginModal.js';
import { Button } from 'antd';


export default function Login({language,toLoginStatus,setToLoginStatus,setToSignupStatus}){
    const [isModalOpen, setIsModalOpen] = useState(false);
   
    const getModalState = (e)=>{
        setToLoginStatus(e);
    }

    return (
        <div className='login'>
            <Button type='link' className="sign-btn" onClick={()=>setToLoginStatus(true)}>{language.loginBtn}</Button>
            <LoginModal isShow={toLoginStatus} SetHandleCancel={getModalState} setToLoginStatus={setToLoginStatus} setToSignupStatus={setToSignupStatus} language={language} />
        </div>
    )
}