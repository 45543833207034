import React from 'react';
import {PlusOutlined,MinusOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import '../fullinfo.scss';
import { InputNumber,Tooltip,ConfigProvider } from 'antd';
import vipTag from '../../../assets/vip1.png';
import InitBuyBtn from '../../../components/InitBuyBtn/InitBuyBtn';

export default function FreeInfo({info,minerPrice,servicePrice,totalPrice,setMinerPower,setServiceDays,toPay,language}){

    const changeMinerNum= (val)=>{
        if(val){
            setMinerPower(val);
        }
    }

    const changeServiceFee = (val)=>{
        if(val){
            setServiceDays(val);
        }
    }

    return (
        <div className='full-info'>
            <div className='info-left'>
                <div className='info-cover'>
                    <h3 className='info-left-title'>{info.package_name}</h3>
                    {/* <div className='info-left-data'>
                        <p className='data'>140TH/s</p>
                        <p className='data'>21.50J/T</p>
                        <p className='data'>3,031.50W</p>
                    </div> */}
                    <img className='cover' src={info.pics} alt='' />
                </div>
                <ul className='info-desc'>
                    {
                        language.fullPackageDesc.map((item,index)=>{
                            return <li key={index} className='desc-item'>{item}</li>
                        })
                    }
                </ul>
            </div>
            <div className='info-right'>
                <h3 className='right-title'>
                    <img className="vip" src={vipTag} alt='' />
                    {info.package_name} {info.periodDays} {language.daysTxt}
                </h3>
                <ConfigProvider
                    theme={{
                        components:{
                            InputNumber:{
                                controlWidth:160,
                                
                            }
                        }
                    }}
                >
                    <ul className='info-data'>
                        <li className='info-item first-item'>
                            <div className='info-lab'>{language.priceHashrate}：</div>
                            <span className='info-val'>{info.perFee}/{info.calPowerUnit}/{language.daysTxt} </span>
                        </li>
                        <li className='info-item'>
                            <div className='info-lab'>{language.powerTxt}</div>
                            <span className='info-val red-color'>{info.calPower}{info.calPowerUnit}/{language.unitTxt}</span>
                        </li>
                        <li className='info-item'>
                            <div className='info-lab'>{language.selectQuantity}：</div>
                            <div className='info-val'>
                                <div className='minus-box'>
                                    {/* <span className='btn'>
                                        <MinusOutlined />
                                    </span> */}
                                    <InputNumber 
                                        size='large'
                                        step={1}
                                        min={1} 
                                        max={info.remainNumber} 
                                        key={1}
                                        defaultValue={1} 
                                        onChange={changeMinerNum} 
                                        formatter={(value) => `${value} ${language.unitTxt}`}
                                        parser={(value) => value.replace(`${language.unitTxt}`, '')}
                                    />
                                    {/* <span className='btn'>
                                        <PlusOutlined />
                                    </span> */}
                                </div>
                            </div>
                        </li>
                        <li className='info-item'>
                            <div className='info-lab'>{language.minerPrice}</div>
                            <span className='info-val red-color'>${minerPrice}</span>
                        </li>
                    </ul>
                    {
                        info.serviceFee>0&&<ul className='info-data'>
                            <li className='info-item first-item'>
                                <div className='info-lab'>{language.electricityCosts}：</div>
                                <span className='info-val'>${info.serviceFee}/{language.unitTxt} /{language.daysTxt}</span>
                            </li>
                            <li className='info-item'>
                                <div className='info-lab'>{language.initialPeriod}：</div>
                                <div className='info-val'>
                                    <div className='minus-box'>
                                        {/* <span className='btn'>
                                            <MinusOutlined />
                                        </span> */}
                                        <InputNumber 
                                            size='large'
                                            min={info.minElectricDay} 
                                            max={info.periodDays} 
                                            key={info.minElectricDay}
                                            step={10} 
                                            defaultValue={info.minElectricDay} 
                                            onChange={changeServiceFee} 
                                            formatter={(value) => `${value}${language.daysTxt}/${info.periodDays}${language.daysTxt}`}
                                            parser={(value) => value.replace(`/${info.periodDays}${language.daysTxt}`, '')}
                                        />
                                        {/* <span className='btn'>
                                            <PlusOutlined />
                                        </span> */}
                                    </div>
                                    <Tooltip placement="top" title={language.initialPeriodTips} arrow={true}>
                                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'10px'}} />
                                    </Tooltip>
                                </div>
                            </li>
                            <li className='info-item'>
                                <div className='info-lab'>{language.priceTxt}</div>
                                <span className='info-val red-color'>$ {servicePrice} </span>
                            </li>
                            <li className='info-item'>
                                <div className='info-lab'>{language.orePrice}</div>
                                <span className='info-val red-color'>${info.minerPositionFee}</span>
                            </li>
                        </ul>
                    }
                    
                </ConfigProvider>
                
                <div className='total-amount'>
                    {language.totalPriceTxt}：<span className='amount'>${totalPrice}</span>
                </div>
                {
                  info.remainGoodsNum>0?<InitBuyBtn sTime={info.sellStartDate} eTime={info.sellEndDate} toPay={toPay} language={language} />
                  :<div className='buy-now-btn gray'>{language.SoldTxt}</div>
                }
                
            </div>
        </div>
    )
}