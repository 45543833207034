import React from 'react';
import { Table,Empty,ConfigProvider} from 'antd';

const tableHeader = [
    {
        title:'Type',
        dataIndex:'type',
        value:'type'
    },
    {
        title:'Email',
        dataIndex:'email',
        value:'email'
    },
    {
        title:'Validity Period(UTC+8)',
        dataIndex:'period',
        value:'period'
    },
    {
        title:'Selling Price (per pcs)',
        dataIndex:'price',
        value:'price'
    },
    {
        title:'Quantity',
        dataIndex:'quantity',
        value:'quantity'
    },
    {
        title:'Status',
        dataIndex:'status',
        value:'status'
    },
    {
        title:'Action',
        dataIndex:'action',
        value:'action'
    }
]

const customEmptyText = 'Please click the "Subscribe Now" to subscribe to the coupon you want!';

export default function ToSubscribe({list}){

    const locale =()=>{
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={customEmptyText} /> 
    };

    return (
        <>
            <div className='common-page-header'>
                <div className='title' style={{fontWeight:'normal'}}>Subscription Fee</div>
                <div className='header-btn-group'>
                    <button className='last-btn'>Subscribe Now</button>
                </div>
            </div>
            <ConfigProvider renderEmpty={locale}>
                <Table size='small' columns={tableHeader} dataSource={list} />
            </ConfigProvider>
            
        </>
    )
}