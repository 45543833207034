import React from 'react';
import './worldleader.scss';

import leaderImg0 from '../../../assets/supplier-1.webp';
import leaderImg1 from '../../../assets/supplier-2.webp';
import leaderImg2 from '../../../assets/supplier-3.webp';
import leaderImg3 from '../../../assets/supplier-4.webp';

const InitIconImg = ({index})=>{
    switch (index) {
        case 0:
            return <img className='img' src={leaderImg0} alt='' />
            break;
        case 1:
            return <img className='img' src={leaderImg1} alt='' />
            break;
        case 2:
            return <img className='img' src={leaderImg2} alt='' />
            break;
        case 3:
            return <img className='img' src={leaderImg3} alt='' />
            break;
        default:
            break;
    }
}

export default function WorldLeader({language}){
    return (
        <div className="world-leader h-w1300">
            <h4 className="title1">{language.WORLDLEADER}</h4>
            <h2 className='top-title'>{language.WORLDLEADERSUB}</h2>
            <p className='top-desc'>{language.WORLDLEADERDesc}</p>
            <ul className="leader">
                {
                    language.WORLDLEADERList.map((item,index)=>{
                        return  <li key={index} className="leader-item">
                            <div className='left-icon'>
                                <InitIconImg index={index} />
                            </div>
                            <div className="right-text">
                                <h3 className='title'>{item.title}</h3>
                                <p className='desc'>{item.desc}</p>
                                <p className='desc'>{item.info}</p>
                            </div>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}