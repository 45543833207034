import React,{useEffect, useState,useContext} from 'react';
import {Button,Checkbox,Modal,Input,message} from 'antd';
import './registerModal.scss';
import registerBg from '../../assets/login_bg.png';
import {useWeb3Modal} from '@web3modal/wagmi/react';
import { useDisconnect,useAccount,useSignMessage } from 'wagmi';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { useNavigate } from 'react-router-dom';
import baseName from '../../utils/baseName';

const RegiserModal = ({isShow,SetHandleCancel,language,setToSignupStatus,setToLoginStatus})=>{
    const {toggleAuth} = useContext(AuthContext);
    const navigate = useNavigate();

    const mid = new URLSearchParams(window.location.search).get('mid')||'';//获取渠道id
    const ivtCode = localStorage.getItem('ivtCode')||'';

    const [showCode,setShowCode] = useState(true);
    const [agreeStatus,setAgreeStatus] = useState(false);
    const [referralCode,setReferralCode] = useState(ivtCode);
    const [isRequesting,setIsRequesting] = useState(false);

    const {open} = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { address} = useAccount();
    const {data, isError, isLoading, isSuccess, signMessage} = useSignMessage({message:'haozizuishuai1'});

    const connectWallet= ()=>{
        open();
    }
    const disconnectWallect = ()=>{
        disconnect();
    }
    

    const onChange = (e) => {
        setAgreeStatus(e.target.checked);
    };

    const handleCancel = (state) => {
        SetHandleCancel(state)
    };

    const changeCodeBox = ()=>{
        setShowCode(!showCode)
    }

    const getInviteCode = (e)=>{
        setReferralCode(e.target.value);
    }

    const toSign = ()=>{
        if(!address){
            message.info(language.connectWalletTips);
            return false;
        }

        if(!agreeStatus){
            message.info(language.agreeUserAgreementTips);
            return false;
        }
        signMessage();
        setIsRequesting(true);

    }

    const toRegister = async()=>{
        setIsRequesting(false);
        let reqData = {
            address:address,
            methodId:mid,
            signature:data,
            message:'haozizuishuai1',
            inviteCode:referralCode
        };

        const resData = await lsRequest('/register','POST',reqData);
        
        if(resData){
            message.success(language.successfulRegistTips);
            resData.data.oldAddress = address;
            setTimeout(() => {
                handleCancel(false);
                toggleAuth(resData.data);
            }, 2000);
        }
    }

    useEffect(()=>{
        if(isSuccess&&isRequesting){
            toRegister();
        }
    },[isSuccess])

    return (
        <>
            <Modal zIndex={99} open={isShow} onCancel={()=>handleCancel(false)} centered footer={null} width={700}>
                <div className="register-modal">
                    <div>
                        <img className='login-bg' src={registerBg} alt="" />
                    </div>
                    <div className="login-content">
                        <div className="login-title">{language.signUpTips} {baseName}</div>
                        {
                            address?<div className="login-continue">
                            
                            <div className="ipt-lab">{language.walletAddressTxt}</div>
                            <div className='address'>
                                {address}<Button className="dis" size='small' shape='round' onClick={disconnectWallect}>{language.DISCONNECT}</Button>
                            </div>
                        </div>:<Button className="connect-btn" shape='round' size='large'onClick={connectWallet} block>{language.connectWalletTxt}</Button>
                        }
                        <div className="ipt-lab1" onClick={changeCodeBox}>
                            {language.referralCodeOptional}
                            <span className={`bitfont bitxiala ${showCode?'bithide':''}`}></span>
                        </div>
                        <div className={`code-box ${showCode?'code-box-hide':''}`}>
                            <Input size='large' defaultValue={ivtCode} onChange={getInviteCode} placeholder={language.referralCodeOptional} />
                        </div>
                        <div className="login-check">
                            <Checkbox className="check" onChange={onChange}></Checkbox>
                            {language.haveReadAgree} <span className="ruler">{language.userRegAgreement}</span>; 
                        </div>
                        <Button onClick={toSign} className="connect-btn" shape='round' type="primary" size='large' block loading={false}>{language.SUBMITBtn}</Button>
                        <div className="login-footer">
                            {language.alreadyHasAccount}?<Button className="sign-up" type="link" onClick={()=>{
                                setToSignupStatus(false)
                                setToLoginStatus(true);
                            }}>{language.signInTxt}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RegiserModal;