import baseName from "./baseName";

export const en = {
    login:'SIGN UP',
    home:'HOME',
    logOut:'Log Out',
    language:'English',
    languageType:'en',
    requestType:'e',
    // navbar
    cloudMiningTitle:'CLOUD MINING',
    minerTitle:'MINER',
    couponTitle:'COUPON',
    newsTitle:'NEWS',
    dashboardTitle:'DASHBOARD',
    helloTitle:'Hello!',
    myOrderTitle:'My Orders',
    setTtitle:'Settings',
    logoutTitle:'Log Out',
    //home page
    bannerTips:'No banner data',
    minerSalesTxt:'Miner Sales',
    RentalTypeMineSite:"Rental type mine site",
    RentalTypeMineSiteDesc:"Each mining slot costs $600 and is valid for three years from the start of the machine's cloud computing power, during which time users can take advantage of a discounted rate of 0.057 cents/KW/H for electricity (the original price was 0.075).",
    minerHostingTxt:'Miner Hosting',
    SelfcontainedMineLevel:"Self-contained mine level",
    SelfcontainedMineLevelDesc:"Reserve your own mining space in advance and enjoy an exclusive discount of only 0.057 USD/KW/H (from 0.075 USD/KW/H) during the validity period.",
    moreTxt:'More',
    cloudMiningHomeNotes:'We offer a completed cloud mining solution that save the hassle for "Purchase - Transport - Hosting - Maintenance" of the devices.',
    minerHomeNotes:'Miner rental offers immediate mining, shorter breakeven period, lower cost, lower risks, and increased flexibility compared to traditional miner purchase.',
    newUserBtn:'New User Purchase',
    buyBtn:'Buy Now',
    daysTxt:'days',
    staticOutputRatio:'Static Output Ratio',
    staticORfreeTips:'Static Output Ratio = Net output / First Day Payment',
    staticORTips:'Static Output Ratio = Net output / First Day Payment',
    freeMiningTags:'New User Purchase',
    trialCashTxt:'Trial Cash',
    soldOut:"Sold Out",
    staticBreakevenDays:"Static Breakeven Days",
    fullRevenueTxt:'Full Revenue',
    hashrateFeeTxt:'Hashrate Fee',
    serviceFeeTxt:'Service Fee',
    rentalFeeTxt:'Rental Fee',
    hostingFee:'Hosting Fee',
    checkDetailTxt:'Check Detail',
    minerHostingDesc:`Personal-owned miners and miners bought from the platform can all be hosted by ${baseName}'s mine, which is operated and maintained by professionals 24/7. Hosting service fee （including electricity fee) is $ 0.075 - 0.085 / kWh. While receiving the mining output, you will also profit from the miner's price increase.`,
    tabMinerSales:[
        {
            title:'Brand Advantage',
            desc:'With the strategic investment from BITMAIN, we developed in-depth cooperation in fields like miners, miner rooms, and miner maintenance.'
        },
        {
            title:'Supply Guarantee',
            desc:'With the first-hand flagship miners from BITMAIN, we can meet your diverse needs.'
        },
        {
            title:'Professional After-sales Service',
            desc:'Provide professional services such as pick-up and delivery, miners warranty, after-sales maintenance and etc'
        }
    ],
    WORLDLEADER:'WORLD LEADER',
    WORLDLEADERSUB:'CLOUD HASHRATE PLATFORM',
    WORLDLEADERDesc:`${baseName}, invested by BITMAIN, is a fast-growing pioneer in digital asset mining and cloud mining services. As the only strategic partner in the cloud mining services of BITMAIN worldwide, we are dedicated to providing a secure, compliant, and transparent blockchain infrastructure with various stable and intelligent digital asset mining solutions for our global users.`,
    WORLDLEADERList:[
        {
            title:'Strategic Partnership With BITMAIN',
            desc:'Joint launch sales and supply of the latest mining machines.',
            info:'In-depth cooperation in miner, plant, maintenance, etc.',
            imgName:''
        },
        {
            title:'Mining Resources',
            desc:'Hosting Capacity 300MW+',
            info:'Miners Under Management 100,000+'
        },
        {
            title:'Top Hashrate Service',
            desc:'Hashrate Under Management 10EH/s+',
            info:'Sales Amount $100,000,000+'
        },
        {
            title:'International Cloud Hashrate Platform',
            desc:'12+ Global Mining Farms in Several Countries',
            info:'100+ Countries Service Coverage'
        }
    ],
    appDownClickBtnTips:'Not yet available, stay tuned!',
    appDownSub:'APP Login and Receive 200U Newcomer Gift',
    appDownTitle:'START YOUR MINING JOURNEY',
    appDownDesc:'Manage Your Revenue Anytime, Anywhere',
    needHelpTxt:'NEED HELP',
    needHelpList:[
        {
            title:'24/7 Chat Support',
            desc:'Contact our customer support with your questions at any time.'
        },
        {
            title:'FAQ',
            desc:'Check out the FAQ for details of specific features.'
        },
        {
            title:'Twitter',
            desc:'Follow us for the latest news and comments.'
        }
    ],
    partnersTxt:'Partners',
    footerDesc:`The World's Top Hashrate Service Provider`,
    productTxt:'Product',
    companyTxt:'COMPANY',
    FAQNewsTxt:'FAQ & News',
    supportTxt:'Support',
    aboutTxt:'About',
    helpCenterTxt:'Help Center',
    privacyPolicy:'Privacy Policy',
    termsService:"terms of service",
    ContactEmail:'Contact Email',

    // cloud mining 
    cloudMiningPageDesc:'Lock in double hashrate and output by paying only a partial service fee, and optimize earnings by paying the remaining fees in batches based on market conditions.',
    compareTxt:'Compare',
    productPositioning:'Product Positioning',
    minimumInvestment:'Minimum Investment',
    outputReturns:'Output Returns',
    serviceFeePayment:'Service Fee Payment',
    cloudTabListTxt:[
        {
            title:'Easy BTC',
            desc:'Newcomer recommendation',
            txt1:'Entry-level product without the need for calculations',
            txt2:'$20',
            txt3:'101%~105%',
            txt4:'User owns 100%',
            txt5:'Full Payment'
        },
        {
            title:'Full Revenue',
            desc:'Earn higher returns',
            txt1:'Mining products with higher returns',
            txt2:'$80-$200',
            txt3:'101%-125%',
            txt4:'User owns 100%',
            txt5:'Partial upfront service fees, rest paid later'
        },
        {
            title:'Stable Mining',
            desc:'VIP Exclusive 、Stable Output',
            txt1:'Super stable mining output',
            txt2:'0.5 BTC',
            txt3:'101%-105%',
            txt4:'User owns 100%',
            txt5:'Full Payment'
        }
    ],

    // miner page 
    minerDesc:[
        {
            title:'Purchase Discount',
            desc:'Exclusive user discounts and benefits.'
        },
        {
            title:'The Most Powerful Miner',
            desc:'Efficiency of miners is absolutely leading, a single miner is as high as 200T.'
        },
        {
            title:'Reserve the First Batch of Miners',
            desc:'Placed an order to secure the first batch of miners scheduled for January.'
        },
        {
            title:'High-quality Mine, Safe Custody',
            desc:'Secure cooperative mine slots and enjoy efficient operation and maintenance services.'
        }
    ],




    // coupon page 
    rewardsTxt:'Rewards',
    rewardDesc:'descriptions',

    // dashboard page 
    myHashrateTxt:'My Hashrate',
    dashboardMenuList:[
        {
            labText:'My Fortune',
            path:'/dashboard',
            iconName:'fortune',
            showRedDot:false
        },
        {
            labText:'My Hashrate',
            path:'/dashboard/hashrate',
            iconName:'',
            showRedDot:false
        },
        {
            labText:'My Miner',
            path:'/dashboard/hosting',
            iconName:'',
            showRedDot:false
        },
        {
            labText:'My Mines',
            path:'/dashboard/oreOrder',
            iconName:'',
            showRedDot:false
        },
        // {
        //     labText:'My Points',
        //     path:'/dashboard/points',
        //     iconName:'',
        //     showRedDot:false
        // },
        // {
        //     labText:'My Earns',
        //     path:'/dashboard/earns',
        //     iconName:'',
        //     showRedDot:false
        // },
        {
            labText:'My Wallet',
            path:'/dashboard/wallet',
            iconName:'',
            showRedDot:false
        },
        // {
        //     labText:'Service Fee',
        //     path:'/dashboard/servicefee',
        //     iconName:'service',
        //     showRedDot:false
        // },
        {
            labText:'My orders',
            path:'/dashboard/myorders',
            iconName:'order',
            showRedDot:false
        },
        {
            labText:'Invite',
            path:'/invite',
            iconName:'invite',
            showRedDot:false
        },
        {
            labText:'My Coupon',
            path:'/dashboard/mycoupon',
            iconName:'coupon1',
            showRedDot:false
        },
        // {
        //     labText:'BITMAIN Coupon',
        //     path:'/dashboard/bitcoupon',
        //     iconName:'coupon2',
        //     showRedDot:false
        // },
        {
            labText:'Settings',
            path:'',
            iconName:'setting',
            showRedDot:false
        },
        {
            labText:'Account Setting',
            path:'/dashboard/accountsetting',
            iconName:'',
            showRedDot:true
        },
        {
            labText:'KYC',
            path:'/dashboard/kyc',
            iconName:'',
            showRedDot:true
        }
    ],
    

    // free detail 
    freePackageDesc:['Obtain coins at lower cost than direct purchasing','All-inclusive fees, get BTC easily','Buy and start mining instantly, get BTC daily'],
    quantityPurchased:'Quantity Purchased',
    durationStaticOutput:'Duration Static Output',
    equivalentTxt:'Equivalent',
    equivalentTips:'Duration Static Output = Currency Mined during the Duration * Reference Currency Price Calculated based on the reference price of 1 BTC ≈ ',
    staticOutputRadioTips:'Static Output Ratio = Duration Static Output / Cost Calculated based on the reference price of 1 BTC ≈ ',
    staticCurrencyAcquisitionPrice:'Static Currency Acquisition Price',
    exchangeRateTips:'Static Currency Acquisition Price = Purchase Amount / Duration Static Output of BTC',
    FAQsTxt:'FAQs',
    FAQsList:(panelStyle) => [
        {
          key: '1',
          label: '1. Differences Between Cloud Mining and Direct Bitcoin Purchase',
          children: <p>Cloud mining theoretically allows for the potential to acquire more bitcoins than purchasing them directly, as, under the condition of the same investment amount and Bitcoin price, mining offers a fixed production rate. This is more efficient compared to direct purchases, where there might be redundancy.</p>,
          style: panelStyle,
        },
        {
          key: '2',
          label: '2. What is the difference between Easy Mining and Pro Mining?',
          children: <p>The cost of Easy Mining includes all Hashrate fees and service fees in the duration. You can pay the service fee in one lump sum. Or you can only pay part of the service fee first and pay again before the service fee expires. If you choose Pro Mining and pay the service fee gradually, the timing of service fee payment, the currency of payment, and the market fluctuation will affect your total cost.</p>,
          style: panelStyle,
        },
        {
          key: '3',
          label: '3. How to calculate the output of a plan?',
          children: <p>The output is based on the static output estimation. Static Output = Purchased Hashrate * Duration * estimated daily output per T. Purchased Hashrate = Purchase amount / Hashrate and service fee / Duration. The estimation of daily output per T was calculated statically by the current difficulty (67.31T).</p>,
          style: panelStyle,
        }
    ],
    disclaimerTxt:'Disclaimer',
    disclaimerList:[
        {
            txt:`1.As specified in ${baseName} terms of use, once an order has been paid, it is unchangeable and non-refundable.`
        },
        {
            txt:`2.${baseName} will not be responsible for any results that arise from uncontrollable risks, including but not limited to natural disasters such as floods, volcanic eruptions, earthquakes, landslides, fires, storms, other unforeseen or higher risk weather events, government actions or directives, city-level grid power supply incidents as well as social actions or conflicts such as war, strikes, riots, etc.`
        },
        {
            txt:`3.Hashrate fluctuation is inevitable. ${baseName} will not be responsible for fluctuations caused by unstable networks, network failures, miner performance, etc.`
        },
        {
            txt:`4.Static Output was calculated statically by the current difficulty, which fluctuates with the market. It will be lower or higher when the difficulty rises or drops. Thus, it is for reference only, and ${baseName} will not make any commitments or promises on output.`
        }
    ],

    // easypay page 
    easypayTabHeader:[
        
        {
            title:'Service/Product',
            dataIndex:'name',
            value:'name',
            align:'center'
        },
        {
            title:'Price',
            dataIndex:'price',
            value:'price',
            align:'center'
        },
        {
            title:'Amount',
            dataIndex:'amount',
            value:'amount',
            align:'center'
        },
        {
            title:'Days Purchased',
            dataIndex:'days',
            value:'days',
            align:'center'
        },
        {
            title:'Total',
            dataIndex:'total',
            value:'total',
            align:'center'
        }
    ],
    submitAgreeTips:'Please agree to Mining Service Agreement',
    paymentSuccessful:'Payment successful',
    orderInformationTitle:'Order Information',
    attentionTxt:'Attention',
    attentionTips:`This product is a newcomer's package, only one copy per person can be purchased`,
    minerFeeTxt:'Miner Fee',
    orderTotal:'Order Total',
    acceptTxt:'I accept',
    acceptAgreeTxt:'Risk Notice Description User Service Agreement',
    submitTxt:'Submit',
    verifyModalDesc:'Your accumulated transaction amount has exceeded the maximum trade amount allowed for your current identity verification level. Complete the identity verification process to increase tyour maximum trade amount.',
    cancelBtnTxt:'Cancel',
    verifyBtnTxt:'Verify',

    // full page 
    productIntroduction:'Product Introduction',
    fullPackageDesc:['Buy more hashrate by paying service fees later','Earn excess returns by paying fees in batches','Ensure a guaranteed hashrate online rate of 95%+'],
    priceHashrate:'Price of Hashrate',
    selectQuantity:'Select Quantity',
    priceTxt:'Price',
    serviceFeePrice:'Service Fee Price',
    initialPeriod:'Initial Period',
    initialPeriodTips:'You can set up automatic deduction or manual payment for unpaid service fees',
    totalPriceTxt:'Total Price',
    productDetails:'Product Details',
    packageType:'Package Type',
    currencyTxt:'Currency',
    planDuration:'Plan Duration',
    validTime:'Valid Time',
    miningPool:'Mining Pool',
    revenueAddress:'Revenue Address',
    immediatelyTxt:'Immediately',
    personalWallet:'Personal Wallet',
    fullRevenueTips:'In full revenu mode, you will get all the mining output during the cycle',
    noDataTxt:'No Data',

    // fullpay page 
    orderInfoDataHeader:[
        {
            title:'Service/Product',
            dataIndex:'name',
            value:'name',
            align:'center'
        },
        {
            title:'Price',
            dataIndex:'price',
            value:'price',
            align:'center'
        },
        {
            title:'Amount',
            dataIndex:'amount',
            value:'amount',
            align:'center'
        },
        {
            title:'Days Purchased',
            dataIndex:'days',
            value:'days',
            align:'center'
        },
        {
            title:'Total',
            dataIndex:'total',
            value:'total',
            align:'center'
        }
    ],
        fullpayTab:[
            {
            key: '0',
            label: 'Miner Fee Coupon'
            },
            {
            key: '1',
            label: 'Service Fee Coupon',
            }
        ],
        fullpayeCloudNote:`Note: If you didn't pay for the full duration of service in one lump sum, you can set up an automatic deduction or manual payment in 'My Hashrate'`,
        fullpayeMinerNote:`Note: If you have not purchased a full cycle of electricity, you can turn on electricity withholding in "My Miner" or pay the electricity manually.`,
        couponTxt:'Coupon',
        minerFeeCoupon:'Coupon',
        noCouponsTxt:'No Coupons',

        // my fortune 
        pageNote:'Complete your personal information and start your mining journey',
        kycVerification:'KYC Verification',
        kycVerDesc:'Due to local laws and policies, you are required to complete real-name identity verification',
        notVerifiedTxt:'Verify',
        verifiedTxt:'Verified',
        totalAssetsConverted:'Total Assets Converted',
        totalAssetsConvertedTips:'Total Assets Converted = My Hashrate Assets + My Hosting Assets.',
        approximatelyTxt:'Approximately',
        myHashrateConverted:'My Hashrate Converted',
        myHashrateConvertedTips:'Single Hashrate Asset = (Unit Price of Hashrate at the Time of Purchase * Remaining Operation Days * Hashrate Amount) + (Unit Price of Service Fee * Remaining Service Fee Days * Hashrate Amount). The sum of the hashrate assets is the conversion of hashrate assets.',
        detailsBtnTxt:'Details',
        hashrateOwned:'Hashrate Owned',
        latestOutput:'Latest Output',
        buyTxt:'BUY',
        myMinerConverted:'My Miner Converted',
        myMinerConvertedTips:'The sum of purchase of all miners is My Hosting Converted.',
        minersOwned:'Miners Owned',
        unitTxt:'unit',

        //my hashrate
        EarningsTips:'Earnings from 8:00 yesterday to 8:00 today (GMT+8)',
        SwitchOnTxt:'ON',
        SwitchOffTxt:'OFF',
        autoPayTxt:'Auto-pay service fee',
        hashrateList:'Hashrate List',
        dailyEarningsTxt:' Daily Earnings',
        hashrateListTips:[
            {
                title:'Minimum threshold for mining earnings',
                info:'If the daily mining payout is greater than 0.005 BTC, the mining pool will make a payment on the same day; if the daily payout is less than 0.005 BTC, the mining pool will make a payment once the accumulated payout reaches 0.005 BTC.'
            },
            {
                title:'Small amount transfer',
                info:'Payout of earnings remaining in the mining pool after package completion - After the order is completed, BTC outputs that meet the payout threshold will be initiated for small payouts within 48 hours. Otherwise, the outputs will remain in the mining pool.'
            }
        ],
        nameTxt:'Name',
        dateTxt:'Date',
        totalAmountTxt:'Total Order Amount',
        orderStatus:'Order Status',
        actionTxt:'Action',

        // my hosting 
        miningList:'Co-Mining List',

        //my wallet
        amountTxt:'Amount',
        timeTxt:'Time',
        statementTxt:'Statement',
        depositTxt:'Deposit',
        withdrawalTxt:'Withdrawal',
        
        // Deposit 
        copySuccess:'Copy Success',
        myWalletTxt:'My Wallet',
        walletTxt:'Wallet',

        // Withdrawal 
        withdralQuantityTips:'Quantity must be greater than gas fee',
        selectWalletAddressTips:'Please select wallet address',
        balanceTips:'The balance is insufficient',
        minimumNumberTips:'The number of coins withdrawn cannot be less than the minimum number',
        successfulApplication:'Successful application',
        withdrawalsNum:'Number of withdrawals',
        withdrawMinimumAmount:'Minimum amount of coins to withdraw',
        walletBalance:'Your wallet balance',
        gasFee:'Gas fee',
        actualReceipt:'Actual receipt',
        confirmBtnTxt:'Confirm',
        numberTxt:'Number',
        
        //add address modal
        enterWalletAddressTips:'Please enter wallet address',
        enterAddressNoteTips:'Enter address note',
        addNewAddress:'Add new address of',
        OKtxt:'OK',

        // my order 
        orderList:'Order List',

        // my coupon 
        allTxt:'All',
        availableTxt:'Available',
        usedTxt:'Used',
        expiredTxt:'Expired',
        bigCouponTxt:'C O U P O N',
        applicableOver:'Applicable over',
        rulesTxt:'Rules',
        getTxt:'Get',

        // Account Setting 
        accountSetting:'Account Setting',
        addressTxt:'Address',
        withdrawalVerification:'Withdrawal Verification',
        addressManagement:'Address Management',
        addressManagementTitle:'Wallet Address Management',
        addressManagementDesc:'Manage your frequently used cryptocurrency wallet address',
        manageBtnTxt:'Manage',

        //address manage
        noteTxt:'Note',
        deleteAddress:'Delete the Address',
        deleteAddressOk:'Are you sure to delete this Address?',
        yesTxt:'Yes',
        noTxt:'No',
        deleteTxt:'Delete',
        selectChainTips:'Please select chain',
        deletedSuccessfully:'Deleted successfully',
        chainTxt:'Chain',
        selectShainTips:'Please select chain name',
        addAddressTxt:'Add Address-',
        modifyRemarks:'Modify remarks',
        selectCurrency:'Please select currency',

        // kyc page 
        identityVerification:'Identity Verification',
        personalVerification:'Personal Verification',
        basicVerification:'Basic Verification',
        basicVerificationDesc:'You can withdraw cash after completing the authentication',
        verificationNotes:`The services provided by ${baseName} are not available to residents (hereinafter referred to as "restricted individuals") of the following countries, including the United States, Mainland China, Crimea, Cuba, Iran, Russia, North Korea, Syria, Singapore, and Hong Kong SAR, China, or any entity or individual subject to restrictions under applicable trade sanctions and export laws. The list above may not be exhaustive. Before using the services provided by ${baseName}, please ensure that you are not a "restricted individual".`,

        //kyc base page
        uploadIDPhoto:'Upload ID Photo',
        IDCard:'ID Card',
        IDCardDesc:'Please provide photos of the front and back of your ID card.',
        enterName:'Enter your name',
        enterIDnumber:'Enter your ID number',
        frontTxt:'Front',
        backTxt:'Back',
        uploadDescList:[
            {
                txt:'You must provide your own photo ID.，',
                imgSize:'Image size 20KB~2M, format JPG, PNG;'
            },
            {
                txt:'• The picture of the ID provided must be a photo. Photocopies or scans shall not pass the verification;'
            },
            {
                txt:' • Your name on the ID must be in English, if your ID card (or Driving License) does not show your English name, please use your passport to apply, otherwise, it shall not pass the verification.'
            },
            {
                txt:'•Please provide your genuine ID that is clear and without any alteration;'
            },
            {
                txt:'• Please provide an ID that is within its validity period'
            }
        ],
        continueTxt:'Continue',
        backPage:'BACK',
        resultTitle:'Certification submitted',
        resultContent:'Please be patient, we will complete the review within 24 hours',


        //invite page
        inviteTabTxt:'Invition',
        myFriendTxt:'My Friend',
        invitationTime:'Invitation Time',
        rewardTxt:'Rewards',
        noRecordTips:'No record of invitations',
        invitationRanking:'Invitation Ranking',
        rankingTxt:'Ranking',
        userNameTxt:'UserName',
        noRecordTxt:'No Data',
        invitedPeoples:'peoples have been invited',
        inviteStepList:[
            {
                title: `Friend's first order within 30 days`,
                sub1:'You get',
                sub2:'10 Points',
                sub3:'rewards'
            },
            {
                title: `Friend's each single purchase ≥ $100`,
                sub1:'You get hashrate',
                sub2:'1.5%',
                sub3:'rebates'
            },
            {
                title: `5 friends placed orders for a long-duration plan`,
                sub1:'Get coupons worth',
                sub2:'$1,000'
            }
        ],
        sendInvitation:'Send invitation',
        referralCodeOptional:'Referral Code (Optional)',
        invitationLink:'Invitation link',
        inviteNow:'Invite Now',
        myRewards:'My Rewards',
        accumulatedrewards:'Accumulated rewards',
        inviteesNumber:'Number of invitees',
        referralCode:'Referral Code',
        posterTxt1:'Sign up today to unlock',
        posterTxt2:'Sign up today to unlock',
        eventRules:'Event Rules',
        eventRulesList:[
            {
               title:'Inviter Rewards:',
               desc:[
                '1. First-Order Rewards: For each invited friend who completes their first order with a minimum value of $100 within 30 days after registration, the inviter will receive a first-order reward. The higher VIP level, the higher the reward. Please refer to the "My Reward Level" table for specific details.',
                '2. Consumption Rebate：For each friend who completes every transaction with a total amount of $100 or more after registration, the inviter will enjoy a consumption rebate. The rebate amount is 2% of the hashrate fee. The higher the level, the longer the rebate cycle (the rebate cycle starts from the registration date of the invitee). The maximum rebate for a single person is 10,000 Points. Please refer to the "My Reward Level" table for specific details.',
                '3. "Enduring Friends" Rewards: If the inviter accumulates 5 or more successful referrals, and any friend completes a 360-day Plan, the inviter will receive a total coupon reward of $1,000.',
                'Notice: The inviter needs to complete KYC identity authentication to withdraw the rewards.'
               ] 
            },
            {
                title:'Invitee Rewards:',
                desc:[
                 '1. New user registration bonus - Your account will be credited with $1000 trial cash, requiring zero investment for mining experience.',
                 '2. New user simple package - Reduced rewards cost for a basic mining experience, with a minimum required computing power of only $14.',
                 '3. New user advanced mining - Exclusive 10% discount on a 30-day package for an advanced mining experience.'
                ] 
             }
        ],


        //invitee page
        strategicInvestment:'Strategic Investment',
        yourFriends:'Your Friends',
        inviteReceiveTxt:'Invite you to receive $1,000 trial cash',
        inviteeBtn:'Free Mining',
        receivedRewardsPeople:'people have received rewards',
        stepsNiceGifts:'3 Easy Steps for 3 Nice Gifts',
        giftsSetpsList:[
            {
                title:'Register an account',
                desc:'$1,000 Trial Cash, Free Mining'
            },
            {
                title:'Complete your KYC',
                desc:'Double the Free Mining Profits'
            },
            {
                title:'Free Mining Ends',
                desc:'Purchase with the Profits'
            }
        ],
        askedQuestions:'Frequently Asked Questions',
        questions1:'How to Register',
        questions2:'How to Complete KYC',
        questions3:'Frequently Asked Questions',

        // sign up and login in 
        connectWalletTips:'Please connect your wallet',
        agreeUserAgreementTips:'Please agree to the User Agreement',
        signUpTips:'Sign Up to',
        walletAddressTxt:'Wallet address',
        DISCONNECT:'DISCONNECT',
        connectWalletTxt:'Connect Wallet',
        haveReadAgree:'I have read and agree to ',
        userRegAgreement:'the User Registration Agreement',
        SUBMITBtn:'SUBMIT',
        signInTxt:'Sign in',
        alreadyHasAccount:'Already has account',
        signUpBtn:'SIGN UP',
        loginBtn:'LOGIN',
        successfulRegistTips:'Successful registration',
        loginSuccessfulTips:'Login Successful',
        loginTo:'Login to',
        NotMember:'Not a member',
        signupNow:'Sign up Now',
        userLoginAgreement:'the User Agreement',

        //404 page
        pageNotFound:'Page not found',
        pageNotFoundDesc:'Sorry, the page you visited does not exist.',
        backHome:'Back Home',

        //Order Detail
        OrderDetail:'Order Detail',
        OrderNo:'Order No',
        MinerName:'Miner Name',
        CurrencyType:'Currency Type',
        CreationTime:'Creation Time',
        ShelfDate:'Shelf Date',
        Dateofexcavation:'Date of excavation',
        QuantityTxt:'Quantity',
        TotalFee:'Total Fee',
        DataTxt:'Data',

        //replenishment
        DateTxt:'Date',
        logoutTips:'Exit successful',
        addressChangeTips:'Wallet address switched successfully, please log in again',
        loginTips:'You are not logged in',
        powerTxt:'Arithmetic power',
        PendingTxt:'Pending',
        ConfirmedTxt:'Confirmed',
        PaidTxt:'Paid',
        ShelveTxt:'Shelve',
        UnknownTxt:'Unknown',
        totalPower:'TotalPower',
        SoldTxt:'Sold',
        totalCosts:'Total cost',
        runStatus:'Status',
        profitTxt:'Profit',
        minerProfit:`Miner's earnings`,
        cloudProfit:'Arithmetic gain',
        myMiner:'My miner',
        checkDetail:'View Details',
        detailsTxt:'Details',
        LatestOutput:'Latest output',
        RemainingServiceFee:'Remaining service fees',
        RemainingElectricityFee:'Remaining electricity costs',
        startTime:'Start Time',
        copyTxt:'copy',
        buyDays:'Days Purchased',
        electricityCosts:'Electricity costs',
        minerPrice:'Mining Machine Prices',
        costRecovery:'Cost Recovery%',

        //Rentalminers
        rentalMinersTxt:'Rental of miners',
        MinersProductTxt:'Mineral Level Products',
        purchaseMiners:'Purchase of miners',
        buyTips:'Do not buy this product',
        oreList:'Ore level orders',
        RentalUnitPrice:'Rental unit price',
        UnitPrice:'Price',
        ProductsTxt:'Products',
        DiscountedElectricityRates:'Discounted electricity rates',
        centsTxt:'cents',
        profitabilityTxt:'Profitability',
        annualisedAbout:'Annualised about',
        paybackTxt:'Payback',
        afterPayback:'After payback',
        endTime:'End time',
        investmentPromotionBuffer:'Investment promotion buffer',
        investBufferTxt:'The investment buffer period is the standard time for the operation and maintenance team to attract investment and set up the machine, if the machine transfer, downtime maintenance, power outages, etc. occur during the lease period, the platform still ensures that the miner`s income will not be affected, so the buffer is set aside for 3 to 5 months.',
        MineralPositionProjectModel:'Mineral Position Project Model',
        minerModelTxt:'Mining is a high energy consumption project, so the construction of the mine is generally built around the cheap energy, the mine is a system project engineering, the entire project six months after the completion of the construction and then split to individual mining positions calculated by the product for investment. In the United States, for example, the construction of a 100,000KW air-cooled mine, need to invest tens of millions of U.S. dollars, is expected to produce about 30,000 mining positions, then the construction cost of a single mining position is close to 1,000 U.S. dollars.',
        ProfitModel:'Profit Model',
        ProfitModelTxt:'Unlike the mining of miners, the mining position mainly relies on the difference in electricity fees paid by miners for profitability, which is more stable and controllable as fiat currencies are used for both the income and expenditure of electricity fees.',
        ProfitModelTxtExample:'Example: For example, the cost of clean energy in the United States is 5 cents, and the price of electricity sold to miners is 7.5 cents, then the difference in electricity costs for the mining position is 2.5 cents. 1 miner 3KW calculations, the difference in electricity costs for 1 mining position = 3KW * S0.025 * 24 hours * 365 days = $657/year. Due to the mining market is hot, mining bit item and is a good business with large investment, quick return and continuous income.',
        LeaseRevenueModel:'Lease revenue model',
        Iagree:'I agree',
        minerAgree:'to the lease agreement for the mine site',
        ActualIssuance:'Actual issuance',
        IssuableTxt:'Issuable',
        powerWastage:'Power wastage',
        hourTxt:'hour',
        getProfit:'Profit',
        totaRent:'Total rent',
        InsufficientAccount:'Insufficient account balance',
        minerPageSub1:'Rental fee as low as 30% of miner price',
        minerPageSub2:'Hashrate online rate of 95%+',
        minerPageTips:'Miner rental offers immediate mining, shorter breakeven period, lower cost, lower risks, and increased flexibility compared to traditional miner purchase.',
        orePrice:'mine fee',
        BackCurrentCycle:'Back to current cycle',
        managementExpense:'Management expense',
        OpeningTime:'Opening time',
        endTime:'End time',
        minerAgreeTips:'Please agree to purchase a lease agreement for the mine site',
        checkProfit:'View Earnings',
        myMinesTxt:'My Mines',
        completedTxt:'Completed',
        MiningTxt:'Mining',
        DiscontinuedTxt:'Discontinued',
        unknownStatus:'Unknown',
        actualPaymentTxt:'Actual payment',
        paybackStateTxt:'payback state',
        Returned:'Returned',
	    NotReturned:'Not returned',
        MiningRemaining:'Remaining days of mining',
        CumulativeOutput:'Cumulative output',
        YesterdayOutput:'Yesterday output',
        MiningDays:'Mining days',
        MiningStartEndTimes:'Mining start and end times',
        MiningStartTimes:'Mining start times',
        MiningEndTimes:'Mining end times',
        DistanceToEnd:'Distance to end',
        DistanceToStart:'Distance to start',
        SaleHasEnded:'Sale has ended',
        SaleNotStarted:'Sale has not started',

        PaymentAlerts:'Payment Alerts',
        orderCosts:'order costs',
        confirmPay:'Are you sure you want to pay?',
        cancelTxt:'Cancel',
        confirmTxt:'Confirm',

        NotYetOpen:'Not yet open',




}