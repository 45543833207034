import React from 'react';
import './partners.scss';

import mapLeft from '../../../assets/map-left.webp';
import mapRight from '../../../assets/map-right.webp';
import partner1 from '../../../assets/coinbase.webp';
import partner2 from '../../../assets/bitmain.webp';
import partner3 from '../../../assets/antpool.webp';

export default function Partners({language}){
    return (
        <div className='partner'>
            <h2 className='partner-title'>{language.partnersTxt}</h2>
            <div className='partner-list h-w1300'>
                <ul className='partner-list-box'>
                    <li className='partner-item'>
                        <img className='pimg' src={partner1} alt='' />
                    </li>
                    <li className='partner-item'>
                        <img className='pimg' src={partner2} alt='' />
                    </li>
                    <li className='partner-item'>
                        <img className='pimg' src={partner3} alt='' />
                    </li>
                </ul>
            </div>
            <img className='m-left' src={mapLeft} alt='' />
            <img className='m-right' src={mapRight} alt='' />
        </div>
    )
}