import React,{useState} from 'react';
import './addaddress.scss';
import {Button, Modal,Input,message } from 'antd';

export default function AddAddressModal({isShow,SetHandleCancel,coinName,setAddrData,language}){
    const [newAddr,setNewAddr] = useState('');
    const [note,setNote] = useState('');

    const handleCancel = (state) => {
        SetHandleCancel(state)
    };

    const getAddr=(e)=>{
        setNewAddr(e.target.value);
    }

    const getNote=(e)=>{
        setNote(e.target.value);
    }

    const submitAddress=()=>{
        if(!newAddr){
            message.info(language.enterWalletAddressTips);
            return false;
        }
        if(!note){
            message.info(language.enterAddressNoteTips);
            return false;
        }
        setAddrData(newAddr.trim(),note.trim());
    }

    return (
        <>
            <Modal open={isShow} onCancel={()=>handleCancel(false)} centered footer={null} width={460}>
                <div className="address-modal">
                    <div className="address-content">
                        <div className="address-title">{language.addNewAddress} {coinName} </div>
                        <div className='address-ipt'>
                            <Input onChange={getAddr} size='large' placeholder={language.enterWalletAddressTips} />
                        </div>
                        <div className='address-ipt'>
                            <Input onChange={getNote} size='large' placeholder={language.enterAddressNoteTips} />
                        </div>
                        <div className='submit-address'>
                            <Button onClick={()=>submitAddress()} className="ls-btn" shape='round' type="primary" size='large' block>
                                {language.OKtxt}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}