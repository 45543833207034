import React,{useState} from 'react';
import './register.scss';
import RegiserModal from './RegisterModal';


export default function Register({language,toSignupStatus,setToSignupStatus,setToLoginStatus}){
   
    const getModalState = (e)=>{
        setToSignupStatus(e);
    }

    return (
        <div className="register">
            <button className="sign-btn" onClick={()=>setToSignupStatus(true)}>{language.signUpBtn}</button>
            <RegiserModal isShow={toSignupStatus} SetHandleCancel={getModalState} setToSignupStatus={setToSignupStatus} setToLoginStatus={setToLoginStatus} language={language} />
        </div>
    )
}