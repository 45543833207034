import React, { useState,useContext,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './myorder.scss';
import {Table,Select} from 'antd';
import {RightOutlined} from '@ant-design/icons';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import OrderStatus from '../../utils/orderStatus';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function MyOrder(){
    const [orderId,setOrderId] = useState('');
    const [showCheck,SetCheckStatus] = useState(false);
    const [orderList,setOrderList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'minerName',
            value:'minerName',
            align:'center'
        },
        {
            title:language.dateTxt,
            dataIndex:'orderSerial',
            value:'orderSerial',
            align:'center'
        },
        {
            title:language.totalAmountTxt,
            dataIndex:'totalFee',
            value:'totalFee',
            align:'center',
            render:(_,{totalFee})=>(
                <span style={{color:'#c00'}}>${totalFee}</span>
            )
        },
        {
            title:language.orderStatus,
            dataIndex:'minerStatus',
            value:'minerStatus',
            align:'center',
            filters:[
                {
                    text:language.PendingTxt,
                    value:1
                },
                {
                    text:language.ShelveTxt,
                    value:4
                }
            ],
            filterSearch:true,
            filterMultiple:false,
            onFilter:(val,item)=>item.minerStatus===val,
            render:(_,{minerStatus})=>(
                <OrderStatus status={minerStatus} language={language} />
            )
        },
        {
            title:language.actionTxt,
            dataIndex:'action',
            value:'action',
            align:'center',
            render:(_,item)=>(
                <Link to={'/dashboard/orderDetail/'+item.id}>
                    {language.moreTxt} <RightOutlined style={{fontSize:'12px'}} />
                </Link>
            )
        }
    ]

    const handleCheckOrder = ()=>{
        SetCheckStatus(true);
    }

    const GetOrderId = (e)=>{
        console.log(e)
        setOrderId(e.target.value)
    }

    const ResetIdInput = ()=>{
        setOrderId('')
    }

    const selectCurrencyChange = (value) => {
        setCurrency(value);
    };

    const getMyOrders = async()=>{
        const resData = await lsRequest('/memberPower/getMemberMinerList','POST',{
            minerStatus:'',
            coinName:currency,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            setOrderList(resData.data.list);
        }
    }

    const getCoinInfo = async()=>{
        const resData = await lsRequest('/coinInfo/getDigCoinInfo','POST',{l:language.requestType},'');

        if(resData&&resData.data.list.length>0){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain;
                obj.len = item.withdrawLength;
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
        }
    }

    useEffect(()=>{
        getCoinInfo();
    },[])

    useEffect(()=>{
        if(currency){
            getMyOrders();
        }
    },[currency])

    return (
        <>
            <div className='common-page-header'>
                <div className='title' style={{display:'flex',alignItems:'center',}}>
                    <div>{language.orderList} </div>
                    <div style={{display:'flex',alignItems:'center',fontSize:'14px',color:'#666',marginLeft:'30px'}}>
                        {language.currencyTxt}：
                        <Select
                            style={{ width: '100px',}}
                            placeholder="Please select currency"
                            defaultValue={currency}
                            key={currency}
                            onChange={selectCurrencyChange}
                            options={currencyList}
                        />
                    </div>
                </div>
                {/* <div className='header-btn-group'>
                    {
                        !showCheck?<button className='first-btn' onClick={handleCheckOrder}>Check Order</button>:<>
                            <span style={{color:'#787878',fontSize:'14px'}}>Order ID：</span>
                                <Space.Compact>
                                    <Input type='number' value={orderId} onChange={GetOrderId} />
                                    <Button onClick={ResetIdInput}>Reset</Button>
                                </Space.Compact>
                                <button className='last-btn'>Check</button>
                        </>
                    }
                </div> */}
            </div>
            
            <Table 
                rowKey={'id'} 
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                bordered 
                size='small' 
                columns={tableHeader} 
                dataSource={orderList} 
            />
        </>
    )
}