import React from 'react';
import { useNavigate } from 'react-router-dom';
import './oreItem.scss';
import defaultImg from '../../assets/easy-product.webp';

export default function OreItem({info,language}){
    const navigate = useNavigate();
    return (
        <div className="ore-item">
           <div className="item-top">
                <h3 className="item-day">{info.periodDays} {language.daysTxt}</h3>
                <div className='item-pic'>
                    <img className='cover' src={info.pics?info.pics:defaultImg} alt='' />
                </div>
                <h5 className='item-name'>{info.oreName}</h5>
           </div>
            <div className="item-bottome">
                <div className='item-h-fee'>{language.UnitPrice}:<span className='num'>${info.oreCoinFee}</span> </div>
                <div className='item-btn' onClick={()=>navigate('/ore/'+info.id)}>
                    {info.remainNumber===0&&<p className='out'>{language.soldOut}</p>}
                    <div className='buy'>{info.remainNumber>0?language.buyBtn:language.checkDetailTxt}</div>
                </div>
            </div>
        </div>
    )
}