import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import './goodsitem.scss';

export default function GoodsItem({info,packageType,language}){
    const navigate = useNavigate();
    return (
        <div className="cloud-item">
           <div className="item-top">
                <h3 className="item-day">{info.periodDays} {language.daysTxt}</h3>
                <div className='item-pic'>
                    <img className='cover' src={info.pics} alt='' />
                </div>
                <h5 className='item-name'>{info.package_name}</h5>
                <p className='item-ratio'>
                    {language.staticOutputRatio}: {info.staticOutputRadio}%
                    <Tooltip placement="top" title={language.staticORfreeTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0'}} />
                    </Tooltip>
                </p>
           </div>
            <div className="item-bottoem">
                <div className='item-tag'>
                    <div className='box'>{language.fullRevenueTxt}</div>
                </div>
                {
                    packageType===2?<>
                        <div className='item-h-fee'>{language.hashrateFeeTxt}:<span className='num'>${info.perFee} <i className='uint'>/{info.calPowerUnit}/{language.daysTxt}</i></span> </div>
                        <p className='item-s-fee'>{language.serviceFeeTxt}: ${info.serviceFee}/{info.calPowerUnit}/{language.daysTxt} </p>
                    </>:<>
                        <div className='item-h-fee'>{language.rentalFeeTxt}:<span className='num'>${info.minerFee}<i className='uint'>/{language.unitTxt}</i></span> </div>
                        <p className='item-s-fee'>{language.electricityCosts}: ${info.serviceFee}/{language.unitTxt}/{language.daysTxt} </p>
                    </>
                }
                <div className='item-btn' onClick={()=>navigate('/full/'+info.package_id)}>
                    {info.remainGoodsNum===0&&<p className='out'>{language.soldOut}</p>}
                    <div className='buy'>{info.remainGoodsNum>0?language.buyBtn:language.checkDetailTxt}</div>
                </div>
            </div>
        </div>
    )
}