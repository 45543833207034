import {Statistic} from 'antd';
const { Countdown } = Statistic;

// const onFinish = () => {
//     console.log('finished!');
// };

const InitBuyBtn = ({sTime,eTime,toPay,language})=>{
    let curtime = new Date().getTime();
    let start = new Date(sTime).getTime();
    let end = new Date(eTime).getTime();

    if(curtime<start){
        return <>
            <div style={{margin:'15px'}}>
                <Countdown title={language.DistanceToStart} value={sTime} format="D 天 H 时 m 分 s 秒" />
            </div>
            <div className='buy-now-btn gray'>{language.SaleNotStarted}</div>
        </>
    }
    else if(curtime>start&&curtime<end){
        return <>
            <div style={{margin:'15px'}}>
                <Countdown title={language.DistanceToEnd} value={eTime} format="D 天 H 时 m 分 s 秒" />
            </div>
            <div className='buy-now-btn' onClick={()=>toPay()}>{language.buyBtn}</div>
        </>
    }
    else if(curtime>end){
        return <div className='buy-now-btn gray'>{language.SaleHasEnded}</div>
    }
}

export default InitBuyBtn;