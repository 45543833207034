import React,{useState} from 'react';
import './cloudtab.scss';
import ctab1 from '../../../assets/c-tab1.webp';
import ctab2 from '../../../assets/c-tab2.webp';
import ctab3 from '../../../assets/c-tab3.webp';

const InitLeftIcon = ({index})=>{
    switch (index) {
        case 0:
            return <img className='icon' src={ctab1} alt='' /> 
            break;
        case 1:
            return <img className='icon' src={ctab2} alt='' /> 
            break;
        case 2:
            return <img className='icon' src={ctab3} alt='' /> 
            break;
        default:
            break;
    }
}

export default function CloudTab({language}){
    const [tabIndex,setTabIndex] = useState(0);
    const [showAll,setShowAll] = useState(true);

    const changeTab = (index)=>{
        setTabIndex(index);
    }

    const changeShowAll = ()=>{
        setShowAll(!showAll);
    }

    return (
        <div className='tab-header'>
            <div className="h-w1300 tab-h-content">
                <div className={`show-all ${showAll?'allinfo':''}`} >
                    <div className='show-all-box' onClick={()=>changeShowAll()}>
                        <div className='icon'>
                            <span className='bitfont bitshuangjiantou-shang'></span>
                        </div>
                        <div>{language.compareTxt}</div>
                    </div>
                    <div className='show-info-lab'>
                        <p className='info-lab'>{language.productPositioning}</p>
                        <p className='info-lab'>{language.minimumInvestment}</p>
                        <p className='info-lab'>{language.staticOutputRatio}</p>
                        <p className='info-lab'>{language.outputReturns}</p>
                        <p className='info-lab'>{language.serviceFeePayment}</p>
                    </div>
                </div>
                {
                    language.cloudTabListTxt.map((item,index)=>{
                        return <div key={index} className={`tab-item  ${tabIndex===index?'active':''} ${showAll?'hideInfo':''}`} onClick={()=>changeTab(index)}>
                            <div className='tab-item-header'>
                                <div className='i-header'>
                                    <InitLeftIcon index={index} />
                                    {item.title}
                                </div>
                                <p className='i-h-desc'>{item.desc}</p>
                            </div>
                            <div className='tab-item-info'>
                                <p className='info-item'>{item.txt1}</p>
                                <p className='info-item'>{item.txt2}</p>
                                <p className='info-item'>{item.txt3}</p>
                                <p className='info-item'>{item.txt4}</p>
                                <p className='info-item'>{item.txt5}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}