import React,{useContext,useEffect,useState} from 'react';
import { Link,useParams } from 'react-router-dom';
import './addressmanage.scss';
import {Breadcrumb, Table,Select, Button, message,Popconfirm } from 'antd';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import AddAddressModal from '../../components/AddAddressModal/AddAddressModal';
import UpdateAddressModal from '../../components/UpdateAddressModal/UpdateAddressModal';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function AddressManage(){
    const [addressList,setAddressList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');
    const [chainList,setChainList] = useState([]);
    const [chainName,setChainName] = useState('');
    const [addrModalStatus,setAddrModalStatus] = useState(false);
    const [updateModalStatus,setUpdateModalStatus] = useState(false);
    const [isMultipleChain,setIsMultipleChain] = useState(0);
    const [updateInfo,setUpdateInfo] = useState({});

    const {token} = useContext(AuthContext);
    const params = useParams();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.addressTxt,
            dataIndex:'address',
            value:'address',
            align:'center'
        },
        {
            title:language.noteTxt,
            dataIndex:'note',
            value:'note',
            align:'center'
        },
        {
            title:language.actionTxt,
            dataIndex:'action',
            value:'action',
            align:'center',
            render:(_,item)=>(
                <>
                    <Button type='link' onClick={()=>getUpdateStatus(true,item)}>{language.modifyRemarks}</Button>
                    <span style={{color:'#dcdcdc'}}>|</span>
                    <Popconfirm
                        title={language.deleteAddress}
                        description={language.deleteAddressOk}
                        onConfirm={()=>deleteAddr(item.id)}
                        onCancel={cancelDelete}
                        okText={language.yesTxt}
                        cancelText={language.noTxt}
                    >
                        <Button type='link'>{language.deleteTxt}</Button>
                    </Popconfirm>
                    
                </>
            )
        }
    ]

    const selectCurrencyChange = (value) => {
        setCurrency(value);
        setChainName('');
        let isChainArr= currencyList.filter(item=>item.value==value);
        setIsMultipleChain(isChainArr[0].isMultipleChain);
    };

    const selectChainChange = (value) => {
        setChainName(value);
    };

    const getOpenModal=(status)=>{
        if(isMultipleChain&&!chainName){
            message.info(language.selectChainTips);
            return false;
        }
        setAddrModalStatus(status);
    }

    const getUpdateStatus = (status,info)=>{
        setUpdateInfo(info);
        setUpdateModalStatus(status);
    }

    const deleteAddr = async(id)=>{
        const resData = await lsRequest('/memberCoinAddress/deleteCoinAddress','POST',{
            id,
            l:language.requestType
        },token);

        if(resData){
            message.success(language.deletedSuccessfully);
            getCoinAddress(currency);
        }
    }

    const cancelDelete = (e) => {
        
      };

    const updateAddr = async(id,note)=>{
        const resData = await lsRequest('/memberCoinAddress/updateCoinAddress','POST',{
            id,
            addressName:note,
            l:language.requestType
        },token);

        if(resData){
            setUpdateModalStatus(false);
            getCoinAddress(currency);
        }
    }

    const getCurrencyInfo = async()=>{
        const resData = await lsRequest('/memberAccount/getMemberAccountList','POST',{l:language.requestType},token);

        if(resData){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
            setIsMultipleChain(newArr[0].isMultipleChain);
        }
    }
    
    const getCoinChain = async()=>{
        const resData = await lsRequest('/coinInfo/getCoinChain','POST',{
            coinName:currency,
            l:language.requestType
        },token);

        if(resData){
            console.log(resData);
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.chainName;
                obj.lable = item.chainName;
                return obj;
            })
            setChainList(newArr);
        }
    }

    const getCoinAddress = async(coinName)=>{
        const resData = await lsRequest('/memberCoinAddress/myCoinAddress','POST',{
            coinName,
            chainName,
            l:language.requestType,
            addressType:1//1内转
        },token);

        if(resData){
            let arr = resData.data.list.map((item)=>{
                item.address = item.addressValue;
                item.note = item.addressName;
                return item;
            })
            setAddressList(arr);
        }
    }

    const getAddrData=async(addr,note)=>{
        const resData = await lsRequest('/memberCoinAddress/addCoinAddress','POST',{
            coinName:currency,
            chainName,
            addressType:1,//1内转
            addressName:note,
            addressValue:addr,
            l:language.requestType
        },token);

        if(resData){
            setAddrModalStatus(false);
            getCoinAddress(currency);
        }
    }

    useEffect(()=>{
        getCurrencyInfo();
    },[])

    useEffect(()=>{
        if(isMultipleChain){
            getCoinChain();
        }

        if(currency){
            getCoinAddress(currency);
        }
        else{
            getCoinAddress(params.coinName);
        }
        
    },[isMultipleChain,currency,chainName])

    return (
        <div style={{padding:'15px 50px'}}>
            <Breadcrumb
                items={[
                    {title:language.dashboardTitle, },
                    {title: <Link to={'/dashboard/wallet'}>{language.accountSetting}</Link>,},
                    {title:language.addressManagement,}
                ]}
            />
            <div className='manage-select-box'>
                <div style={{display:'flex',alignItems:'center'}}>
                    {language.currencyTxt}：
                    <Select
                        style={{ width: '100px',}}
                        placeholder={language.selectCurrency}
                        defaultValue={params.coinName}
                        onChange={selectCurrencyChange}
                        options={currencyList}
                    />
                    {
                        isMultipleChain>0&&<div style={{marginLeft:'20px'}}>
                            Chain：
                            <Select
                                style={{ width: '100px'}}
                                placeholder={language.selectShainTips}
                                onChange={selectChainChange}
                                options={chainList}
                            />
                        </div>
                    }
                </div>
                <Button shape='round' onClick={()=>getOpenModal(true)}>{language.addAddressTxt}{currency} </Button>
            </div>
            <Table
                style={{marginTop:'30px'}} 
                rowKey={'id'} 
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                bordered 
                size='small' 
                columns={tableHeader} 
                dataSource={addressList} 
            />

            <AddAddressModal coinName={currency} isShow={addrModalStatus} setAddrData={getAddrData} SetHandleCancel={getOpenModal} language={language} />
            <UpdateAddressModal isShow={updateModalStatus}  setUpdateData={updateAddr} SetHandleCancel={getUpdateStatus} info={updateInfo} language={language} />
        </div>
    )
}