import React, { useEffect, useState,useContext } from 'react';
import './myfortune.scss';
import { Tooltip,Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import initCoinImg from '../../utils/initCoinImg';
import initCoinTxt from '../../utils/initCoinTxt';
import initToCoinNum from '../../utils/initToCoinNum';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Myfortune(){
    const [memberInfo,setMemberInfo] = useState({});
    const [totalPower,setTotalPower] = useState([
        {totalAmount2U:0,totalAmount:0 }, 
        {remain_amount:0, totalCalPower:0,totalDigAmount:0},
        {remain_amount:0, totalGoodsNumber:0,totalDigAmount:0}
    ]);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');
    const [currencyLen,setCurrencyLen] = useState(2);

    const {token} = useContext(AuthContext);
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const selectCurrencyChange = (value) => {
        setCurrency(value);
    };

    const getMemberInfo = async()=>{
        const resData = await lsRequest('/memberInfo','POST',{l:language.requestType},token);
        if(resData){
            setMemberInfo(resData.data);
        }
    }

    const getTotalCalPower = async()=>{
        const resData = await lsRequest('/memberPower/getMyTotalCalPower','POST',{
            coinName:currency,
            l:language.requestType
        },token);
        if(resData){
            setTotalPower(resData.data);
        }
    }

    const getCoinInfo = async()=>{
        const resData = await lsRequest('/coinInfo/getDigCoinInfo','POST',{l:language.requestType},'');

        if(resData&&resData.data.list.length>0){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain;
                obj.len = item.withdrawLength;
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
            setCurrencyLen(newArr[0].len);
        }
    }
    

    useEffect(()=>{
        getMemberInfo();
        getCoinInfo();
    },[])

    useEffect(()=>{
        if(currency){
            getTotalCalPower();
        }
    },[currency])

    return (
        <div className='my-fortune'>
            <p className='fortune-note'>{language.pageNote}</p>
            <div className='fortune-kyc-verify'>
                <span className='icon'>
                    <i className='bitfont bitrenzheng'></i>
                </span>
                <div className='mid-part'>
                    <h3 className='title'>{language.kycVerification}</h3>
                    <p className='desc'>{language.kycVerDesc}</p>
                </div>
                {
                    memberInfo.authStatus===1?<span className='is-verify'>{language.verifiedTxt}</span>:<button className='fortune-btn' onClick={()=>navigate('/dashboard/kycbase')}>{language.notVerifiedTxt}</button>
                }
            </div>
            {/* <div className='fortune-kyc-verify'>
                <span className='icon'>
                    <i className='bitfont bittable-authorization-f'></i>
                </span>
                <div className='mid-part'>
                    <h3 className='title'>Fund Password</h3>
                    <p className='desc'>Used to verify your transactions and ensure the safety of your funds.</p>
                </div>
                <button className='fortune-btn'>Set</button>
            </div> */}


            {/* Total Assets Converted */}
            
            <div className='module-title'>
                <div>
                    {language.totalAssetsConverted}
                    <Tooltip placement="top" title={language.totalAssetsConvertedTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'8px',cursor:'pointer'}} />
                    </Tooltip>
                </div>
                <div style={{display:'flex',alignItems:'center',fontSize:'14px',color:'#666'}}>
                    {language.currencyTxt}：
                    <Select
                        style={{ width: '100px',}}
                        placeholder="Please select currency"
                        defaultValue={currency}
                        key={currency}
                        onChange={selectCurrencyChange}
                        options={currencyList}
                    />
                </div>
            </div>
            <div className='total-assets'>
                <h3 className='amount'>$ {totalPower[0].totalAmount2U}</h3>
                <p className='btc-amount'>{language.approximatelyTxt} {totalPower[0].totalAmount} {currency}</p>
            </div>

            {/* My Hashrate Converted */}
            <div className='module-title'>
                <div>
                    {language.myHashrateConverted}
                    <Tooltip placement="top" title={language.myHashrateConvertedTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'8px',cursor:'pointer'}} />
                    </Tooltip>
                </div>
                <div className='fortune-btn-group'>
                    <button className='detail-btn h-btn' onClick={()=>navigate('/dashboard/hashrate')}>{language.detailsBtnTxt}</button>
                    <button className='buy-btn h-btn' onClick={()=>navigate('/cloud')}>{language.buyTxt}</button>
                </div>
            </div>
            <div className='hashrate-hosting'>
                <h3 className='amount'>$ {totalPower[1].remain_amount}</h3>
                <p className='btc-amount'>{language.approximatelyTxt} {initToCoinNum(totalPower[1].remain_amount,totalPower[3],currencyLen)} BTC</p>
            </div>
            <div className='owner-box'>
                <div className='owner-left'>
                    {initCoinImg(currency)}
                    <span className='coinname'>{initCoinTxt(currency)}</span>
                </div>
                <div className='owner-right'>
                    <p className='name'>{language.hashrateOwned}</p>
                    <h5 className='num'>{totalPower[1].totalCalPower} TH/s</h5>
                    <p className='output'>{language.latestOutput} {totalPower[1].totalDigAmount} {currency}</p>
                </div>
            </div>

            
            {/* My Hosting Converted */}
            <div className='module-title' style={{paddingTop:'30px',borderTop:'1px solid #dcdcdc'}}>
                <div>
                     {language.myMinerConverted}
                    <Tooltip placement="top" title={language.myMinerConvertedTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'8px',cursor:'pointer'}} />
                    </Tooltip>
                </div>
                <div className='fortune-btn-group'>
                    <button className='detail-btn h-btn' onClick={()=>navigate('/dashboard/hosting')}>{language.detailsBtnTxt}</button>
                </div>
            </div>
            <div className='hashrate-hosting'>
                <h3 className='amount'>$ {totalPower[2].remain_amount}</h3>
                <p className='btc-amount'>{language.approximatelyTxt} {initToCoinNum(totalPower[2].remain_amount,totalPower[3],currencyLen)} {currency}</p>
            </div>
            <div className='owner-box'>
                <div className='owner-left'>
                    {initCoinImg(currency)}
                    <span className='coinname'>{initCoinTxt(currency)}</span>
                </div>
                <div className='owner-right'>
                    <p className='name'>{language.minersOwned} {totalPower[2].totalGoodsNumber} {language.unitTxt}</p>
                    <p className='output'>{language.latestOutput} {totalPower[2].totalDigAmount} {currency}</p>
                </div>
            </div>
        </div>
    )
}