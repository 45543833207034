import React, { useEffect, useState,useContext } from 'react';
import './mywallet.scss';
import { Tabs,Carousel,Table } from 'antd';
import Wallet from './Wallet/Wallet';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';

const curRef = React.createRef();

export default function MyWallet(){
    const [tabItems,setTabItems] = useState([]);
    const [tabIndex,setTabIndex] = useState(0);
    const [statementList,setStatementList] = useState([]);
    const [coinName,setCoinName] = useState('');
    const [walletInfo,setWalletInfo] = useState({});

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tabHeader = [
        {
          title:language.nameTxt,
          dataIndex: 'depict',
          key: 'depict'
        },
        {
            title:language.amountTxt,
            dataIndex: 'coinAmount',
            key: 'coinAmount',
            render:(_,item)=>(
                <span style={{color:`${item.direction==='-'?'#c00':'green'}`}}>{item.direction} {item.coinAmount}</span>
            )
        },
        {
          title:language.timeTxt,
          dataIndex: 'releaseDate',
          key: 'releaseDate',
        }
    ];

    const tabChange = (index)=>{
        curRef.current.goTo(index);
        setTabIndex(index);
        setCoinName(tabItems[index].coinName);
        getWalletInfo(tabItems[index].coinName);
    }

    const getCoinInfo = async()=>{
        const resData = await lsRequest('/memberAccount/getMemberAccountList','POST',{l:language.requestType},token);

        if(resData&&resData.data.list.length>0){
            let newArr = resData.data.list.map((item,index)=>{
                item.key = String(index);
                item.label = item.showName;
                return item;
            })
            setCoinName(newArr[tabIndex].showName);
            setTabItems(newArr);
            setWalletInfo(newArr[tabIndex]);
        }
    }

    const getWalletInfo=(cname)=>{
        let arr = tabItems.filter((item)=>item.coinName === cname);
        setWalletInfo(arr[0]);
    }

    const getStatementList = async()=>{
        if(coinName){
            const resData = await lsRequest('/transaction/getTransactionDetail','POST',{
                coinName,
                sceneType:'',
                l:language.requestType
            },token);
    
            if(resData.data.list){
                let newArr = resData.data.list.map((item,index)=>{
                    item.uid = index;
                    return item;
                })
                setStatementList(newArr);
            }
            else{
                setStatementList([]);
            }
        }
        
    }

    useEffect(()=>{
        getCoinInfo();
    },[])

    useEffect(()=>{
        getStatementList();
    },[coinName])

    return (
        <div className='body-content'>
            <Tabs defaultActiveKey={0} size='large' items={tabItems} onChange={tabChange} />
            <Carousel dots={false} ref={curRef} waitForAnimate={true}>
                <div>
                    <Wallet winfo = {walletInfo} language={language} />
                    <p style={{padding:'50px 0 20px'}}>{language.statementTxt}</p>
                    <Table rowKey={'uid'} style={{width:'700px'}} columns={tabHeader} dataSource={statementList} size="small" />
                </div>
                <div>
                    <Wallet winfo = {walletInfo} language={language} />
                    <p style={{padding:'50px 0 20px'}}>{language.statementTxt}</p>
                    <Table rowKey={'uid'} style={{width:'700px'}} columns={tabHeader} dataSource={statementList} size="small" />
                </div>
                <div>
                    <Wallet winfo = {walletInfo} language={language} />
                    <p style={{padding:'50px 0 20px'}}>{language.statementTxt}</p>
                    <Table rowKey={'uid'} pagination={{position:['bottomCenter']}} style={{width:'700px'}} columns={tabHeader} dataSource={statementList} size="small" />
                </div>
            </Carousel>
        </div>
    )
}