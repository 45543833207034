import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import './dailyearning.scss';
import {Breadcrumb,Table,Select,DatePicker} from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';

const { RangePicker } = DatePicker;

export default function DailyEarning(){
    const [dailyList,setDailyList] = useState([]);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.DateTxt,
            dataIndex:'date',
            value:'date',
            align:'center'
        },
        {
            title:language.dailyEarningsTxt,
            dataIndex:'earnings',
            value:'earnings',
            align:'center'
        }
    ]

    const selectCurrencyChange = (value) => {
        setCurrency(value);
    };

    const getCoinInfo = async()=>{
        const resData = await lsRequest('/coinInfo/getDigCoinInfo','POST',{l:language.requestType},'');

        if(resData){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain;
                obj.len = item.withdrawLength;
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
        }
    }

    const getDailyList = async()=>{
        const resData = await lsRequest('/coinDigInfo/getDigRecord','POST',{
            coinName:currency,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            setDailyList(resData.data.list);
        }
    }

    const changeDatePicker=(date,dateString)=>{
        console.log(dateString)
    }

    useEffect(()=>{
        getCoinInfo();
    },[])
    useEffect(()=>{
        if(currency){
            getDailyList();
        }
    },[currency])

    return (
        <div style={{padding:'15px 0'}}>
            <Breadcrumb
                items={[
                    {title: language.dashboardTitle},
                    {title: <Link to={'/dashboard/hashrate'}>{language.myHashrateTxt}</Link>},
                    {title: language.dailyEarningsTxt}
                ]}
            />
            <div className='daily-earning-title'>
                <div className='d-e-title'>{language.dailyEarningsTxt}</div>
                <div className='select-currency'>
                    {language.currencyTxt}：
                    <Select
                        style={{ width: '100px',}}
                        placeholder="Please select currency"
                        defaultValue={currency}
                        key={currency}
                        onChange={selectCurrencyChange}
                        options={currencyList}
                    />
                </div>
                {/* <RangePicker onChange={changeDatePicker} /> */}
            </div>
            <Table 
                style={{marginTop:'20px'}}
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                rowKey={'id'}
                columns={tableHeader} 
                dataSource={dailyList} 
                size="small" 
            />
        </div>
    )
}