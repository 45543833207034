import React, { useEffect, useState,useContext } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import './hashrate.scss';
import {Table,Button } from 'antd';
import {CloseOutlined,RightOutlined} from '@ant-design/icons';
import Converted from '../../components/Converted/Converted';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import OrderStatus from '../../utils/orderStatus';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Hashrate(){
    const [tipsStatus,setTipsStatus] = useState(false);
    const [hashrateList,setHashrateList] = useState([]);
    const [currencyList,setCurrencyList] = useState([]);
    const [currency,setCurrency] = useState('');
    const [currencyLen,setCurrencyLen] = useState(2);
    const [totalPower,setTotalPower] = useState([{},{remain_amount:0,totalCalPower:0,totalDigAmount:0},{}]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const {token} = useContext(AuthContext);
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'minerName',
            value:'minerName',
            align:'center'
        },
        {
            title:language.startTime,
            dataIndex:'digStartDate',
            value:'digStartDate',
            align:'center'
        },
        {
            title:language.runStatus,
            dataIndex:'minerStatus',
            value:'minerStatus',
            align:'center',
            render:(_,{minerStatus})=>(
                <OrderStatus language={language} status={minerStatus} />
            )
        },
        {
            title:language.RemainingServiceFee,
            dataIndex:'remainElectricFee',
            value:'remainElectricFee',
            align:'center',
            render:(_,{remainElectricFee})=>(
                <span style={{color:'#c00'}}>${remainElectricFee}</span>
            )
        },
        {
            title:language.LatestOutput,
            dataIndex:'todayCoinAmount',
            value:'todayCoinAmount',
            align:'center'
        },
        {
            title:language.CumulativeOutput,
            dataIndex:'totalCoinAmount',
            value:'totalCoinAmount',
            align:'center'
        },
        {
            title:language.actionTxt,
            dataIndex:'action',
            value:'action',
            align:'center',
            render:(_,item)=>(
                <>
                    <p>
                        <Link to={'/dashboard/orderearning/'+item.id+'/'+item.coinName+'/'+item.orderType}>
                            <Button size='small'>{language.checkProfit}</Button>
                        </Link>
                        
                    </p>
                    <p style={{marginTop:'10px'}}>
                        <Link to={'/dashboard/orderProfit/'+item.id+'/'+item.orderType}>
                            <Button size='small'>{language.checkDetail}</Button>
                        </Link>
                    </p>
                </>
            )
        }
    ]

    const selectCurrencyChange = (value) => {
        setCurrency(value);
    };

    const handleSetStatus = ()=>{
        localStorage.setItem('tipsStatus',true);
        setTipsStatus(true)
    }

    const getCoinInfo = async()=>{
        const resData = await lsRequest('/coinInfo/getDigCoinInfo','POST',{l:language.requestType},'');

        if(resData&&resData.data.list.length>0){
            let newArr = resData.data.list.map((item)=>{
                let obj = {};
                obj.value = item.coinName;
                obj.lable = item.coinName;
                obj.isMultipleChain = item.isMultipleChain;
                obj.len = item.withdrawLength;
                return obj;
            })
            setCurrencyList(newArr);
            setCurrency(newArr[0].value);
            setCurrencyLen(newArr[0].len);
        }
    }

    const getTotalCalPower = async()=>{
        const resData = await lsRequest('/memberPower/getMyTotalCalPower','POST',{
            coinName:currency,
            l:language.requestType
        },token);
        if(resData){
            setTotalPower(resData.data);
        }
    }

    const getMyOrders = async()=>{
        const resData = await lsRequest('/memberPower/getMemberMinerList','POST',{
            orderType:2,
            minerStatus:'',
            coinName:currency,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            setHashrateList(resData.data.list);
        }
    }


    useEffect(()=>{
        setTipsStatus(localStorage.getItem('tipsStatus')||true);
        getCoinInfo();
    },[])

    useEffect(()=>{
        if(currency){
            getTotalCalPower();
            getMyOrders();
        }
    },[currency])

    
    return (
        <div className='hashrate-body' style={{padding:'5px 0'}}>
            <div>
                <Converted 
                    info={{
                        type:1,
                        titleTips:language.myHashrateConvertedTips
                    }} 
                    currency={currency} 
                    currencyList={currencyList}
                    selectCurrencyChange={selectCurrencyChange}
                    totalPower = {totalPower}
                    currencyLen={currencyLen}
                    language={language}
                />
                {/* Hashrate List */}
                <div className='hashrate-list-title'>
                    <p className='txt'>{language.hashrateList}</p>
                    <div className='list-right'>
                        {/* <div className='pay-rules'>
                            <i className='bitfont bitdingdan1'></i>
                            <span className='txt'>Payment Rules</span>
                        </div> */}
                        <div className='daily-btn' onClick={()=>{navigate('/dashboard/dailyearning/'+currency)}}> {language.dailyEarningsTxt} </div>
                    </div>
                </div>
                {/* tips */}
                {
                    !tipsStatus&&<div className='hashrate-list-tips'>
                        <div className='close-btn' onClick={()=>handleSetStatus()}>
                            <CloseOutlined style={{fontSize:'18px',color:'#a0a0a0'}} />
                        </div>
                        {
                            language.hashrateListTips.map((item,index)=>{
                                return <div key={index}>
                                    <h3 className='tips-title'>{item.title}</h3>
                                    <p className='tips-info'>{item.info}</p>
                                </div>
                            })
                        }
                    </div>
                }

                {/* Table */}
                <Table 
                    style={{marginTop:'20px'}}
                    pagination={{
                        defaultPageSize:pageSize,
                        defaultCurrent:currentPage,
                        position:['bottomCenter']
                    }} 
                    columns={tableHeader} 
                    dataSource={hashrateList} 
                    size="small" 
                />

            </div>
        </div>
    )
}