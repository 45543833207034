import React from 'react';
import './freetrial.scss';

import frimgEn from '../../assets/free-trial_en.png';
import frimgHk from '../../assets/free-trial_hk.png';

export default function FreeTrial({language}){
    return (
        <div className="banner-wrap-img h-w1300">
            <img className='img' src={language.languageType==='en'?frimgEn:frimgHk} alt='' />
        </div>
    )
}