import React, { useEffect, useState,useContext } from 'react';
import './about.scss';
import { LanguageContext } from '../../contexts/LanguageContext';


export default function About(){
    const {langType,en,hk} = useContext(LanguageContext);
	// const language = langType==='en'?en:hk;

    return (
        <div className='body-content about-box-body' style={{backgroundColor:'#edeef4'}}>
            {
                langType==='hk'?<div className='h-w1300'>
                    <h3 className='title'>關于 HASH BOX</h3>
                    <p className='about-desc'>
                        Hashbox 算力服务平台（[hashbox.tech](https://hashbox.tech/)）全球範圍部署多個礦場，尤其在亞洲投資興建了多個大型礦場及IDC機房，主要的算力分佈在阿曼，充分利用當地豐富的電力資源。
                    </p>
                    <p className='about-desc'>
                        Hashbox 的使命是將挖礦不僅僅局限於加密貨幣愛好者，也面向普通用戶。透過礦機算力租賃的方式，Hashbox 顯著降低了參與加密貨幣挖礦的投入成本。用戶無需購買昂貴的設備，也不必花費時間進行設置和維護，使每個人都能輕鬆參與到加密貨幣挖礦中來。
                    </p>
                    <p className='about-desc'>
                        自2021年起，Hashbox 已成功獲得新加坡及香港的共同基金的投資。公司專注於行業的上下游佈局，積極投資並參與大型變電站、發電廠和水電站等項目。
                    </p>
                    <p className='about-desc'>
                        Hashbox 致力於為用戶提供可靠、高效的算力服務，透過持續的技術創新和戰略投資，推動數字貨幣挖礦領域的發展。我們堅信，通過開放、透明的合作，Hashbox 將成為全球領先的算力服務提供商之一
                    </p>
                </div>:<div className='h-w1300'>
                    <h3 className='title'>About HASH BOX</h3>
                    <p className='about-desc'>
                        Hashbox Mining Power Service Platform (hashbox.tech) strategically establishes multiple mining facilities globally, with a particular focus on constructing large-scale mining farms and IDC data centers in Asia, primarily in Oman, taking advantage of the region's abundant power resources.
                    </p>
                    <p className='about-desc'>
                        Hashbox's mission is to broaden the scope of mining beyond cryptocurrency enthusiasts to include the general public. Through the leasing of mining machine computing power, Hashbox significantly reduces the entry costs for cryptocurrency miners. Users no longer need to purchase expensive equipment or spend time on setup and maintenance, enabling everyone to participate effortlessly in cryptocurrency mining.
                    </p>
                    <p className='about-desc'>
                        Since 2021, Hashbox has secured investment funds from both the Singapore and Hong Kong Funds. The company is dedicated to the upstream and downstream development of the industry, actively investing and participating in large-scale substations, power plants, and hydroelectric projects.
                    </p>
                    <p className='about-desc'>
                        Hashbox is committed to providing users with reliable and efficient computing power services. Through continuous technological innovation and strategic investments, the company aims to drive the development of the digital currency mining sector. We firmly believe that through open and transparent collaboration, Hashbox will emerge as one of the leading global providers of computing power services.
                    </p>
                </div>
            }
        </div>
    )
}