import React, { useEffect, useState,useContext } from 'react';
import './invitee.scss';
import { useParams,useNavigate } from 'react-router-dom';
import {Statistic,Button} from 'antd'
import CountUp from 'react-countup';
import lsRequest from '../../utils/lsRequest';
import RegiserModal from '../../components/register/RegisterModal';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import InitSetpIcon from '../../utils/initStepIcon';

import bannerImg from '../../assets/invitee-banner-web-en.png';
import askImg1 from '../../assets/faq-0.png';
import askImg2 from '../../assets/faq-1.png';
import askImg3 from '../../assets/faq-2.png';
import tIcon from '../../assets/coop-icon.png';

const formatter = (value) => <CountUp end={value} separator="," />;

export default function Invitee(){
    const [platInfo,setplatInfo] = useState({});
    const [openReg,setOpenReg] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    localStorage.setItem('ivtCode',params.ivtCode);
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getInviteInfo = async()=>{
        const resData = await lsRequest('/inviteRecord/inviteDetail','POST',{
            inviteCode:params.ivtCode
        });
        
        if(resData){
            setplatInfo(resData.data);
        }
    }

    const toRegister = (status)=>{
        if(token){
            navigate('/')
        }
        else{
            setOpenReg(status);
        }
    }

    useEffect(()=>{
        getInviteInfo();
    },[])

    return (
        <div className='body-content invitee-body'>
            <div className='invitee-banner'>
                <div className='h-w1300 invitee-banner-content'>
                    <div className='invitee-banner-txt'>
                        <div className='invitee-banner-title'>
                            <img className='icon' src={tIcon} alt='' />
                            *** {language.strategicInvestment}
                        </div>
                        <div className='invitee-banner-username'>
                            <p className='info1'> {language.yourFriends} {platInfo.userName} </p>
                            <div className='info2'>{language.inviteReceiveTxt}</div>
                        </div>
                        <Button onClick={()=>toRegister(true)} type='primary' shape='round' className='invitee-free-btn'>{language.inviteeBtn} </Button>
                        <p className="invitee-num">
                            <Statistic className='num' value={platInfo.peopleNum} precision={0} formatter={formatter} />
                            {language.receivedRewardsPeople}
                        </p>
                    </div>
                    <div className='b-img-box'>
                        <img className='b-img' src={bannerImg} alt='' />
                    </div>
                </div>
            </div>

            <RegiserModal isShow={openReg} SetHandleCancel={toRegister} />

            <div className='invitee-bg-box h-w1300'>
                    <div className='invitee-step-title'>{language.stepsNiceGifts}</div>
                    <div className='invitee-steps'>
                        {
                            language.giftsSetpsList.map((item,index)=>{
                                return <div key={index} className='invitee-steps-item'>
                                    <InitSetpIcon index={index} />
                                    <div>
                                        <h4 className='step-title'>{item.title}</h4>
                                        <p className='step-desc'>{item.desc}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className='invitee-step-title'>{language.askedQuestions}</div>
                    <div className='invitee-ask'>
                        <div className='invitee-ask-item'>
                            <img className='invitee-ask-item-img' src={askImg1} alt='' />
                            <p className='invitee-ask-item-desc'>{language.questions1}</p>
                        </div>
                        <div className='invitee-ask-item'>
                            <img className='invitee-ask-item-img' src={askImg2} alt='' />
                            <p className='invitee-ask-item-desc'>{language.questions2}</p>
                        </div>
                        <div className='invitee-ask-item'>
                            <img className='invitee-ask-item-img' src={askImg3} alt='' />
                            <p className='invitee-ask-item-desc'>{language.questions3}</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}