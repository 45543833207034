const OreDigStatus = ({status,language})=>{
    switch (status) {
        case 4:
            return <span style={{color:'#4bc267'}}>{language.MiningTxt}</span>;
        case 10:
            return <span style={{color:'#4bc267'}}>{language.completedTxt}</span>;
        case 11:
            return <span style={{color:'#f36b64'}}>{language.DiscontinuedTxt}</span>;
        default:
            return <span style={{color:'#999'}}>{language.unknownStatus}</span>;
    }
}

export default OreDigStatus;