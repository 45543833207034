import baseName from "./baseName";

export const hk = {
    login:'登录',
    home:'首页',
    logOut:'退出',
    language:'繁体中文',
    languageType:'hk',
    requestType:'c',
    // navbar
    cloudMiningTitle:'雲算力',
    minerTitle:'礦機',
    couponTitle:'優惠券',
    newsTitle:'媒體報導',
    dashboardTitle:'我的賬戶',
    helloTitle:'您好!',
    myOrderTitle:'我的訂單',
    setTtitle:'設置',
    logoutTitle:'退齣',
    //home page
    bannerTips:'沒有banner數據',
    minerSalesTxt:'礦機銷售',
    RentalTypeMineSite:"出租型礦位",
    RentalTypeMineSiteDesc:"每個礦位的價格爲600美元，有效期爲該機器雲算力開始使用後的三年，在有效期內，用戶可以享受電費0.057美分/KW/H的折扣價格（原價爲0.075）。",
    minerHostingTxt:'礦機託管',
    SelfcontainedMineLevel:"自用型礦位",
    SelfcontainedMineLevelDesc:"提前預約自用礦位，可享專有電費折扣，有效期內僅需0.057 USD/KW/H（原價爲0.075USD/KW/H）。",
    moreTxt:'更多',
    cloudMiningHomeNotes:'放手式輕鬆雲端挖礦體驗，將設備的“採購、運輸、託管與維護”等繁雜環節留給我們。',
    minerHomeNotes:'礦機租賃相比傳統購買礦機挖礦，租賃後即可立即挖礦，回本週期短、成本低、風險小，且更加靈活。',
    newUserBtn:'新用戶購買',
    buyBtn:'立即搶購',
    daysTxt:'天',
    staticOutputRatio:'靜態產出率',
    staticORfreeTips:'靜態產出率 = 挖礦淨得 / 期初投入',
    staticORTips:'靜態產出率 = 挖礦淨得 / 期初投入',
    freeMiningTags:'新用戶購買',
    trialCashTxt:'體驗金',
    soldOut:"已售馨",
    staticBreakevenDays:"靜態回本天數",
    fullRevenueTxt:'全產出',
    hashrateFeeTxt:'算力費',
    serviceFeeTxt:'服務費',
    rentalFeeTxt:'礦機費',
    hostingFee:'電費',
    checkDetailTxt:'查看詳情',
    minerHostingDesc:`用戶在平台購買的礦機，或是自有礦機都可以託管到${baseName}礦場，礦場由專業人員部署、24小時維護、託管費（含電費）$ 0.075 - 0.085 / kw.h。您在獲得挖礦產出同時还可享受礦機增值。`,
    tabMinerSales:[
        {
            title:'品牌優勢',
            desc:'比特大陸戰略投資，雙方在礦機、礦機機房、礦機維護等多方面深度合作'
        },
        {
            title:'貨源保障',
            desc:'比特大陸旗艦機型供應，滿足用戶多元化需求'
        },
        {
            title:'專業售後',
            desc:'提供提貨發貨、礦機質保、售後維修等專業服務'
        }
    ],
    WORLDLEADER:'全球領先',
    WORLDLEADERSUB:'算力服務商',
    WORLDLEADERDesc:`${baseName}是一家快速發展的數字資產挖礦服務商，也是全球領先的雲挖礦服務商。由全球領先的加密貨幣挖礦硬件製造商比特大陸投資，並且是目前唯一的雲挖礦戰略合作夥伴。致力於打造安全、合規、透明的區塊鏈基礎設施，為全球客戶群提供各種穩定、智能的算力服務解決方案。`,
    WORLDLEADERList:[
        {
            title:'比特大陸官方合作',
            desc:'最新礦機聯合首發銷售和供應',
            info:'在礦機、礦機機房、礦機維護等方面深度合作'
        },
        {
            title:'挖礦資源',
            desc:'礦場託管 300MW+',
            info:'管理礦機 100,000 台+'
        },
        {
            title:'领先算力服務',
            desc:'管理算力 10EH/s+',
            info:'平臺銷售額 $100,000,000+'
        },
        {
            title:'全球化運算力平台',
            desc:'全球多國配置礦場 12+',
            info:'服務遍佈 100+ 國家'
        }
    ],
    appDownClickBtnTips:'暫未開通，敬請期待!',
    appDownSub:'APP領取200U新人禮包',
    appDownTitle:'開始您的挖礦之旅，一切盡在掌握！',
    appDownDesc:'隨時隨地 輕鬆點擊即可管理收益',
    needHelpTxt:'需要幫助',
    needHelpList:[
        {
            title:'24/7聊天支持',
            desc:'全天候的在線聊天支持，我們的客服代表將竭誠為您服務'
        },
        {
            title:'常見問題',
            desc:'根據關鍵詞查詢相關問題，獲取特定功能的詳細說明'
        },
        {
            title:'Twitter',
            desc:'關注我們的推特賬號，實時關注我們發佈的最新動態與評論'
        }
    ],
    partnersTxt:'合作夥伴',
    footerDesc:`全球領先的算力服務提供商`,
    productTxt:'商品',
    companyTxt:'公司',
    FAQNewsTxt:'問題與新聞',
    supportTxt:'支持',
    aboutTxt:'關于',
    helpCenterTxt:'幫助中心',
    privacyPolicy:'隱私條款',
    termsService:"服務條款",
    ContactEmail:'聯系郵箱',

    // cloud mining 
    cloudMiningPageDesc:'一鍵操作，费用全包，總體成本相對更低。适合囤币用户，开启無憂挖礦。',
    compareTxt:' 關閉對比',
    productPositioning:'産品定位',
    minimumInvestment:'最小投入',
    outputReturns:'産出收益',
    serviceFeePayment:'服務費繳納',
    cloudTabListTxt:[
        {
            title:'簡單算力',
            desc:'新人推薦、優惠套餐挖礦',
            txt1:'無需計算的入門產品',
            txt2:'$20',
            txt3:'101%~105%',
            txt4:'用戶自有 100%',
            txt5:'全額繳納'
        },
        {
            title:'全產出',
            desc:'更高收益、分期服務費',
            txt1:'收益更高的挖礦産品',
            txt2:'$80-$200',
            txt3:'101%-125%',
            txt4:'用戶自有 100%',
            txt5:'首期部分繳納，分期補繳'
        },
        {
            title:'穩定挖礦',
            desc:'VIP 專用、穩定産出',
            txt1:'超穩定的挖礦産出',
            txt2:'0.5 BTC',
            txt3:'101%-105%',
            txt4:'用戶自有 100%',
            txt5:'全額繳納'
        }
    ],

    // miner page 
    minerDesc:[
        {
            title:'訂購享優惠',
            desc:'用戶優惠專享，福利多多'
        },
        {
            title:'最強礦機，絕對領先',
            desc:'礦機能效絶對領先，單礦機算力高達200T'
        },
        {
            title:'鎖定首批礦機',
            desc:'下單鎖定一月首批礦機貨源。'
        },
        {
            title:'優質礦場，安心託管',
            desc:'鎖定礦場機位，享高效運維服務。'
        }
    ],


    // coupon page 
    rewardsTxt:'獎勵',
    rewardDesc:'描述',

    // dashboard page 
    myHashrateTxt:'我的算力',
    dashboardMenuList:[
        {
            labText:'我的財富',
            path:'/dashboard',
            iconName:'fortune',
            showRedDot:false
        },
        {
            labText:'我的算力',
            path:'/dashboard/hashrate',
            iconName:'',
            showRedDot:false
        },
        {
            labText:'我的礦機',
            path:'/dashboard/hosting',
            iconName:'',
            showRedDot:false
        },
        {
            labText:'我的礦位',
            path:'/dashboard/oreOrder',
            iconName:'',
            showRedDot:false
        },
        // {
        //     labText:'我的積分',
        //     path:'/dashboard/points',
        //     iconName:'',
        //     showRedDot:false
        // },
        // {
        //     labText:'獎勵金',
        //     path:'/dashboard/earns',
        //     iconName:'',
        //     showRedDot:false
        // },
        {
            labText:'我的錢包',
            path:'/dashboard/wallet',
            iconName:'',
            showRedDot:false
        },
        // {
        //     labText:'服務費',
        //     path:'/dashboard/servicefee',
        //     iconName:'service',
        //     showRedDot:false
        // },
        {
            labText:'我的訂單',
            path:'/dashboard/myorders',
            iconName:'order',
            showRedDot:false
        },
        {
            labText:'邀請',
            path:'/invite',
            iconName:'invite',
            showRedDot:false
        },
        {
            labText:'我的優惠券',
            path:'/dashboard/mycoupon',
            iconName:'coupon1',
            showRedDot:false
        },
        // {
        //     labText:'BITMAIN Coupon',
        //     path:'/dashboard/bitcoupon',
        //     iconName:'coupon2',
        //     showRedDot:false
        // },
        {
            labText:'設置',
            path:'',
            iconName:'setting',
            showRedDot:false
        },
        {
            labText:'賬號設置',
            path:'/dashboard/accountsetting',
            iconName:'',
            showRedDot:true
        },
        {
            labText:'KYC驗證',
            path:'/dashboard/kyc',
            iconName:'',
            showRedDot:true
        }
    ],
    freePackageDesc:['相比從市場買幣，獲幣成本更低','費用全包，輕鬆獲得BTC','立即購買、立即挖礦'],
    quantityPurchased:'買入數量',
    durationStaticOutput:'週期靜態產出',
    equivalentTxt:'折合',
    equivalentTips:'週期靜態產出 = 週期內挖礦所得幣量 * 參考幣價 參考幣價以 1 BTC ≈ ',
    staticOutputRadioTips:'靜態產出率 = 週期靜態產出 / 投入 以參考幣價 1 BTC ≈ ',
    staticCurrencyAcquisitionPrice:'靜態貨幣價格',
    exchangeRateTips:'靜態貨幣價格 = 收購金額/持續時間 BTC 的靜態輸出量',
    FAQsTxt:'常見問題',
    FAQsList:(panelStyle) => [
        {
          key: '1',
          label: '1. 雲挖礦與直接購買比特幣差異',
          children: <p>雲挖礦理論上可以比直接購買bitcoin獲得更多的比特幣，因為在投入金額比特幣價格一致的情況下，挖礦存在靜態產出率，這將比直接購買會存在冗餘。</p>,
          style: panelStyle,
        },
        {
          key: '2',
          label: '2. 簡單算力與專業算力，有什麼區別？',
          children: <p>简单算力的费用已包含周期内的全部的算力费及服务费。專業算力的全部的算力費，您可以選擇一次性繳納服務費，也可以先只繳納部分服務費，在服務費到期前再次繳納。若您選擇專業算力並分批繳納服務費，服務費繳納的時機、付款幣種及市場的波動，會影響您總的費用成本。</p>,
          style: panelStyle,
        },
        {
          key: '3',
          label: '3. 如何計算產品的產出？',
          children: <p>產出是基於靜態產出預估。靜態產出 = 購入算力數 * 期限 * 每T每天産出預估，購入算力數 = 買入金額 / 算力費及服務費 / 期限，每T每天産出預估是根據當前算力難度(67.31T)靜態推算。</p>,
          style: panelStyle,
        }
    ],
    disclaimerTxt:'免責聲明',
    disclaimerList:[
        {
            txt:'1.按照協議規定，一旦您購買完成，將不支持更換或退款。'
        },
        {
            txt:'2.不對以下不可控風險所造成的損失負責：不能預見、不能避免或不能克服的客觀事件，包括自然災害如洪水、火山爆發、地震、山崩、火災、被政府部門評定為百年不遇級別以上的風暴和惡劣氣候等，政府行為和政府指令，城市級別的電網供電事故，以及社會異常事件如戰爭、罷工、動亂等。'
        },
        {
            txt:`3.${baseName}提供的套餐對應的為真實雲算力，真實雲算力由於網絡、礦機功率等不穩定因素會導致波動，${baseName}不承諾100%穩定運行。`
        },
        {
            txt:`4.靜態產出是根據當前算力難度靜態推算，僅供參考，會因市場難度波動産生變化，難度提高靜態產出會減少，難度降低靜態產出會增多，${baseName}对BTC产出不作承诺。`
        }
    ],

    // easypay page 
    easypayTabHeader:[
        
        {
            title:'服務/産品',
            dataIndex:'name',
            value:'name',
            align:'center'
        },
        {
            title:'價格',
            dataIndex:'price',
            value:'price',
            align:'center'
        },
        {
            title:'數量',
            dataIndex:'amount',
            value:'amount',
            align:'center'
        },
        {
            title:'購買天數',
            dataIndex:'days',
            value:'days',
            align:'center'
        },
        {
            title:'總計',
            dataIndex:'total',
            value:'total',
            align:'center'
        }
    
    ],
    submitAgreeTips:'請同意挖礦服務協議',
    paymentSuccessful:'支付成功',
    orderInformationTitle:'訂購信息',
    attentionTxt:'請注意',
    attentionTips:`本産品爲新人套餐，每人只能購買壹份`,
    minerFeeTxt:'礦機費',
    orderTotal:'訂單總額',
    acceptTxt:'我同意',
    acceptAgreeTxt:'風險聲明 用戶服務協議說明',
    submitTxt:'提交',
    verifyModalDesc:'您的累計交易金額已超過您當前身份驗證級別所允許的最高交易金額。完成身份驗證程序以提高您的最高交易金額。',
    cancelBtnTxt:'取消',
    verifyBtnTxt:'認證',

    // full page 
    productIntroduction:'產品介紹',
    fullPackageDesc:['服務費後繳，可更多購入算力','可根據市場變化，分批繳納服務費，獲得超額收益','上市礦企、專業運維，算力在線率95%+'],
    priceHashrate:'算力費單價',
    selectQuantity:'選擇數量',
    priceTxt:'價格',
    serviceFeePrice:'服務費單價',
    initialPeriod:'首次繳納',
    initialPeriodTips:'未全部繳納服務費可以通過設置服務費代扣或是手動繳納服務費',
    totalPriceTxt:'總價格',
    productDetails:'產品詳情',
    packageType:'套餐類型',
    currencyTxt:'幣種',
    planDuration:'計劃週期',
    validTime:'生效時間',
    miningPool:'礦池',
    revenueAddress:'打款地址',
    immediatelyTxt:'購買後立即生效',
    personalWallet:'個人錢包地址',
    fullRevenueTips:'在全產出模式下，您將獲得周期內挖礦產出的全部部分',
    noDataTxt:'暫無數據',

    // fullpay page 
    orderInfoDataHeader:[
        {
            title:'服務/産品',
            dataIndex:'name',
            value:'name',
            align:'center'
        },
        {
            title:'價格',
            dataIndex:'price',
            value:'price',
            align:'center'
        },
        {
            title:'數量',
            dataIndex:'amount',
            value:'amount',
            align:'center'
        },
        {
            title:'購買天數',
            dataIndex:'days',
            value:'days',
            align:'center'
        },
        {
            title:'總計',
            dataIndex:'total',
            value:'total',
            align:'center'
        }
    ],
    fullpayTab:[
        {
          key: '0',
          label: '算力費優惠券'
        },
        {
          key: '1',
          label: '服務費優惠券',
        }
    ],
    fullpayeCloudNote:`注：未購買全周期服務費，可在”我的算力“開啟服務費代扣，或是手动缴纳服务费`,
    fullpayeMinerNote:`注：未購買全周期電費，可在”我的礦機“開啟電費代扣，或是手动缴纳電費`,
    couponTxt:'優惠券',
    minerFeeCoupon:'優惠券',
    noCouponsTxt:'暫無優惠券',

    // my fortune 
    pageNote:'完善個人信息，開啟挖礦之旅',
    kycVerification:'KYC驗證',
    kycVerDesc:'出於當地相關法規和政策要求，需要您進行實名身份認證',
    notVerifiedTxt:'去驗證 ',
    verifiedTxt:'已認證',
    totalAssetsConverted:'總資產折合',
    totalAssetsConvertedTips:'總資產折合=我的算力資產+我的託管資產',
    approximatelyTxt:'約',
    myHashrateConverted:'我的算力折合',
    myHashrateConvertedTips:'單筆算力資產 = (購買時算力費單價 * 剩余未運行天數 * 算力數) + (服務費單價 * 剩餘服務費天數 * 算力數)，每筆算力資產求和得出我的算力資產折合',
    detailsBtnTxt:'訂單詳情',
    hashrateOwned:'持有算力',
    latestOutput:'最新產出',
    buyTxt:'購買',
    myMinerConverted:'我的礦機折合',
    myMinerConvertedTips:'所有矿机购买时成本金额求和得出我的托管资产折合',
    minersOwned:'持有台數',
    unitTxt:'台',
    EarningsTips:'昨日8:00至今日8:00產出的變化量(東八區)',
    SwitchOnTxt:'開',
    SwitchOffTxt:'關',
    autoPayTxt:'自動繳納服務費',
    hashrateList:'算力列表',
    dailyEarningsTxt:'每日產出 ',
    hashrateListTips:[
        {
            title:'挖礦收益最低起付額',
            info:'如果日產出金額大於 0.005 BTC，則礦池將在當日進行打款；如果日產出金額小於 0.005 BTC，則待累積產出金額達到 0.005 BTC後，礦池予以打款。'
        },
        {
            title:'小額打款',
            info:'套餐結束後遺留在礦池的收益打款，訂單完成後，留在礦池中達到打款門檻值的BTC產出，礦池將在套餐完成後 48小時內發起小額打款，反之，產出將留在礦池。'
        }
    ],
    nameTxt:'名稱',
    dateTxt:'訂單日期',
    totalAmountTxt:'訂單總金額',
    orderStatus:'訂單狀態',
    actionTxt:'操作',

    // my hosting 
    miningList:'聯合託管清單',

    //my wallet
    amountTxt:'數量',
    timeTxt:'時間',
    statementTxt:'流水記錄',
    depositTxt:'充值',
    withdrawalTxt:'提現',

    // Deposit 
    copySuccess:'複制成功',
    myWalletTxt:'我的錢包',
    walletTxt:'錢包',

    // Withdrawal 
    withdralQuantityTips:'提幣數量必須大于gas費',
    selectWalletAddressTips:'請選擇錢包地址',
    balanceTips:'錢包余額不足',
    minimumNumberTips:'提幣數量不得少于最低數量',
    successfulApplication:'申請成功',
    withdrawalsNum:'提幣數量',
    withdrawMinimumAmount:'提幣最少數量',
    walletBalance:'錢包余額',
    gasFee:'gas費',
    actualReceipt:'實際到賬',
    confirmBtnTxt:'確定',
    numberTxt:'數量',

    //add address modal
    enterWalletAddressTips:'請輸入錢包地址',
    enterAddressNoteTips:'輸入地址備注',
    addNewAddress:'錢包地址-',
    OKtxt:'確認',

    // my order 
    orderList:'訂單列表',

    // my coupon 
    allTxt:'全部',
    availableTxt:'可使用',
    usedTxt:'已使用',
    expiredTxt:'已過期',
    bigCouponTxt:'優 惠 券',
    applicableOver:'適用範圍',
    rulesTxt:'規則',
    getTxt:'領取',

    // Account Setting 
    accountSetting:'賬號設置',
    addressTxt:'地址',
    withdrawalVerification:'提幣驗證',
    addressManagement:'地址管理',
    addressManagementTitle:'錢包地址管理',
    addressManagementDesc:'管理您常用的數字貨幣接收地址',
    manageBtnTxt:'管理',

    //address manage
    noteTxt:'備注',
    deleteAddress:'刪除地址',
    deleteAddressOk:'確定要刪除此地址嗎?',
    yesTxt:'是的',
    noTxt:'否',
    deleteTxt:'刪除',
    selectChainTips:'請選擇鏈',
    deletedSuccessfully:'刪除成功',
    chainTxt:'鏈',
    selectShainTips:'請選擇鏈名稱',
    addAddressTxt:'添加地址-',
    modifyRemarks:'修改備注',
    selectCurrency:'請選擇幣種',

    // kyc page 
    identityVerification:'身份認證',
    personalVerification:'個人認證',
    basicVerification:'基礎認證',
    basicVerificationDesc:'完成身份驗證後即可提現',
    verificationNotes:`${baseName}提供的服務不適用於以下（下稱“受限人士”）美國、中國大陸、克里米亞、古巴、伊朗、俄羅斯、朝鮮、敘利亞、新加坡、委內瑞拉的居民；根據適用的貿易製裁和出口合規法律，受到限製的任何實體或個人。上述名錄可能並窮盡，在使用${baseName}提供的服務前，請確認您不屬於受限人士。 `,

    //kyc base page
    uploadIDPhoto:'上傳身份證照片',
    IDCard:'身份證',
    IDCardDesc:'請提供身份證正反面的照片。',
    enterName:'請輸入您的姓名',
    enterIDnumber:'請輸入您的身份證號碼',
    frontTxt:'正面',
    backTxt:'反面',
    uploadDescList:[
        {
            txt:'必須提供本人證件照，',
            imgSize:'圖片大小 20KB~2M, 格式 JPG, PNG;'
        },
        {
            txt:'• 證件務必為拍攝照片，影本和掃描件不能通過；'
        },
        {
            txt:'• 證件中姓名必須有英文語言，如身份證（駕駛證）沒有英文姓名，請使用護照進行申請，否則不予通過；',
        },
        {
            txt:'• 請提供真實的證件，證件照務必清晰，不得有任何塗改；'
        },
        {
            txt:'• 請填寫在有效期內的證件'
        }
    ],
    continueTxt:'下一步',
    backPage:'返回',
    resultTitle:'申請已提交',
    resultContent:'請耐心等待，我們將在 24 小時內完成審核',

    //invite page
    inviteTabTxt:'邀請記錄',
    myFriendTxt:'我的好友',
    invitationTime:'邀請時間',
    rewardTxt:'總獎勵',
    noRecordTips:'暫無邀請記錄',
    invitationRanking:'邀請排行',
    rankingTxt:'排名',
    userNameTxt:'賬戶',
    noRecordTxt:'暫無記錄',
    invitedPeoples:'人已被邀請',
    inviteStepList:[
        {
            title: `好友30天内首次下單`,
            sub1:'獲得',
            sub2:'10點積分',
            sub3:'獎勵'
        },
        {
            title: `好友每單消費 ≥ $100`,
            sub1:'獲得算力費',
            sub2:'1.5%',
            sub3:'返傭'
        },
        {
            title: `5位好友下單長期套餐`,
            sub1:'獎勵',
            sub2:'$1,000',
            sub3:'優惠券'
        }
    ],
    sendInvitation:'發送邀請',
    referralCodeOptional:'邀請碼 (可選)',
    invitationLink:'邀請鏈接',
    inviteNow:'立即邀請',
    myRewards:'我的獎勵',
    accumulatedrewards:'累計獎勵',
    inviteesNumber:'邀請人數',
    referralCode:'邀請碼',
    posterTxt1:'現在注冊',
    posterTxt2:'享新人專屬折扣',
    eventRules:'活動規則',
    eventRulesList:[
        {
           title:'邀請人獎勵：',
           desc:[
            '1. 首單獎勵，每邀請一名好友，並在註冊後30日內完成首次下單金額≥$100，邀請人享受首單獎勵，级别越高，奖励越高，具体参照“我的獎勵等級”表',
            '2. 消費返傭，每名好友在註冊後下單金額≥$100的交易，邀請人享受消費返傭，返傭金額為訂單算力費的2%，级别越高，返佣周期越长（返佣周期按被邀请人注册日开始计算），單人最高返傭 10,000點積分，具体参照“我的獎勵等級”表',
            '3. "友長"獎勵，累積邀請5名好友及以上，並且好友成功下單360天及以上週期套餐，邀請人可獲得總優惠金額$1,000優惠券',
            '溫馨提示：邀請人需完成KYC身份認證才可提取獎勵。'
           ] 
        },
        {
            title:'受邀人獎勵：',
            desc:[
             '1. 新人註冊獎勵，帳號享有$1000體驗金，0投入體驗挖礦',
             '2. 新人簡單套餐，體驗獎勵金減免，簡單算力最低僅需$14即可挖礦',
             '3. 新人進階挖礦，專屬30天套裝10折優惠'
            ] 
         }
    ],

    //invitee page
    strategicInvestment:'戰略投資',
    yourFriends:'您的好友',
    inviteReceiveTxt:'邀請您領取$1,000體驗金',
    inviteeBtn:'免費領取',
    receivedRewardsPeople:'人已領取獎勵',
    stepsNiceGifts:'簡單3步即可領取3重豪禮',
    giftsSetpsList:[
        {
            title:'注冊賬號',
            desc:'領$1,000體驗金，免費挖礦'
        },
        {
            title:'完成KYC',
            desc:'免費挖礦獎勵翻倍'
        },
        {
            title:'體驗結束',
            desc:'用收益購買產品'
        }
    ],
    askedQuestions:'常見問題',
    questions1:'如何註冊',
    questions2:'如何KYC',
    questions3:'常見問題',

    // sign up and login in 
    connectWalletTips:'請鏈接您的錢包',
    agreeUserAgreementTips:'請選擇同意用戶注冊協議',
    signUpTips:'歡迎註冊',
    walletAddressTxt:'錢包地址',
    DISCONNECT:'斷開連接',
    connectWalletTxt:'鏈接錢包',
    haveReadAgree:'我已經閱讀並同意',
    userRegAgreement:'用戶注冊協議',
    SUBMITBtn:'提交',
    signInTxt:'去登錄',
    alreadyHasAccount:'已有帳戶',
    signUpBtn:'注冊',
    loginBtn:'登錄',
    successfulRegistTips:'注冊成功',
    loginSuccessfulTips:'登錄成功',
    loginTo:'登錄',
    NotMember:'还没有帳戶',
    signupNow:'立即註冊',
    userLoginAgreement:'用戶協議',

    //404 page
    pageNotFound:'頁面未找到',
    pageNotFoundDesc:'抱歉，您訪問的頁面不存在。',
    backHome:'返回首頁',

    //Order Detail
    OrderDetail:'訂單詳情',
    OrderNo:'訂單編號',
    MinerName:'礦機名稱',
    CurrencyType:'幣種類型',
    CreationTime:'創建時間',
    ShelfDate:'上架日期',
    Dateofexcavation:'開挖日期',
    QuantityTxt:'數量',
    TotalFee:'總費用',
    DataTxt:'數據',
    
    
    //replenishment
    DateTxt:'日期',
    logoutTips:'退出成功',
    addressChangeTips:'錢包地址切換成功，請重新登錄',
    loginTips:'您還未登錄',
    powerTxt:'算力',
    PendingTxt:'准備中',
    ConfirmedTxt:'已確認',
    PaidTxt:'已支付',
    ShelveTxt:'已上架',
    UnknownTxt:'未知狀態',
    totalPower:'總算力',
    SoldTxt:'已售馨',
    totalCosts:'总成本',
    runStatus:'運行狀態',
    profitTxt:'收益金額',
    minerProfit:'礦機收益',
    cloudProfit:'算力收益',
    myMiner:'我的礦機',
    checkDetail:'查看詳情',
    detailsTxt:'詳情',
    LatestOutput:'最新產出',
    RemainingServiceFee:'剩餘服務費',
    RemainingElectricityFee:'剩餘服電費',
    startTime:'開始時間',
    copyTxt:'份',
    buyDays:'購買天數',
    electricityCosts:'電費',
    minerPrice:'礦機價格',
    costRecovery:' 回本比例',


    //Rentalminers
    rentalMinersTxt:'礦位租賃',
    MinersProductTxt:'礦位産品',
    purchaseMiners:'礦機購買',
    buyTips:'不可購買此産品哦',
	oreList:'礦位訂單',
	RentalUnitPrice:'租賃單價',
	UnitPrice:'單價',
	profitabilityTxt:'利潤率',
	annualisedAbout:'年化約',
	paybackTxt:'回本前',
	afterPayback:'回本後',
	endTime:'結束時間',
	investmentPromotionBuffer:'招商緩沖期',
    investBufferTxt:'招商緩沖期爲運維團隊招商及上架機器的標准時間，  如果租賃期間發生機器轉運、宕機維修、停電等情況，平台依然保證礦主的收益不受影響，故預留3~5月作爲緩沖期。',
	MineralPositionProjectModel:'礦位項目模式',
    minerModelTxt:'挖礦是壹個高能耗的項目，因此礦場建設壹般是圍繞的低廉的能源來搭建，礦場是壹個系統項目工程，整個工程半年建設完成後再拆分以單個礦位計算的産品進行招商。以美國爲例，建設壹個10萬KW的風冷礦場，需要投入數千萬美元，預計産生約30000個礦位，那麽單個礦位的建設成本接近1000美元。',
	ProfitModel:'盈利模式',
    ProfitModelTxt:'與礦機的挖礦不同，礦位主要依靠礦工支付的電費差進行盈利，由于電費的收支均使用法幣，因此礦位的盈利更加穩定可控。',
    ProfitModelTxtExample:'舉例：比如美國清潔能源的成本是5 美分，而賣給礦工電價是7.5美分，則礦位的電費差爲2.5美分。1台礦機3KW計算，1個礦位壹的電費差價=3KW*S0.025*24小時*365天=657 美元/年。由于挖礦市場的火爆，礦位項且是壹個投入大、回本快、持續收益的好生意。',
	LeaseRevenueModel:'租賃收益模式',
	Iagree:'本人同意',
	minerAgree:'礦位租賃協議',
	ActualIssuance:'實際發放',
	IssuableTxt:'應發放',
	powerWastage:'功耗',
	hourTxt:'小時',
	getProfit:'獲得收益',
	totaRent:'總租金',
	InsufficientAccount:'賬戶余額不足',
    minerPageSub1:'租賃費低至礦機價30%',
    minerPageSub2:'保障算力在線率95%+',
    minerPageTips:'礦位租賃相比傳統購買礦機挖礦，租賃後即可立即挖礦，回本週期短、成本低、風險小，且更加靈活。',
    orePrice:'礦位費',
    ProductsTxt:'産品介紹',
    DiscountedElectricityRates:'折扣電費',
    centsTxt:'美分',
    BackCurrentCycle:'回本周期',
    managementExpense:'管理費',
    OpeningTime:'開售時間',
    endTime:'結束時間',
    minerAgreeTips:'請先同意購買礦位租賃協議',
    checkProfit:'查看收益',
    myMinesTxt:'我的礦位',
    completedTxt:'已完成',
    MiningTxt:'挖礦中',
	DiscontinuedTxt:'已終止',
    unknownStatus:'未知狀態',
    actualPaymentTxt:'實際支付',
    paybackStateTxt:'回本狀態',
    Returned:'已回本',
	NotReturned:'未回本',
    MiningRemaining:'挖礦剩余天數',
    CumulativeOutput:'累計産出',
    YesterdayOutput:'昨日收益',
    MiningDays:'挖礦天數',
    MiningStartTimes:'挖礦開始時間',
    MiningEndTimes:'挖礦結束時間',
    DistanceToEnd:'距離結束',
    DistanceToStart:'距離開始',
    SaleHasEnded:'售賣已結束',
    SaleNotStarted:'售賣未開始',

    PaymentAlerts:'付款提醒',
    orderCosts:'支付將花費',
    confirmPay:'您確定要支付嗎?',
    cancelTxt:'取消',
    confirmTxt:'確定',

    NotYetOpen:'尚未開放',


}