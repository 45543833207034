import React, { useState } from 'react';
import './earns.scss';
import { Table,Tabs,Carousel } from 'antd';
import {Link} from 'react-router-dom'
import cardBg from '../../assets/reward-card-bg.webp';

const curRef = React.createRef();
const tabItems = [
    {
      key: '0',
      label: 'Statement'
    },
    {
      key: '1',
      label: 'Expiring Rewards',
    }
];

const stateTableHader = [
    {
        title:'Time',
        dataIndex:'time',
        key:'time'
    },
    {
        title:'Type',
        dataIndex:'type',
        key:'type',
        filters:[
            {
                text: 'All',
                value: 'All',
              },
              {
                text: 'Order Remain',
                value: 'Remain',
              },
              {
                text:'History Fund',
                value:'history'
              },
              {
                text:'Buy',
                value:'buy'
              },
              {
                text:'Order refund',
                value:'refund'
              },
              {
                text:'Miner Coupon Transaction Cash Back',
                value:'miner'
              },
              {
                text:'Order Payment',
                value:'payment'
              },
              {
                text:'Service fee withholding',
                value:'service'
              },
              {
                text:'Rewards',
                value:'rewards'
              }
        ],
        onFilter:(value,record)=>record.type.startsWith(value),
        filterSearch:true,
        filterMultiple:false
    },
    {
        title:'Amount',
        dataIndex:'amount',
        key:'amount'
    },
    {
        title:'Balance',
        dataIndex:'balance',
        key:'balance'
    },
    {
        title:'Remark',
        dataIndex:'remark',
        key:'remark'
    }
];


const rewardsTableHeader = [
    {
        title:'Validity Until',
        dataIndex:'validity',
        value:'validity'
    },
    {
        title:'Amount',
        dataIndex:'amount',
        value:'amount'
    }
]
export default function Earns(){
    const [statementList,setStatementList] = useState([]);
    const [rewardsList,setRewardsList] = useState([
        {
            validity:'2023-11-15 15:40:58',
            amount:2.5665
        }
    ]);
    
    const tableChange = (pagination, filters, sorter, extra)=>{
        console.log('params', pagination, filters, sorter, extra);
    }

    const tabChange = (index) => {
        curRef.current.goTo(index);
    };

    return (
        <div className='earns-body'>
            <p className='point-title'>Earns</p>
            <div className='point-info'>
              <div className='card-box'>
                <div className='card-Ava'>Available Amount</div>
                <div className='card-point'>0</div>
                <div className='card-note'>
                    No available FuFu Earns at the moment.
                </div>
                <img className='card-bg' src={cardBg} alt='' />
              </div>
              <div className='point-right'>
                <div className='point-right-title'>
                  <p className='txt'>About *** Earns</p>
                </div>
                <p className='point-right-desc'>
                    Earns can pay for ***'s products and services.
                </p>
                <p className='point-right-desc'>
                    Earns is equivalent to USD 1:1, no minimum threshold. 
                </p>
                <p className='point-right-desc'>
                    Please use it within the validity period.
                </p>
                <div className='purchase-btn'>
                  <Link>
                    <i className='bitfont bitvipkehu'></i>
                    Using *** Earns
                  </Link>
                </div>
              </div>
            </div>


            <Tabs defaultActiveKey="0" size='large' items={tabItems} onChange={tabChange} />
            <Carousel dots={false} ref={curRef} waitForAnimate={true}>
                <div>
                    <Table size='small' columns={stateTableHader} dataSource={statementList} onChange={tableChange} />
                </div>
                <div>
                    <Table size='small' columns={rewardsTableHeader} dataSource={rewardsList} />
                </div>
            </Carousel>
            
        </div>
    )
}