import React, { useState,useContext, useEffect } from 'react';
import './invite.scss';
import { Tabs, Table, Carousel, Statistic, message, ConfigProvider,Empty } from 'antd';
import CountUp from 'react-countup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QrcodeModal from './QrcodeModal/QrcodeModal';
import CouponItem from '../../components/CouponItem/CouponItem';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import InitSetpIcon from '../../utils/initStepIcon';

import bannerImgEn from '../../assets/invite-banner-en.png';
import bannerImgHk from '../../assets/invite-banner-hk.png';
import stepsProcess from '../../assets/invite-process-line.png';
import rank1 from '../../assets/record-1.png';
import rank2 from '../../assets/record-2.png';
import rank3 from '../../assets/record-3.png';



const curRef = React.createRef();

const RankNo = ({ rank }) => {
    switch (rank) {
        case 1:
            return <img className='rankimg' src={rank1} alt='' />
        case 2:
            return <img className='rankimg' src={rank2} alt='' />
        case 3:
            return <img className='rankimg' src={rank3} alt='' />
        default:
            return <div className='rankimg'>{rank}</div>;
    }
}



const formatter = (value) => <CountUp end={value} separator="," />;

export default function Invite() {
    const [isOpenQrdode,setOpenQrcode] = useState(false);
    // const [rewardList, setRewardList] = useState([]);
    const [rankList, setRankList] = useState([]);
    const [inviteInfo, setInviteInfo] = useState({});
    const [platInfo,setplatInfo] = useState({});

    const {token,inviteCode} = useContext(AuthContext);
    const inviteLink = `${window.location.origin+'/invitee/'+inviteCode}`
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tabList = [
        {
            key: '0',
            label:language.inviteTabTxt
        }
    ];
    
    const inviteTableHeader = [
        {
            title:language.myFriendTxt,
            dataIndex: 'userName',
            key: 'userName'
        },
        {
            title:language.invitationTime,
            dataIndex: 'createDate',
            key: 'createDate'
        },
        {
            title:language.rewardTxt,
            dataIndex: 'reward',
            key: 'reward',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.reward - b.reward,
        },
    ];

    const rankTableHeader = [
        {
            title:language.rankingTxt,
            dataIndex: 'rank',
            key: 'rank',
            render: (text) => <RankNo rank={text} />
        },
        {
            title:language.userNameTxt,
            dataIndex: 'userName',
            key: 'userName'
        },
        {
            title:language.rewardTxt,
            dataIndex: 'amount',
            key: 'amount'
        }
    ];

    // const [couponList, setCouponList] = useState([
    //     {
    //         title: 'Cloud Hashrate Coupon',
    //         price: '50',
    //         isClaim: true
    //     },
    //     {
    //         title: 'Cloud Hashrate Coupon',
    //         price: '250',
    //         isClaim: false
    //     },
    //     {
    //         title: 'Cloud Hashrate Coupon',
    //         price: '700',
    //         isClaim: false
    //     }
    // ])

    const tabChange = (index) => {
        curRef.current.goTo(index);
    }

    // Copy Success
    message.config({
        top: 300,
        duration: 2,
        contentPadding:'15px 50px'
    });
    const handleCopy = (text) => {
        message.success({
            content:language.copySuccess,
        });
    }

    const getQrcodeStatus = (status)=>{
        setOpenQrcode(status)
    }


    const getInviteList = async()=>{
        const resData = await lsRequest('/inviteRecord/inviteRecord','POST',{l:language.requestType},token);

        if(resData&&resData.data){
            setInviteInfo(resData.data);
        }
    }

    const getPlatInfo = async()=>{
        const resData = await lsRequest('/inviteRecord/inviteDetail','POST',{l:language.requestType});
        
        if(resData){
            setplatInfo(resData.data);
        }
    }

    const getInviteRank = async()=>{
        const resData = await lsRequest('/inviteRecord/hbRanking','POST',{l:language.requestType},token);

        if(resData&&resData.data.list1){
            let rankArr = resData.data.list1.map((item,index)=>{
                item.rank = index+1;
                return item;
            })
            setRankList(rankArr);
        }
    }

    useEffect(()=>{
        getInviteList();
        getInviteRank();
        getPlatInfo();
    },[])


    const locale =()=>{
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={language.noRecordTips} /> 
    };

    const locale1 =()=>{
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={language.noRecordTxt} /> 
    };

    return (
        <div className='invite-body body-content'>
            {/* invite-banner */}
            <div className='invite-banner'>
                <div className='h-w1300 invite-banner-content'>
                    <div className='banner-txt'>
                        <span className='num'>
                            <Statistic value={platInfo.peopleNum} precision={0} formatter={formatter} />
                        </span>
                        {language.invitedPeoples}
                    </div>
                    <span className='banner-rules'>{language.rulesTxt}</span>
                    <img className='b-img' src={langType==='hk'?bannerImgHk:bannerImgEn} alt='' />
                </div>
            </div>

            {/* invite-steps */}
            <div className='invite-steps-bg'></div>
            <div className='invite-steps h-w1300'>
                {
                    language.inviteStepList.map((item,index)=>{
                        return <div key={index} className='invite-steps-item'>
                            <div className='invite-steps-con'>
                                <InitSetpIcon index={index} />
                                <div>
                                    <h4 className='step-title'>{item.title}</h4>
                                    <p className='step-desc'>
                                        {item.sub1}<span className='num'>{item.sub2}</span>{item.sub3&&item.sub3}
                                    </p>
                                </div>
                            </div>
                            {index<2&&<img className='invite-process' src={stepsProcess} alt='' />}
                        </div>
                    })
                }
            </div>


            {/* My Rewards */}
            <div className='my-invite h-w1300'>
                <div className='invite-info-box'>
                    <h4 className='invite-info-title'>{language.sendInvitation}</h4>
                    <div className='invite-info-lab'>
                        <span className='lab-txt'>{language.referralCode}</span>
                    </div>
                    <div className='invite-code'>
                        {inviteCode}
                        <CopyToClipboard text={inviteCode} onCopy={() => { handleCopy(inviteCode) }}>
                            <i className='bitfont bitfuzhi'></i>
                        </CopyToClipboard>
                    </div>
                    <div className='invite-info-lab'>
                        <span className='lab-txt'>{language.invitationLink}</span>
                    </div>
                    <div className='invite-link'>
                        {inviteLink}
                        <CopyToClipboard text={inviteLink} onCopy={() => { handleCopy(inviteLink) }}>
                            <i className='bitfont bitfuzhi'></i>
                        </CopyToClipboard>
                    </div>
                    <div className='invite-btn ls-btn' onClick={()=>getQrcodeStatus(true)}>{language.inviteNow}</div>

                    <QrcodeModal setQrcodeStatus = {getQrcodeStatus} handleCopyCode={handleCopy} handleCopyLink={handleCopy} isOpen = {isOpenQrdode} codeTxt={inviteCode} inviteLink={inviteLink} language={language} />
                </div>
                <div className='invite-info-box'>
                    <h4 className='invite-info-title'>{language.myRewards}</h4>
                    <div className='invite-info-lab'>
                        <span className='lab-txt'>{language.accumulatedrewards}</span>
                        <span className='lab-txt'>{language.inviteesNumber}</span>
                    </div>
                    <div className='invite-info-amount'>
                        <span className='num-txt'>{inviteInfo.totalAmount}</span>
                        <span className='num-txt'>{inviteInfo.peopleNum}</span>
                    </div>
                </div>
            </div>

            <div className='h-w1300 content-box'>
                {/* Reward List && Invite List */}
                <div className='tabs-box-con'>
                    <Tabs defaultActiveKey="0" size='large' items={tabList} onChange={tabChange}></Tabs>
                    <Carousel dots={false} ref={curRef}>
                        <div>
                            <ConfigProvider renderEmpty={locale}>
                                <Table e size='small' columns={inviteTableHeader} dataSource={inviteInfo.list} />
                            </ConfigProvider>
                        </div>
                    </Carousel>
                </div>

                {/* Invitation Ranking */}
                <div className='rank-box'>
                    <h3 className='rank-title'>{language.invitationRanking}</h3>
                    <ConfigProvider renderEmpty={locale1}>
                        <Table pagination={false} size='small' columns={rankTableHeader} dataSource={rankList} />
                    </ConfigProvider>
                    
                </div>

                {/* Invitation Rewards Description */}
                <div className='invite-description'>
                    {/* <div className='invite-desc-header'>
                        <div className='title'>
                            <span className='icon'></span>
                            Invitation Rewards
                        </div>
                        <button className='btn ls-btn'>Invite</button>
                    </div>
                    <p className='invite-header-desc'>Quest: Get $1000 coupon by inviting 5+ friends who buy 200-day plan or above.</p> */}

                    {/* Cloud Hashrate Coupon */}
                    {/* <div className='invite-coupon-list'>
                        {
                            couponList.map((item, index) => {
                                return <>
                                    <CouponItem key={index} info={item} />
                                </>
                            })
                        }
                    </div> */}

                    {/* Invite Rules */}
                    <div className='invite-ruler-title'>{language.eventRules}:</div>
                    {
                        language.eventRulesList.map((item,index)=>{
                            return <div key={index} className='invite-ruler-item'>
                                <p className='invite-ruler-sub'>{item.title}</p>
                                {
                                    item.desc.map((val,num)=>{
                                        return <p key={num} className='invite-ruler-desc'>{val}</p>
                                    })
                                }
                            </div>
                        })
                    }
                    <p className='invite-ruler-desc'></p>
                </div>

            </div>

        </div>
    )
}