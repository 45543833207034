import React,{useEffect, useState,useContext} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './ore.scss';
import OreInfo from './OreInfo/OreInfo';
import OreDesc from './OreDesc/OreDesc';
import GoodsDetailTitle from '../../components/GoodsDetailTitle/GoodsDetailTitle';
import lsRequest from '../../utils/lsRequest';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { message } from 'antd';

export default function Ore(){
    const [totalPrice,setTotalPrice] = useState(0);
    const [minerNum,setMinerNum] = useState(1);

    const [oreInfo,setOreInfo] = useState({});

    const params = useParams();
    const navigate = useNavigate();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getDetailInfo = async()=>{
        const resData = await lsRequest('/oreLocation/getOreDetail','POST',{oreId:params.pid,l:language.requestType});
        resData.data.yearProfit = (resData.data.dailyInput*365/resData.data.oreCoinFee*100).toFixed(2);

        setMinerNum(resData.data.minBuyNumber);
        setOreInfo(resData.data);
        initPrice(resData.data.minBuyNumber,resData.data.oreCoinFee);
    }

    const toPayOreOrder=()=>{
        if(!token){
            message.info(language.loginTips);
            return false;
        }

        let orderData={
            amount:minerNum,
            orePrice:totalPrice,
        }
        orderData = JSON.stringify(orderData);

        localStorage.setItem('oreInfoData',orderData);

        navigate(`/orePay/${params.pid}`);
    }

    const initPrice = (num,oreCoinFee)=>{
        setTotalPrice(formatDecimal(num*oreCoinFee,3)); 
    }

    useEffect(()=>{
        getDetailInfo();
    },[])

    useEffect(()=>{
        initPrice(minerNum,oreInfo.oreCoinFee);
    },[minerNum])


    return (
        <div className='body-content'>
            <div className='h-w1300' style={{paddingBottom:'60px'}}>
                <OreInfo 
                    info={oreInfo} 
                    language={language}
                    totalPrice={totalPrice}
                    setMinerNum={setMinerNum} 
                    toPay={toPayOreOrder} 
                 />
                <GoodsDetailTitle title={language.ProductsTxt} />
                {/* <img className='bannerStyle' src={oreInfo.pic} alt={oreInfo.oreName} /> */}

                {/* <GoodsDetailTitle title={language.investmentPromotionBuffer} />
                <OreDesc desc={language.investBufferTxt} />
                <GoodsDetailTitle title={language.MineralPositionProjectModel} />
                <OreDesc desc={language.minerModelTxt} />
                <GoodsDetailTitle title={language.ProfitModel} />
                <OreDesc desc={language.ProfitModelTxt} />
                <OreDesc desc={language.ProfitModelTxtExample} />
                <GoodsDetailTitle title={language.LeaseRevenueModel} />
                <p className='ore-desc'>
                    {
                        language.languageType==='hk'?`自租賃之日起${oreInfo.startDays}天緩沖期，第${oreInfo.startDays+2}天天開始每天發放，按自然月到賬，共計${oreInfo.periodDays}天發放完畢，期滿視作租賃權自動結束。`:`A buffer period of ${oreInfo.startDays} days from the date of the lease, with daily disbursements starting on the ${oreInfo.startDays+2}nd day and arriving on a natural monthly basis, totalling ${oreInfo.periodDays} days of disbursements, with the expiry of the period deemed to be the automatic termination of the leasehold right.`
                    }
                </p> */}

            </div>
        </div>
    )
}