import React from 'react';
import './oreinfo.scss';
import { InputNumber,ConfigProvider} from 'antd';
import defaultImg from '../../../assets/easy-product.webp';
import InitBuyBtn from '../../../components/InitBuyBtn/InitBuyBtn';


export default function OreInfo({info,language,totalPrice,setMinerNum,toPay}){
    const changeMinerNum= (val)=>{
        if(val){
            setMinerNum(val);
        }
    }

    return (
        <div className='ore-info'>
            <div className='info-left'>
                <div className='info-cover'>
                    <img className='cover' src={info.pics?info.pics:defaultImg} alt='' />
                </div>
            </div>
            <div className='info-right'>
                <h3 className='right-title'>
                    {info.oreName}
                </h3>
                <div className='info-amount'>
                    {language.quantityPurchased}：
                    <ConfigProvider
                        theme={{
                            components:{
                                InputNumber:{
                                    controlWidth:160,
                                    
                                }
                            }
                        }}
                    >
                        <InputNumber 
                            size='large'
                            step={1}
                            min={info.minBuyNumber} 
                            max={info.maxBuyNumber} 
                            key={info.minBuyNumber}
                            defaultValue={info.minBuyNumber} 
                            onChange={changeMinerNum} 
                            formatter={(value) => `${value} ${language.copyTxt}`}
                            parser={(value) => value.replace(`${language.copyTxt}`, '')}
                        />
                    </ConfigProvider>
                </div>
                <ul className='info-data'>
                    <li className='info-item'>
                        <div className='info-item-1'>
                            <div className='info-lab'>{language.RentalUnitPrice}：</div>
                            <span className='info-val red-color'>${info.oreCoinFee}<i>/{info.periodDays}{language.daysTxt}</i></span>
                        </div>
                        <div className='info-item-1'>
                            <div className='info-lab'>{language.managementExpense}：</div>
                            <span className='info-val red-color'>
                                <i>{language.paybackTxt}</i>{info.beforeManageRadio}<i>~{language.afterPayback}</i>{info.afterManageRadio}
                            </span>
                        </div>
                    </li>
                    <li className='info-item'>
                        {
                            info.type===1?<div className='info-item-1'>
                            <div className='info-lab'>{language.annualisedAbout}：</div>
                            <span className='info-val red-color'>{info.yearProfit}%</span>
                        </div>:<div className='info-item-1'>
                            <div className='info-lab'>{language.DiscountedElectricityRates}：</div>
                            <span className='info-val red-color'>0.057{language.centsTxt}</span>
                        </div>
                        }
                        {/* <div className='info-item-1'>
                            <div className='info-lab'>{language.BackCurrentCycle}：</div>
                            <span className='info-val red-color'>{info.payBackDay}{language.daysTxt}</span>
                        </div> */}
                    </li>
                    <li className='info-item'>
                        <div className='info-item-1'>
                            <div className='info-lab'>{language.OpeningTime}：</div>
                            <span className='info-val red-color'><i>{info.sellStartDate}</i></span>
                        </div>
                        <div className='info-item-1'>
                            <div className='info-lab'>{language.endTime}：</div>
                            <span className='info-val red-color'><i>{info.sellEndDate}</i></span>
                        </div>
                    </li>
                </ul>
                <div className='total-amount'>
                    {language.totalPriceTxt}：<span className='amount'>${totalPrice}</span>
                </div>
                {
                    info.remainNumber>=info.minBuyNumber?<InitBuyBtn sTime={info.sellStartDate} eTime={info.sellEndDate} toPay={toPay} language={language} />:
                    <div className='buy-now-btn gray'>{language.SoldTxt}</div>
                }
                
            </div>
        </div>
    )
}