import React, { Component, useEffect } from 'react';
import './qrcode.scss';
import { Modal,QRCode,Typography } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qrcodeBgEN from '../../../assets/invitePosterEN.jpg';
import qrcodeBgHK from '../../../assets/invitePosterHK.jpg';
import qrcodeIcon from '../../../assets/logo192.png'

const { Text } = Typography;


export default function QrcodeModal({isOpen,codeTxt,inviteLink,setQrcodeStatus,handleCopyLink,handleCopyCode,language}){

    const hideQrcodeModal = ()=>{
        setQrcodeStatus(false);
    }

    const downloadQRCode = ()=>{
        //创建canvas标签并设置宽高
        let canvas = document.createElement('canvas');
        canvas.width = 420;
        canvas.height = 670;
        //创建content对象
        let context = canvas.getContext("2d");
        //绘制矩形
        context.rect(0,0,canvas.width,canvas.height);
        //设置颜色
        context.fillStyle = "#fff";
        //渲染
        context.fill();
        //创建Iamge对象
        let bgImg = new Image();
        //背景图片
        if(language.languageType==='hk'){
            bgImg.src = qrcodeBgHK;
        }
        else{
            bgImg.src = qrcodeBgEN;
        }
        
        bgImg.crossOrigin = 'Anonymous';//跨域必须

        bgImg.onload = ()=>{
            //画布中插入图片
            context.drawImage(bgImg,0,0,canvas.width,514);
            //画布中插入文字设置颜色字体
            context.fillStyle = '#c00';
            context.font = '500 20px Microsoft Yahei';
            context.fillText(language.posterTxt1,10,580);
            context.fillText(language.posterTxt2,10,610);

            const canvasQrcode = document.getElementById('myqrcode')?.querySelector('canvas');
            if (canvas) {
                const qrcodeUrl = canvasQrcode.toDataURL();
                //创建Image对象
                let qrcodeImg = new Image();
                qrcodeImg.src = qrcodeUrl;
                qrcodeImg.crossOrigin = 'Anonymous';
                qrcodeImg.onload = ()=>{
                    context.drawImage(qrcodeImg,270,524,140,140);
                    let base64Url = canvas.toDataURL("image/png");


                    //在图片中展示
                    // var img = document.getElementById('poster');
                    // document.getElementById('poster').src = base64Url;
                    // img.setAttribute('src' , base64Url);

                    //下载
                    const a = document.createElement('a');
                    a.download = 'QRCode.png';
                    a.href = base64Url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    
                }
            }
        }  
        
    }

    return (
        <Modal footer={null} open ={isOpen} onCancel={hideQrcodeModal} width={500} centered >
            <div className='qr-modal-box'>
                <div className='qr-img-con'>
                    <div className='qr-img'>
                        <img className='qr-bg' src={language.languageType==='hk'?qrcodeBgHK:qrcodeBgEN} />
                        <div className='qr-img-footer'>
                            <p className='qrcode-txt'>
                                {language.posterTxt1} <br />
                                {language.posterTxt2}
                            </p>
                            <div id="myqrcode">
                                <QRCode
                                    value={inviteLink}
                                    bgColor="#fff"
                                    size={140}
                                    icon={qrcodeIcon}
                                    bordered={false}
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='qr-modal-footer'>
                    <div className='act-item'>
                        <span className='act-item-lab'>{language.referralCode}：</span>
                        <span className='act-item-txt'>
                            {codeTxt}
                            <CopyToClipboard text={codeTxt} onCopy={() => { handleCopyCode(codeTxt) }}>
                                <i className='bitfont bitfuzhi'></i>
                            </CopyToClipboard>
                        </span>
                    </div>
                    <div className='act-item'>
                        <span className='act-item-lab'>{language.invitationLink}：</span>
                        <span className='act-item-txt'>
                            <Text style={{width:210}} ellipsis={true}>{inviteLink}</Text>
                            <CopyToClipboard text={inviteLink} onCopy={() => { handleCopyLink(inviteLink) }}>
                                <i className='bitfont bitfuzhi'></i>
                            </CopyToClipboard>
                        </span>
                    </div>
                    <div className='act-btn-group'>
                        <CopyToClipboard text={inviteLink} onCopy={() => { handleCopyLink(inviteLink) }}>
                            <span className='bitfont bitfuzhilianjie'></span>
                        </CopyToClipboard>
                        <span className='bitfont bitxiazai' onClick={downloadQRCode}></span>
                    </div>
                </div>

            </div>
        </Modal>
    )
}