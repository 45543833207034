const initCoinTxt = (coinName)=>{
    let cname = coinName.toLowerCase();
    switch (cname) {
        case 'btc':
            return <span style={{color:'#ff9b22'}}>{coinName}</span>
        case 'usdt':
            return <span style={{color:'#55a29f'}}>{coinName}</span>
        case 'fil':
            return <span style={{color:'#42c1ca'}}>{coinName}</span>
        case 'eth':
            return <span style={{color:'#676eb0'}}>{coinName}</span>
        default:
            return <span style={{color:'#c00'}}>{coinName}</span>
            break;
    }
}

export default initCoinTxt;