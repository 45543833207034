import React from 'react';
import { Modal } from 'antd';

export default function VerifyTipsModal({isOpenVerify,handleVerifyCancel,handleVerifyOk,language}){

    return (
        <Modal closeIcon={false} open={isOpenVerify} centered footer={null} width={460}>
            <div className='ls-modal-box'>
                <div className='ls-modal-content'>{language.verifyModalDesc}</div>
                <div className='ls-modal-footer'>
                    <div className='cancel' onClick={handleVerifyCancel}>{language.cancelBtnTxt}</div>
                    <div className='verify ls-btn' onClick={handleVerifyOk}>{language.verifyBtnTxt}</div>
                </div>
            </div>
        </Modal>
    )
}