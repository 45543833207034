import React,{useContext,useState,useEffect} from 'react';
import './oreposition.scss';
import { LanguageContext } from '../../contexts/LanguageContext';
import GoodsDetailTitle from '../../components/GoodsDetailTitle/GoodsDetailTitle';
import OreItem from '../../components/OreItem/OreItem';
import lsRequest from '../../utils/lsRequest';
import NoData from '../../components/NoData/NoData';

export default function OrePositon(){
    const [oreList,setOreList] = useState([]);

    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getOreList = async()=>{
        const resData = await lsRequest('/oreLocation/getOreList','POST',{l:language.requestType});
        if(resData.data&&resData.data.list.length>0){
            setOreList(resData.data.list);
        }
    }

    useEffect(()=>{
        getOreList()
    },[])

    return (
        <div className='body-content ore'>
            <div className='h-w1300'>
                <GoodsDetailTitle title={language.MinersProductTxt} />
                {/* <div className='miner-title'>
                    <span className='tag1'>{language.minerPageSub1}</span>
                    <span className='tag2'>{language.minerPageSub2}</span>
                    <span className='miner-tips'>{language.minerPageTips}</span>
                </div>
                 */}
                {
                    oreList.length>0?<div className='goods-list'>
                        {
                            oreList.map((item,index)=>{
                                return <div className='goods-item' key={index}>
                                    <OreItem info={item} language={language} />
                                </div>
                            })
                        }
                    </div>:<div style={{marginTop:'100px'}}><NoData language={language} /></div>
                }
            </div>
        </div>
    )
}