import React, { useEffect,useContext, useState } from 'react';
import './kyc.scss';
import { useNavigate } from 'react-router-dom';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Kyc(){
    const [memberInfo,setMemberInfo] = useState({});
    
    const navigate = useNavigate();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getMemberInfo = async()=>{
        const resData = await lsRequest('/memberInfo','POST',{l:language.requestType},token);
        if(resData){
            setMemberInfo(resData.data);
        }
    }

    useEffect(()=>{
        getMemberInfo();
    },[])

    return (
        <>
            <p className='account-set-title'>{language.identityVerification}</p>
            {/* Personal Verification */}
            <div className='account-com-title'>
                <h3 className='com-title'>
                    <span className='personal'></span>
                    {language.personalVerification}
                </h3>
            </div>
            <div className="account-com-box">
                <div>
                    <h5 className='com-box-title'>{language.basicVerification}</h5>
                    <p className='com-box-desc'>{language.basicVerificationDesc}</p>
                </div>
                {
                    memberInfo.authStatus===1?<span className='is-verify'>{language.verifiedTxt}</span>:<button className='com-box-btn no-ban' onClick={()=>navigate('/dashboard/kycbase')}>{language.notVerifiedTxt}</button>
                }
                
            </div>
            {/* <div className="account-com-box">
                <div>
                    <h5 className='com-box-title'>LV.2 Advanced Verification</h5>
                    <p className='com-box-desc'>Increase total transaction limit to 100,000,000 USD</p>
                </div>
                <button className='com-box-btn ban'>Not Verified</button>
            </div> */}
            {/* Enterprise Verification */}
            {/* <div className='account-com-title'>
                <h3 className='com-title enterprice'>
                    <span className='enterprice'></span>
                    Enterprise Verification
                </h3>
            </div>
            <div className="account-com-box">
                <div>
                    <h5 className='com-box-title'>Enterprise Verification</h5>
                    <p className='com-box-desc'>Increase total transaction limit to 100,000,000 USD</p>
                </div>
                <button className='com-box-btn no-ban'>Not Verified</button>
            </div> */}

            <p className='kyc-note'>{language.verificationNotes}</p>
        </>
    )
}