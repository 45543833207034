import React from 'react';
import './nocoupons.scss';
import noCouponImg from '../../assets/no-coupon.png';

export default function NoCoupons({language}){
    return (
        <div className='no-coupon'>
            <img className='img' src={noCouponImg} alt='' />
            <p className='note'>{language.noCouponsTxt}</p>
        </div>
    )
}