import React from 'react';
import './freeinfo.scss';
import { Tooltip } from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import initToCoinNum from '../../../utils/initToCoinNum';
import InitBuyBtn from '../../../components/InitBuyBtn/InitBuyBtn';

import goodsimg from '../../../assets/easy-product.webp';
import tagimg from '../../../assets/c-tab1.webp';

export default function FreeInfo({info,toPay,language}){

    return (
        <div className='free-info'>
            <div className='info-left'>
                <div className='info-cover'>
                    <img className='cover' src={goodsimg} alt='' />
                </div>
                <ul className='info-desc'>
                    {
                        language.freePackageDesc.map((item,index)=>{
                            return <li key={index} className='desc-item'>{item}</li>
                        })
                    }
                </ul>
            </div>
            <div className='info-right'>
                <h3 className='right-title'>
                    {info.package_name}
                    <span className='tag'>
                        <img className='img' src={tagimg} alt='' />
                        {language.newUserBtn}
                    </span>
                </h3>
                <div className='info-amount'>
                    {language.quantityPurchased}：
                    <span className='new-power'>
                        {info.packageType===2?`${info.calPower}${info.calPowerUnit}`:`1${language.unitTxt}${info.calPower}${info.calPowerUnit}${language.minerTitle}`}
                    </span>
                </div>
                <div className='info-amount'>
                    {language.buyDays}：
                    <span className='new-power'>{info.periodDays}{language.daysTxt}</span>
                </div>
                <ul className='info-data'>
                    <li className='info-item'>
                        <div className='info-lab'>{language.durationStaticOutput}：</div>
                        <span className='info-val'>{info.perFee} {info.digCoin} </span>
                    </li>
                    <li className='info-item'>
                        <div className='info-lab'>{language.equivalentTxt}：</div>
                        <span className='info-val'>
                            $ {initToCoinNum(info.perFee,info.exchangeRate,2)}
                            <Tooltip placement="top" title={language.equivalentTips+info.exchangeRate} arrow={true}>
                                <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'6px'}} />
                            </Tooltip>
                        </span>
                    </li>
                    <li className='info-item'>
                        <div className='info-lab'>{language.staticOutputRatio}：</div>
                        <span className='info-val red-color'>
                            {info.staticOutputRadio} % 
                            <Tooltip placement="top" title={language.staticOutputRadioTips+info.exchangeRate} arrow={true}>
                                <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'6px'}} />
                            </Tooltip>
                        </span>
                    </li>
                    <li className='info-item'>
                        <div className='info-lab'>{language.staticCurrencyAcquisitionPrice}：</div>
                        <span className='info-val red-color'>
                            ${info.exchangeRate}
                            <Tooltip placement="top" title={language.exchangeRateTips} arrow={true}>
                                <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'6px'}} />
                            </Tooltip>
                        </span>
                    </li>
                </ul>
                <InitBuyBtn sTime={info.sellStartDate} eTime={info.sellEndDate} toPay={toPay} language={language} />
            </div>
        </div>
    )
}