import stepsImg1 from '../assets/process-1.png';
import stepsImg2 from '../assets/process-2.png';
import stepsImg3 from '../assets/process-3.png';

const InitSetpIcon = ({index})=>{
    switch (index) {
        case 0:
            return <img className='step-icon' src={stepsImg1} alt='' />
        case 1:
            return <img className='step-icon' src={stepsImg2} alt='' />
        case 2:
            return <img className='step-icon' src={stepsImg3} alt='' />
        default:
            return <img className='step-icon' src={stepsImg1} alt='' />
    }
}

export default InitSetpIcon;
