import { useContext } from 'react';
import {Route,BrowserRouter,Routes} from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import { arbitrum, mainnet } from 'viem/chains';
import { ConfigProvider,message } from 'antd';
import zhHK from 'antd/locale/zh_HK';
import enUS from 'antd/locale/en_US';

import Navbar from './components/navbar/Navbar.js';
import Footer from './components/Footer/Footer.js';
import AuthContextProvider from './contexts/AuthContext.js';
import { LanguageContext } from './contexts/LanguageContext.js';

import Home from './view/home/Home.js';
import CloudMining from './view/cloud/CloudMining.js';
import Miner from './view/miner/Miner.js';
import OrePositon from './view/orePostion/OrePostion.js';
import Coupon from './view/coupon/Coupon.js';
import Rewards from './view/rewards/Rewards.js';
import Free from './view/free/FreeDetail.js';
import Full from './view/full/FullDetail.js';
import Ore from './view/ore/Ore.js';
import EasyPay from './view/easyPay/EasyPay.js';
import FullPay from './view/fullPay/FullPay.js';
import OrePay from './view/orePay/OrePay.js';
import Dashboard from './view/dashboard/Dashboard.js';
import Hashrate from './view/hashrate/Hashrate.js';
import DailyEarning from './view/dailyEarning/DailyEarning.js';
import OrderDailyEarning from './view/OrderDailyEarning/OrderDailyEarning.js';
import Hosting from './view/hosting/Hosting.js';
import OreOrder from './view/oreOrder/OreOrder.js';
import OreDailyProfit from './view/oreDailyProfit/OreDailyProfit.js';
import OreOrderDetails from './view/oreOrderDetails/OreOrderDetails.js';
import Myfortune from './view/myFortune/Myfortune.js';
import Points from './view/points/Points.js';
import Earns from './view/earns/Earns.js';
import ServiceFee from './view/serviceFee/ServiceFee.js';
import MyOrder from './view/myOrder/MyOrder.js';
import OrderDetail from './view/orderDetail/OrderDetail.js';
import OrderProfitDetail from './view/orderProfitDetail/OrderProfitDetail.js';
import Invite from './view/invite/Invite.js';
import MyCoupon from './view/myCoupon/MyCoupon.js';
import BitCoupon from './view/bitCoupon/BitCoupon.js';
import AccountSetting from './view/accountSetting/AccountSetting.js';
import Kyc from './view/kyc/kyc.js';
import KycBase from './view/kycBase/KycBase.js';
import News from './view/news/News.js';
import MyWallet from './view/myWallet/myWallet.js';
import Deposit from './view/deposit/Deposit.js';
import Withdrawal from './view/withdrawal/Withdrawal.js';
import Invitee from './view/invitee/Invitee.js';
import AddressManage from './view/addressManage/AddressManage.js';
import About from './view/about/About.js';
import Help from './view/help/Help.js';

// 1. Get projectId
const projectId = '33728a78ee836bed5eb359744921c468';

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const chains = [mainnet, arbitrum];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

message.config({
  top: 300,
  prefixCls: 'my-message',

});


function App() {
  const {langType} = useContext(LanguageContext);
  return (
    <ConfigProvider
      locale={langType==='en'?enUS:zhHK}
      theme={{
        components: {
            Tabs: {
                itemActiveColor: '#c00',
                itemHoverColor: '#c00',
                itemSelectedColor: '#c00',
                inkBarColor: '#c00',
                titleFontSize: 16
            },
            Table: {
                fontWeightStrong: 450
            },
            Button:{
                colorPrimaryBorderHover:'#c00',
                colorPrimaryHover:'#a00',
                colorPrimary:'#c00',
                colorLink:'#c00',
                colorLinkHover:'#a00'
            },
            Input:{
                colorPrimaryHover:'#d9d9d9',
                activeBorderColor:'#d9d9d9',
                controlOutline:'#fcf2f2'
            },
            Dropdown:{
              colorPrimary:'#c00',
              controlItemBgActiveHover:'rgb(248, 186, 186)',
              controlItemBgActive:'rgb(255, 200, 200)'
            },
            Radio:{
              colorPrimary:'#c00'
            },
            Pagination:{
              colorPrimary:'#c00',
              colorPrimaryHover:'#a00'
            },
            Checkbox:{
              colorPrimary:'#c00',
              colorPrimaryHover:'#a00'
            },
            Select:{
              optionSelectedBg:'#fcf2f2'
            },
            Steps:{
              colorPrimary:'#c00',
              controlItemBgActive:'#fcf2f2'
            },
            Result:{
              colorSuccess:'#c00'
            },
            DatePicker:{
              activeBorderColor:'#C00',
              hoverBorderColor:'#C00',
              colorPrimary:'#C00',
              controlItemBgActive:'#fcf2f2'
            },
            Statistic:{
              contentFontSize:16,
              titleFontSize:14,
              colorTextHeading:'#c00'
            }
        }
    }}
    >
      <WagmiConfig config={wagmiConfig}>
        <AuthContextProvider>
          <BrowserRouter>
            <div className='App'>
              <Navbar />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/cloud' element={<CloudMining />} />
                <Route path='/miner' element={<Miner />} />
                <Route path='/orepos' element={<OrePositon />} />
                <Route path='/coupon' element={<Coupon />} />
                <Route path='/rewards' element={<Rewards />} />
                <Route path='/free/:pid' element={<Free />} />
                {/* type 0 算力 1 矿机 */}
                <Route path='/full/:pid' element={<Full />} /> 
                <Route path='/ore/:pid' element={<Ore />} />
                <Route path='/easyPay/:id' element={<EasyPay />} />
                <Route path='/fullPay/:id' element={<FullPay />} />
                <Route path='/orePay/:id' element={<OrePay />} />
                <Route path='/news' element={<News />} />
                <Route path='/about' element={<About />} />
                <Route path='/help' element={<Help />} />
                <Route path='/dashboard' element={<Dashboard />}>
                  <Route path='' element={<Myfortune />} />
                  <Route path='hashrate' element={<Hashrate />} />
                  <Route path='dailyearning/:coinName' element={<DailyEarning />} />
                  <Route path='hosting' element={<Hosting />} />
                  <Route path='orderearning/:id/:coinName/:type' element={<OrderDailyEarning />} />
                  <Route path='oreorder' element={<OreOrder />} />
                  <Route path='oreprofit/:id/:coinName' element={<OreDailyProfit />} />
                  <Route path='oreorderdetail' element={<OreOrderDetails />} />
                  <Route path='points' element={<Points />} />
                  <Route path='wallet' element={<MyWallet />} />
                  <Route path='withdrawal/:coinName' element={<Withdrawal />} />
                  <Route path='addrmanage/:coinName' element={<AddressManage />} />
                  <Route path='deposit/:coinName' element={<Deposit />} />
                  <Route path='earns' element={<Earns />} />
                  <Route path='servicefee' element={<ServiceFee />} />
                  <Route path='myorders' element={<MyOrder />} />
                  <Route path='orderDetail/:id' element={<OrderDetail />} />
                  <Route path='orderProfit/:id/:type' element={<OrderProfitDetail />} />
                  <Route path='mycoupon' element={<MyCoupon />} />
                  <Route path='bitcoupon' element={<BitCoupon />} />
                  <Route path='accountsetting' element={<AccountSetting />} />
                  <Route path='kyc' element={<Kyc />} />
                  <Route path='kycbase' element={<KycBase />} />
                </Route>
                <Route path='/invite' element={<Invite />} />
                <Route path='/invitee/:ivtCode' element={<Invitee />} />
                <Route path='*' element={<Home />} />
              </Routes>
              <Footer />
            </div>
          </BrowserRouter>
        </AuthContextProvider>
      </WagmiConfig>
    </ConfigProvider>
  );
}

export default App;
