const OrderStatus = ({status,language})=>{
    switch (status) {
        case 1:
            return <span style={{fontSize:'14px'}}>{language.PendingTxt}</span>;
        case 2:
            return <span style={{fontSize:'14px'}}>{language.ConfirmedTxt}</span>;
        case 3:
            return <span style={{fontSize:'14px'}}>{language.PaidTxt}</span>;
        case 4:
            return <span style={{fontSize:'14px'}}>{language.ShelveTxt}</span>;
        default:
            return <span style={{fontSize:'14px'}}>{language.UnknownTxt}</span>;
    }
}

export default OrderStatus