import React from 'react';
import './needhelp.scss';

import needImg1 from '../../../assets/need1.webp';
import needImg2 from '../../../assets/need2.webp';
import needImg3 from '../../../assets/need3.webp';

const InitIcon = ({index})=>{
    switch (index) {
        case 0:
            return <img className='img' src={needImg1} alt='' />
            break;
        case 1:
            return <img className='img' src={needImg2} alt='' />
            break;
        case 2:
            return <img className='img' src={needImg3} alt='' />
            break;
        default:
            break;
    }
}

export default function NeedHelp({language}){
    return (
        <div className="need-help h-w1300">
            <h3 className='n-h-title'>
                <span className='txt'>
                    {language.needHelpTxt}<span className='wenhao'></span>
                </span>
            </h3>
            <ul className='need-help-list'>
                {
                    language.needHelpList.map((item,index)=>{
                        return <li key={index} className='help-item'>
                            <InitIcon index={index} />
                            <h4 className='help-item-title'>{item.title}</h4>
                            <p className='help-item-desc'>{item.desc}</p>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}