import React from 'react';
import './nodata.scss';
import noDataImg from '../../assets/no_data.png';

export default function NoData({txt,language}){
    return (
        <div className='no-data'>
            <img className='img' src={noDataImg} alt='' />
            <p className='note'>
                {txt?txt:language.noDataTxt}
            </p>
        </div>
    )
}