import React, { useState,useEffect,useContext } from 'react';
import { Link,useNavigate} from 'react-router-dom';
import './oreorder.scss';
import { Table,Button } from 'antd';
import lsRequest from '../../utils/lsRequest';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import OreDigStatus from '../../components/OreDigStatus/OreDigStatus';



export default function OreOrder(){
    const [oreList,setOreList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
    const navigate = useNavigate();

    const tableHeader = [
        {
            title:language.nameTxt,
            dataIndex:'packageName',
            value:'packageName',
            align:'center'
        },
        {
            title:language.dateTxt,
            dataIndex:'createDate',
            value:'createDate',
            align:'center'
        },
        {
            title:language.QuantityTxt,
            dataIndex:'actualBuyNumber',
            value:'actualBuyNumber',
            align:'center',
            render:(_,{actualBuyNumber})=>(
                <span>{actualBuyNumber}{language.copyTxt}</span>
            )
        },
        {
            title:language.totalCosts,
            dataIndex:'actualPayFee',
            value:'actualPayFee',
            align:'center',
            render:(_,{actualPayFee})=>(
                <span style={{color:'#c00'}}>${actualPayFee}</span>
            )
        },
        {
            title:language.orderStatus,
            dataIndex:'digStatus',
            value:'digStatus',
            align:'center',
            render:(_,item)=>(
                <OreDigStatus language={language} status={item.digStatus} />
            )
        },
        {
            title:language.CumulativeOutput,
            dataIndex:'totalAmount',
            value:'totalAmount',
            align:'center'
        },
        {
            title:language.YesterdayOutput,
            dataIndex:'yesterdayDugAmount',
            value:'yesterdayDugAmount',
            align:'center'
        },
        {
            title:language.actionTxt,
            dataIndex:'action',
            value:'action',
            align:'center',
            render:(_,item)=>(
                <>
                    <p>
                        <Link to={'/dashboard/oreprofit/'+item.memberOreId+'/'+item.coinName}>
                            <Button size='small'>{language.checkProfit}</Button>
                        </Link>
                    </p>
                    <p style={{marginTop:'10px'}}>
                        <Button size='small' onClick={()=>toOreOrderDetail(item)}>{language.OrderDetail}</Button>
                    </p>
                </>
                
            )
        }
    ]

    const toOreOrderDetail = (item)=>{
        localStorage.setItem('oreOrderDetails',JSON.stringify(item));
        navigate('/dashboard/oreorderdetail');
    }

    const getOreOrders = async()=>{
        const resData = await lsRequest('/memberOre/getMemberOreList','POST',{
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            setOreList(resData.data.list);
        }
    }

    useEffect(()=>{
        getOreOrders();
    },[])

    return (
        <div className='ore-order'>
            <div className='ore-profit-title'>
                {language.getProfit}
            </div>
            <div  className='ore-profit'>
                <h3 className='amount'>$ 0</h3>
            </div>
            <p style={{padding:'20px 0'}}>{language.miningList}</p>
            <Table 
                rowKey={'memberOreId'}
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                dataSource={oreList} 
                columns={tableHeader}
                size="small" 
            />
        </div>
    )
}