import React, { useState,useContext, useEffect } from 'react';
import './mycoupon.scss';
import MyCouponItem from '../../components/MyCouponItem/MyCouponItem';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import NoCoupons from '../../components/NoCoupons/NoCoupons';
import { LanguageContext } from '../../contexts/LanguageContext';


export default function MyCoupon(){
    const [tabsIndex,setTabsIndex] = useState(0);
    const [allNum,setAllNum] = useState(0);
    const [availableNum,setAvailableNum] = useState(0);
    const [usedNum,setUsedNum] = useState(0);
    const [expiredNum,setExpiredNum] = useState(0);
    
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    // const [tags,setTags] = useState([
    //     {text:'All',index:0},
    //     {text:'Hashrate Fee Coupon', index:1},
    //     {text:'Service Fee Coupon',index:2}
    // ]);
    // const [tagsIndex,setTagsIndex] = useState(0);

    const [couponList,setCouponList] = useState([]);
    const [filterList,setFilterList] = useState([]);


    const changeTabs = (item)=>{
        setTabsIndex(item.index);
        filterCoupont(item.status);
    }

    // const changeTags = (index)=>{
    //     setTagsIndex(index);
    // }

    const getMyCouponList = async()=>{
        const resData = await lsRequest('/coupon/getCouponList','POST',{l:language.requestType},token);
        
        if(resData.data.list){
            countNum(resData.data.list);
            setCouponList(resData.data.list);
            setFilterList(resData.data.list);
        }
    } 

    const countNum = (list)=>{
        let newArr = list;
        let availableNum = newArr.filter(item=>item.couponStatus === 1);
        let usedNum = newArr.filter(item=>item.couponStatus === 3);
        let expiredNum = newArr.filter(item=>item.couponStatus === 0);

        setAllNum(newArr.length);
        setAvailableNum(availableNum.length);
        setUsedNum(usedNum.length);
        setExpiredNum(expiredNum.length);
    }

    const filterCoupont = (status)=>{
        if(status===100){
            setFilterList(couponList);
        }
        else{
            let arr = couponList;
            let filterData = arr.filter(item=>item.couponStatus===status);
            setFilterList(filterData);
        }
        
    }

    useEffect(()=>{
        getMyCouponList();
    },[])

    return (
        <div className='my-coupon'>
            <p className='my-coupon-header'>{language.couponTxt}</p>
            <div className='tabs-list'>
                <span 
                    onClick={()=>changeTabs({index:0,status:100})} 
                    className={`tab-item ${tabsIndex===0?'active':''}`}>
                    {language.allTxt}({allNum})
                </span>
                <span 
                    onClick={()=>changeTabs({index:1,status:1})} 
                    className={`tab-item ${tabsIndex===1?'active':''}`}>
                    {language.availableTxt}({availableNum})
                </span>
                <span 
                    onClick={()=>changeTabs({index:2,status:3})} 
                    className={`tab-item ${tabsIndex===2?'active':''}`}>
                    {language.usedTxt}({usedNum})
                </span>
                <span 
                    onClick={()=>changeTabs({index:3,status:0})} 
                    className={`tab-item ${tabsIndex===3?'active':''}`}>
                    {language.expiredTxt}({expiredNum})
                </span>
            </div>
            {/* <div className='tags-list'>
                {
                    tags.map((item,index)=>{
                        return <span onClick={()=>{changeTags(index)}} className={`tags-item ${tagsIndex===index?'active':''}`}>{item.text}</span>
                    })
                }
            </div> */}

            <div className='my-coupon-list'>
                {
                    filterList.length>0?filterList.map((item,idnex)=>{
                        return <MyCouponItem info={item} language={language} />
                    }):<NoCoupons language={language} />
                }
            </div>

            
        </div>
    )
}