import React from 'react';
import './tabminerhosting.scss';
import TabTelegram from '../TelegramEmail/TelegramEmail';
import HostImg from '../../assets/tab4.png';

export default function TabMinerHosting({language}){
    return (
        <div className='miner-hosting'>
            <div className='hosting-box'>
                <div className='hosting-img'>
                    <img className='img' src={HostImg} alt='' />
                </div>
                <div className="host-text">
                    <h3 className='title'>{language.SelfcontainedMineLevel}</h3>
                    <p className='desc'>
                        {language.SelfcontainedMineLevelDesc}
                    </p>
                </div>
            </div>
        </div>
    )
}