import React, { useEffect, useState,useContext } from 'react';
import './news.scss';
import { Typography } from 'antd';
import lsRequest from '../../utils/lsRequest';
import bannerImg from '../../assets/news_list_top.png';
import NoData from '../../components/NoData/NoData';
import { LanguageContext } from '../../contexts/LanguageContext';

const { Paragraph } = Typography;


export default function News(){
    const [isMore,setIsMore] = useState(false);
    const [newsList,setNewsList] = useState([]);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getNewsList = async()=>{
        const resData = await lsRequest('/memberLog','POST',{l:language.requestType},'');
        if(resData.data.list){
            setNewsList(resData.data.list);
        }
    }

    useEffect(()=>{
        getNewsList()
    },[])

    return (
        <div className='body-content news-box-body' style={{backgroundColor:'#edeef4'}}>
            <div className='h-w1300'>
                <img className='banner' src={bannerImg} alt='' />

                {
                    newsList.length>0?<div className='news-list'>
                        {
                            newsList.map((item,index)=>{
                                return  <div className='news-item'>
                                    <img className='news-item-cover' src={item.cover} alt='' />
                                    <div className='news-item-right'>
                                        <h3 className='news-item-title'>{item.title}</h3>
                                        <p className='news-item-time'>{item.createDate}</p>
                                        <p className='news-item-desc'>
                                            <Paragraph ellipsis={{rows: 4 }}>{item.content}</Paragraph>
                                        </p>
                                    </div>
                                </div>
                            })
                        }
                    </div>:<div style={{marginTop:'80px'}}><NoData language={language} /></div>
                }
                

                {
                    newsList.length>0&&<div>
                        {isMore?<div className='more-news'>{language.moreTxt}</div>:<div className='more-news'>{language.noDataTxt}</div>}
                    </div>
                }
                
            </div>
        </div>
    )
}