import React,{useState} from 'react';
import './update.scss';
import {Button, Modal,Input,message } from 'antd';

export default function AddAddressModal({isShow,SetHandleCancel,setUpdateData,info,language}){
    const [note,setNote] = useState('');

    const handleCancel = (state) => {
        SetHandleCancel(state,'');
    };


    const getNote=(e)=>{
        setNote(e.target.value);
    }

    const submitAddress=()=>{
        if(!note){
            message.info(language.enterAddressNoteTips);
            return false;
        }
        setUpdateData(info.id,note.trim());
    }

    return (
        <>
            <Modal open={isShow} onCancel={()=>handleCancel(false)} centered footer={null} width={460}>
                <div className="update-modal">
                    <div className="address-content">
                        <div className="address-title">{language.modifyRemarks}</div>
                        <div className='address-ipt'>
                            {language.addressTxt}： {info.address}
                        </div>
                        <div className='address-ipt'>
                            {language.noteTxt}：<Input onChange={getNote} defaultValue={info.note} size='large' placeholder="Enter address note" />
                        </div>
                        <div className='submit-address'>
                            <Button onClick={()=>submitAddress()} className="ls-btn" shape='round' type="primary" size='large' block>{language.confirmBtnTxt}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}