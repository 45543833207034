import React from 'react';
import './disclaimer.scss';

export default function Disclaimer({language}){
    return (
        <div className='disclaimer-content'>
            {
                language.disclaimerList.map((item,index)=>{
                    return<p key={index} className='disclaimer-item'>{item.txt}</p>
                })
            }
            
        </div>
    )
}