import React from 'react';
import './mycouponitem.scss';
import {RightCircleOutlined} from '@ant-design/icons';

const CouponAmount =({info})=>{
    switch (info.couponType) {
        case 1:
            return info.couponDiscount*100+'% off'
        case 2:
            return '$'+info.couponDiscount
        case 3:
            return info.couponDiscount+'T'
        default:
            return 0
    }
}

const CouponCondition = ({info})=>{
    switch (info.couponType) {
        case 3:
            return info.couponCondition+'T';
        default:
            return '$'+info.couponCondition;
    }
}

export default function MyCouponItem({info,language}){
    return (
        <div className='my-coupon-item'>
            <div className='left-con'>
                <div className='title'>- {language.bigCouponTxt} -</div>
                <div className='num'>
                    <CouponAmount info={info} />
                </div>
                <p className='info'>
                    {language.applicableOver} <CouponCondition info={info} />
                </p>
                <p className='info'>{info.couponName}</p>
            </div> 
            <div className='right-con'>
                <div className='title'>ID：{info.couponNo}</div>
                <div className='rules'>
                    {language.rulesTxt} <RightCircleOutlined />
                </div>
                <p className='info'>{info.endTime}</p>
                {info.couponStatus===1&&<button className='b-btn'>{language.getTxt}</button>}
            </div>
        </div>
    )
}