import React, { useEffect, useState,useContext } from 'react';
import { Link,useParams } from 'react-router-dom';
import './oredailyprofit.scss';
import {Breadcrumb,Table,DatePicker} from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';

const { RangePicker } = DatePicker;

export default function OreDailyProfit(){
    const [dailyList,setDailyList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [pageSize,setPageSize] = useState(20);

    const params = useParams();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const tableHeader = [
        {
            title:language.DateTxt,
            dataIndex:'releaseDate',
            value:'releaseDate',
            align:'center'
        },
        {
            title:language.IssuableTxt,
            dataIndex:'coinAmountOrigin',
            value:'coinAmountOrigin',
            align:'center'
        },
        {
            title:language.ActualIssuance,
            dataIndex:'actualAmount',
            value:'actualAmount',
            align:'center'
        },
        {
            title:language.managementExpense,
            dataIndex:'manageFee',
            value:'manageFee',
            align:'center'
        },
        {
            title:language.powerWastage,
            dataIndex:'calPower',
            value:'calPower',
            align:'center'
        }
    ]

    const getDailyList = async()=>{
        const resData = await lsRequest('/memberOre/getDigRecord','POST',{
            coinName:params.coinName,
            pageNum:currentPage,
            pageSize:pageSize,
            l:language.requestType,
            orderId:params.id
        },token);

        if(resData&&resData.code===200){
            setDailyList(resData.data.list);
        }
    }

    const changeDatePicker=(date,dateString)=>{
        console.log(dateString)
    }
    useEffect(()=>{
        getDailyList();
    },[])

    return (
        <div style={{padding:'15px 0'}}>
            <Breadcrumb
                items={[
                    {title: language.dashboardTitle},
                    {title: <Link to={'/dashboard/oreOrder'}>{language.myMinesTxt}</Link>},
                    {title: language.dailyEarningsTxt}
                ]}
            />
            <div className='daily-earning-title'>
                <div className='d-e-title'>{language.dailyEarningsTxt}</div>
                {/* <div style={{marginLeft:'15px'}}>
                    <RangePicker onChange={changeDatePicker} />
                </div> */}
            </div>
            <Table 
                style={{marginTop:'20px'}}
                pagination={{
                    defaultPageSize:pageSize,
                    defaultCurrent:currentPage,
                    position:['bottomCenter']
                }} 
                rowKey={'id'}
                columns={tableHeader} 
                dataSource={dailyList} 
                size="small" 
            />
        </div>
    )
}