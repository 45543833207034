import React,{useState,useContext,useEffect} from 'react';
import './navbar.scss';
import { NavLink,useNavigate } from 'react-router-dom';
import { Dropdown,Button,message,Tooltip } from 'antd';
import { useAccount,useDisconnect } from 'wagmi';
import { ProfileOutlined,SettingOutlined,DownOutlined } from '@ant-design/icons';

import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';

import LanguageToggle from '../switchLanguage/LanguageToggle';
import Logo from '../../assets/logo_o.png';
import Login from '../login/Login';
import Register from '../register/Register';
import giftIcon from '../../assets/gift-icon.png';

const LoginGroup = ({language})=>{
	const [toLoginStatus,setToLoginStatus] = useState(false);
	const [toSignupStatus,setToSignupStatus] = useState(false);
	const navigate = useNavigate();
	const { disconnect } = useDisconnect();
	const {token,toggleAuth,oldAdress} = useContext(AuthContext);
	const {address} = useAccount();

	let showAddr =address?`${address.slice(0,3)}***${address.slice(address.length-3,address.length)}`:'';
	
	// const getToLoginStatus=(status)=>{
	// 	setToLoginStatus(status);
	// }

	// const getToSignupStatus=(status)=>{
	// 	setToSignupStatus(status)
	// }

	const Logout = ()=>{
		message.info(language.logoutTips);
		disconnect();
		let obj = {
			token:'',
			authStatus:'',
			oldAdress:'',
			inviteCode:''
		}
		toggleAuth(obj);
	}

	const items = [
		{
			key: '0',
			label: (<div onClick={()=>navigate('/dashboard')}>
				<h4>{language.helloTitle}</h4>
				<p>{showAddr}</p>
			</div>)
		},
		{
			key: '1',
			label: (<div onClick={()=>navigate('/dashboard/myorders')}>{language.myOrderTitle}</div>),
			icon:<ProfileOutlined />
		},
		{
			key: '2',
			label: (<div onClick={()=>navigate('/dashboard/accountsetting')}>{language.setTtitle}</div>),
			icon:<SettingOutlined />

		},
		{
		  key: '3',
		  label: (<Button size='small' onClick={()=>Logout()}>{language.logoutTitle}</Button>),
		},
	];

	useEffect(()=>{
		if(oldAdress&&address!==oldAdress){
			message.info(language.addressChangeTips);
			setTimeout(() => {
				disconnect();
				let obj = {
					token:'',
					authStatus:'',
					oldAdress:'',
					inviteCode:''
				}
				toggleAuth(obj);
			}, 1500);
		}
	},[address])

	if(address&&token){
		return <>
			<Dropdown
				menu={{
					items,
					selectable: true
				}}
				placement="bottom"
				arrow
			>
				<span className='bitfont bityonghutouxiang'></span>
			</Dropdown>
			<button className="btn-dash" onClick={()=>navigate('/dashboard')}>{language.dashboardTitle}</button>
		</>
	}
	else{
		return<><Login toLoginStatus={toLoginStatus} setToLoginStatus={setToLoginStatus} setToSignupStatus={setToSignupStatus} language={language} /><Register toSignupStatus={toSignupStatus} setToSignupStatus={setToSignupStatus} setToLoginStatus={setToLoginStatus} language={language} /></>
	}
}


const Navbar = ()=>{
	const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;
	const navigate = useNavigate();

	// const items = [
	// 	{
	// 		key: '0',
	// 		label: (<div onClick={()=>navigate('/miner')}>{language.purchaseMiners}</div>)
	// 	},
	// 	{
	// 		key: '1',
	// 		label: (<div onClick={()=>navigate('/orepos')}>{language.rentalMinersTxt}</div>),
	// 	},
	// ];

	return(
		<nav className="navbar">
			<div className="container h-w1300">
				<NavLink to='/'>
					<img className="logo" src={Logo} alt="" />
				</NavLink>
				<ul className="navbar-center">
					<li className='nav-item'>
						{/* <NavLink className="nav-link" to='/cloud'>{language.cloudMiningTitle}</NavLink> */}
						<Tooltip placement="top" title={language.NotYetOpen} arrow={true}>
							<a className="nav-link" style={{'cursor':'default'}}>{language.cloudMiningTitle}</a>
						</Tooltip>
					</li>
					<li className='nav-item'>
						{/* <Dropdown
							menu={{
								items
							}}
							placement="bottom"
							arrow
							className="nav-link"
						>
							<div>
								{language.minerTitle} 
								<DownOutlined style={{marginLeft:'5px'}} />
							</div>
						</Dropdown> */}
						<Tooltip placement="top" title={language.NotYetOpen} arrow={true}>
							<a className="nav-link" style={{'cursor':'default'}}>{language.minerTitle}</a>
						</Tooltip>
					</li>
					<li className='nav-item'><NavLink className="nav-link" to='/orepos'>{language.rentalMinersTxt}</NavLink></li>
					{/* <li className='nav-item'><NavLink className="nav-link" to='/rewards'>REWARDS</NavLink></li> */}
					{/* <li className='nav-item'><NavLink className="nav-link" to='/news'>{language.newsTitle}</NavLink></li> */}
					<li className='nav-item'>
						<NavLink className="nav-link" to='/coupon'>
							{language.couponTitle}
							<img className='gift-icon' src={giftIcon} alt='' />
						</NavLink>
					</li>
				</ul>
				<div className="user-group">
					<LoginGroup language={language} />
					{/* <span className='bitfont bitAPPku'></span> */}
					<LanguageToggle />
				</div>
			</div>
		</nav>
	)
}

export default Navbar