import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import './freemining.scss';

export default function FreeMiningItem({info,packageType,language}){
    const navigate = useNavigate();
    return (
        <div className="free-item">
           <div className="item-top gray">
                <h3 className="item-day">{info.periodDays} {language.daysTxt}</h3>
                <div className='item-pic'>
                    <img className='cover' src={info.pics} alt='' />
                </div>
                <p className='item-ratio'>
                    {language.staticOutputRatio}: {info.staticOutputRadio}% 
                    <Tooltip placement="top" title={language.staticORfreeTips} arrow={true}>
                        <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'6px'}} />
                    </Tooltip>
                </p>
           </div>
            <div className="item-bottoem gray">
                <div className='item-tag'>{language.freeMiningTags}</div>
                <p className='free-num'>
                    {
                        packageType===2?<span>{info.calPower}{info.calPowerUnit} {language.trialCashTxt}</span>:
                        <span> 1{language.unitTxt}{info.calPower}{info.calPowerUnit}{language.minerTitle}</span>
                    }
                </p>
                <div className='item-btn'>
                    {info.remainGoodsNum===0&&<p className='out'>{language.soldOut}</p>}
                    <div className='buy' onClick={()=>navigate('/free/'+info.package_id)}>{language.newUserBtn}</div>
                </div>
            </div>
        </div>
    )
}