import React,{useState,useContext,useEffect} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import './easypay.scss';
import {Checkbox,Table,message } from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import lsRequest from '../../utils/lsRequest';
import { LanguageContext } from '../../contexts/LanguageContext';
import VerifyTipsModal from '../../components/VerifyTipsModal/VerifyTipsModal';
import ConfrimPayModal from '../../components/ConfirmPayModal/ConfirmPayModal';



export default function Pay(){
    const [isOpenVerify, setIsOpenVerify] = useState(false);
    const [isOpenPay,setIsOpenPay] = useState(false);
    const [orderInfo,setOrderInfo] = useState([{amount:0},{amount:0}]);
    const [packageInfo,setPackageInfo] = useState({});
    const [totalOldPrice,setTotalOldPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);
    const [agreeStatus,setAgreeStatus] = useState(false);

    const {token} = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const showOpenVerify = () => {
        setIsOpenVerify(true);
    };
    const handleVerifyOk = () => {
        setIsOpenVerify(false);
    };
    const handleVerifyCancel = () => {
        setIsOpenVerify(false);
    };


    const orderData = JSON.parse(localStorage.getItem('orderData'));
    const getOrderInfo = async()=>{
        const resData = await lsRequest('/powerPackage/getMinerPackageDetail','POST',{minerPackageId:params.id,l:language.requestType});
        setTotalPrice(orderData.total);
        setTotalOldPrice(orderData.total);
        if(resData&&resData.data){
            let data = resData.data;
            setPackageInfo(data);
            let newArr = [
                {
                    id:0,
                    name:data.package_name,
                    price:data.packageType==2?data.perFee+'/'+data.calPowerUnit+'/'+language.daysTxt:'$'+data.minerFee+'/'+language.unitTxt,
                    amount:data.packageType==2?orderData.amount+data.calPowerUnit:orderData.amount+language.unitTxt+`(${data.calPower}${data.calPowerUnit}/${language.unitTxt})`,
                    days:data.periodDays+language.daysTxt,
                    total:'$'+orderData.minerPrice
                },
                {
                    id:1,
                    name:data.packageType==2?language.serviceFeeTxt:language.electricityCosts,
                    price:data.packageType==2?'$'+data.serviceFee+'/'+data.calPowerUnit+'/'+language.daysTxt:'$'+data.serviceFee+'/'+language.unitTxt+'/'+language.daysTxt,
                    amount:data.packageType==2?orderData.amount+data.calPowerUnit:orderData.amount+language.unitTxt+`(${data.calPower}${data.calPowerUnit}/${language.unitTxt})`,
                    days:orderData.days+language.daysTxt,
                    total:'$'+orderData.servicePrice
                }
            ]
            setOrderInfo(newArr);
        }
        
    }

    const agreeChange = (e)=>{
        setAgreeStatus(e.target.checked);
    }

    const toPayOrder=()=>{
        if(!agreeStatus){
            message.info(language.submitAgreeTips);
            return false;
        }
        setIsOpenPay(true);
    }

    const submitOrder=async()=>{
        
        const resData = await lsRequest('/memberBill/createBill','POST',{
            goodsNumber:orderData.amount,
            buyElectricDay:orderData.days,
            minerId:params.id,
            couponId:'',
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            confirmOrder(resData.data);
        }
    }

    const confirmOrder = async(billId)=>{
        const resData = await lsRequest('/memberPower/submitOrderWithBalance','POST',{
            billIdStr:billId,
            coinName:'USDT',
            l:language.requestType
        },token);

        if(resData&&resData.code===200){
            message.success(language.paymentSuccessful);
            setTimeout(() => {
                navigate('/dashboard/myorders');
            }, 2000);
        }
    }

    useEffect(()=>{
        getOrderInfo();
    },[]);

    return (
        <div className='easypay-info h-w1300 body-content'>
            <div className='p-info-left'>
                <h3 className='pay-info-title'>{language.orderInformationTitle}</h3>
                {/* <p className='pay-info-output'>Order Information</p> */}
                <Table rowKey={'id'} size='small' pagination={false} columns={language.easypayTabHeader} dataSource={orderInfo} bordered/>
                <div className='output-info'>
                    <p className='p-attention'>{language.attentionTxt}:</p>
                    <p className='p-attention-item'>{language.attentionTips}</p>
                </div>
            </div>
            <div className='p-info-right'>
                <div className='p-info-right-item'>
                    <span className='lab'>{packageInfo.packageType===2?language.hashrateFeeTxt:language.minerFeeTxt}：</span>
                    <div className='price'>${orderData.minerPrice}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{language.orePrice}：</span>
                    <div className='price'>${orderData.positionFee}</div>
                </div>
                <div className='p-info-right-item'>
                    <span className='lab'>{packageInfo.packageType===2?language.serviceFeeTxt:language.electricityCosts}：</span>
                    <div className='price'>${orderData.servicePrice}</div>
                </div>
                <div className='p-info-right-total'>
                    <span className='lab'>{language.orderTotal}：</span>
                    <div className='price'>${totalPrice}</div>
                </div>
                <div className='accept-ruler'>
                    <Checkbox className="check" onChange={agreeChange}>{language.acceptTxt}</Checkbox>
                    {language.acceptAgreeTxt}
                </div>
                <div className='sub-order-btn ls-btn' onClick={()=>toPayOrder()}>{language.submitTxt}</div>
            </div>

            <ConfrimPayModal 
                isShow={isOpenPay} 
                SetConfrimPayStatus={setIsOpenPay} 
                submitOrder={()=>submitOrder()} 
                totalPrice={totalPrice} 
                language={language} 
            />

            <VerifyTipsModal 
                isOpenVerify={isOpenVerify} 
                handleVerifyCancel={handleVerifyCancel}
                handleVerifyOk={handleVerifyOk} 
                language={language} 
            />
        </div>
    )
}