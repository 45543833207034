import React,{Component,createContext} from 'react';

export const AuthContext = createContext();

class AuthContextProvider extends Component{
	state = {
		authStatus:localStorage.getItem('authStatus') || 0,
		token:localStorage.getItem('istk')||'',
		inviteCode:localStorage.getItem('inviteCode')||'',
		oldAdress:localStorage.getItem('oldAddress')||'',

	}
	
	toggleAuth = (Obj)=>{
		this.setState({
			token:Obj.token,
			authStatus:Obj.authStatus,
			inviteCode:Obj.inviteCode,
			oldAdress:Obj.oldAddress
		})
		setTimeout(() => {
			localStorage.setItem('authStatus',Obj.authStatus);
			localStorage.setItem('istk',Obj.token);
			localStorage.setItem('inviteCode',Obj.inviteCode);
			localStorage.setItem('oldAddress',Obj.oldAddress);
		}, 1500);
	}
	
	render(){
		return(
			<AuthContext.Provider value = {{...this.state,toggleAuth:this.toggleAuth}}>
				{this.props.children}
			</AuthContext.Provider>
		)
	}
}

export default AuthContextProvider;