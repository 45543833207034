import React from 'react';
import './telegramemail.scss';

export default function TabTelegram(){
    return (
        <div className="tab-miner-bottom">
            <div className="t-m-btn t-icon1">
                <div className="txt">
                    Telegram: 
                    <span className="url">https://t.me/hash_box</span>
                </div>
                <span className="bitfont bitgengduo1"></span>
            </div>
            <div className="t-m-btn t-icon2">
                <div className="txt">
                    Email: 
                    <span className="url">hashbox33@gmail.com</span>
                </div>
                <span className="bitfont bitgengduo1"></span>
            </div>
        </div>
    )
}