import React from 'react';
import './productdetail.scss';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';

export default function ProductDetail({info,language}){

    return (
        <div className='product-detail'>
            <h3 className='p-d-title'>{language.productDetails}</h3>
            <table className='detail-table' border={0} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td className='td'>
                            <p className='td-item'>{language.packageType}</p>
                            <p className='td-item'>
                                {language.fullRevenueTxt}
                                <Tooltip placement="top" title={language.fullRevenueTips} arrow={true}>
                                    <ExclamationCircleOutlined style={{color:'#a0a0a0',marginLeft:'6px'}} />
                                </Tooltip>
                            </p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.currencyTxt}</p>
                            <p className='td-item'>{info.digCoin}</p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.planDuration}</p>
                            <p className='td-item'>{info.periodDays} {language.daysTxt}</p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.hashrateFeeTxt}</p>
                            <p className='td-item'>
                                ${info.minerFee}/{info.calPowerUnit}/{language.daysTxt}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className='td'>
                            <p className='td-item'>{language.serviceFeeTxt}</p>
                            <p className='td-item'>
                                ${info.serviceFee}/{info.calPowerUnit}/Days
                            </p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.validTime}</p>
                            <p className='td-item'>{language.immediatelyTxt}</p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.miningPool}</p>
                            <p className='td-item'>{language.noDataTxt}</p>
                        </td>
                        <td className='td'>
                            <p className='td-item'>{language.revenueAddress}</p>
                            <p className='td-item'>{language.personalWallet}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}