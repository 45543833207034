import React from 'react';
import './tabminer.scss';

import TabMinerImg from '../../assets/tab3.png';

export default function TabMiner({language}){
    return (
        <div className="tab-miner">
            <div className="miner-box">
                <div className="img-box">
                    <img className='img' src={TabMinerImg} alt='' />
                </div>
                {/* <ul className="tab-miner-info">
                    {
                        language.tabMinerSales.map((item,index)=>{
                            return <li key={index} className={`item item${index+1}`}>
                                <h3 className="title">{item.title}</h3>
                                <p className="desc">{item.desc}</p>
                            </li>
                        })
                    }
                </ul> */}
                <div className="tab-miner-info">
                    <h3 className="title">{language.RentalTypeMineSite}</h3>
                    <p className="desc">{language.RentalTypeMineSiteDesc}</p>
                </div>
            </div>
        </div>
    )
}