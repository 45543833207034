import React, { useEffect, useState,useContext } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import FreeInfo from './FreeInfo/FreeInfo';
import GoodsDetailTitle from '../../components/GoodsDetailTitle/GoodsDetailTitle';
import Faq from '../../components/Faq/Faq';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import lsRequest from '../../utils/lsRequest';
import formatDecimal from '../../utils/formateDecimal';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { message } from 'antd';

export default function Free(){
    const [detaiInfo,setDetailInfo] = useState({});
    const [minerNum,setMinerNum] = useState(1);
    const [minerPrice,setMinerPrice] = useState(0);
    const [servicePrice,setServicePrice] = useState(0);
    const [serviceDay,setServiceDays] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const params = useParams();
    const navigate = useNavigate();
    const {token} = useContext(AuthContext);
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    const getDetailInfo = async()=>{
        const {data} = await lsRequest('/powerPackage/getMinerPackageDetail','POST',{minerPackageId:params.pid,l:language.requestType});
        let num = data.packageType==2?data.calPower:1;
        setMinerNum(num);
        setServiceDays(data.periodDays);
        setDetailInfo(data);
    }

    const initPrice = (num,days)=>{
        if(detaiInfo.packageType==2){
            // 算力
            setMinerPrice(formatDecimal(num*detaiInfo.minerFee/detaiInfo.calPower,3)); 
        }
        else{
            // 矿机
            setMinerPrice(formatDecimal(num*detaiInfo.minerFee,3)); 
        }
        setServicePrice(formatDecimal(num*detaiInfo.serviceFee*days,3));
        setTotalPrice(formatDecimal(parseFloat(minerPrice) + parseFloat(servicePrice)+parseFloat(detaiInfo.minerPositionFee),3));
    }

    const toPayOrder=()=>{
        if(!token){
            message.info(language.loginTips);
            return false;
        }
        let orderData={
            amount:minerNum,
            days:serviceDay,
            minerPrice:minerPrice,
            servicePrice:servicePrice,
            total:totalPrice,
            positionFee:detaiInfo.minerPositionFee
        }
        orderData = JSON.stringify(orderData);

        localStorage.setItem('orderData',orderData);

        navigate(`/easyPay/${detaiInfo.package_id}`);
    }

    useEffect(()=>{
        getDetailInfo();
    },[])

    useEffect(()=>{
        initPrice(minerNum,serviceDay);
    },[detaiInfo,minerPrice,servicePrice,minerNum,serviceDay])

    return (
        <div className='body-content'>
            <div className='h-w1300' style={{paddingBottom:'60px'}}>
                <FreeInfo info={detaiInfo} toPay={toPayOrder} language={language} />
                {/* <GoodsDetailTitle title={'Product Introduction'} />
                <StaticOutput /> */}
                <GoodsDetailTitle title={language.FAQsTxt} />
                <Faq language={language} />
                <GoodsDetailTitle title={language.disclaimerTxt} />
                <Disclaimer language={language} />
            </div>
        </div>
    )
}