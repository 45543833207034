import React, { useState } from 'react';
import './points.scss';
import { Table,Tooltip } from 'antd';
import {RightCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom'
import cardBg from '../../assets/card-bg.png';


const tableHader = [
    {
        title:'Time',
        dataIndex:'time',
        key:'time'
    },
    {
        title:'Type',
        dataIndex:'type',
        key:'type',
        filters:[
            {
                text: 'All',
                value: 'All',
              },
              {
                text: 'Order Remain',
                value: 'Remain',
              },
              {
                text:'History Fund',
                value:'history'
              },
              {
                text:'Buy',
                value:'buy'
              },
              {
                text:'Order refund',
                value:'refund'
              },
              {
                text:'Miner Coupon Transaction Cash Back',
                value:'miner'
              },
              {
                text:'Order Payment',
                value:'payment'
              },
              {
                text:'Service fee withholding',
                value:'service'
              },
              {
                text:'Rewards',
                value:'rewards'
              }
        ],
        onFilter:(value,record)=>record.type.startsWith(value),
        filterMultiple:false,
        filterSearch:true
    },
    {
        title:'Amount',
        dataIndex:'amount',
        key:'amount'
    },
    {
        title:'Balance',
        dataIndex:'balance',
        key:'balance'
    },
    {
        title:'Remark',
        dataIndex:'remark',
        key:'remark'
    }
]
export default function Points(){
    const [statementList,setStatementList] = useState();
    
    const tableChange = (pagination, filters, sorter, extra)=>{
        console.log('params', pagination, filters, sorter, extra);
    }

    return (
        <div className='point-body'>
            <p className='point-title'>Points</p>
            <div className='point-info'>
              <div className='card-box'>
                <div className='card-Ava'>Available Points</div>
                <div className='card-point'>0</div>
                <div className='card-no'>No. 202311070001622404</div>
                <div className='card-apply'>
                  <Tooltip placement='top' title={'Hashrate commodity order reduction and service fee withholding on the platform'} arrow={true}>
                    Applies to
                    <RightCircleOutlined style={{marginLeft:'5px'}} />
                  </Tooltip>
                </div>
                <img className='card-bg' src={cardBg} alt='' />
                <button className='card-btn'>Buy</button>
              </div>
              <div className='point-right'>
                <div className='point-right-title'>
                  <p className='txt'>About *** Points</p>
                </div>
                <p className='point-right-desc'>
                  It can pay for ***'s products and automatic service fee deductions.
                </p>
                <p className='point-right-desc'>
                  Points are equivalent to USD 1:1.
                </p>
                <p className='point-right-desc'>
                  Points are permanently valid, with no minimum threshold.
                </p>
                <div className='purchase-btn'>
                  <Link>
                    <i className='bitfont bitvipkehu'></i>
                    Purchase Products
                  </Link>
                </div>
              </div>
            </div>
            <p style={{padding:'50px 0 20px'}}>Statement</p>
            <Table size='small' columns={tableHader} dataSource={statementList} onChange={tableChange} />
        </div>
    )
}