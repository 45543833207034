import React,{useContext} from 'react';
import './dashboard.scss';
import { Link,Outlet,useLocation } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Dashboard(){
    const location = useLocation();
    const pathname = location.pathname;
    const {langType,en,hk} = useContext(LanguageContext);
	const language = langType==='en'?en:hk;

    return (
        <div className='body-content' style={{backgroundColor:'#edeef4',padding:'45px 0',boxSizing:'border-box'}}>
            <div className='dashboard-body h-w1300'>
                <div className='dashboard-menu'>
                    {
                        language.dashboardMenuList.map((item,index)=>{
                            return <div key={index}>
                                {
                                    item.iconName?<div className={`d-menu-item ${pathname===item.path?'active':''}`}>
                                        {
                                            item.path?<Link to={item.path}>
                                                <i className={`item-icon ${item.iconName}`}></i>
                                                {item.labText}
                                            </Link>:<span>
                                                <i className={`item-icon ${item.iconName}`}></i>
                                                {item.labText}
                                            </span>
                                        }   
                                    </div>:<div className={`d-menu-item-child ${pathname===item.path?'active':''}`}>
                                        <Link className='dot-box' to={item.path}>{item.labText}
                                            {
                                                item.showRedDot&&<span className='dot'></span>  
                                            }
                                        </Link>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>
                <div className='dashboar-content'>
                    <Outlet />
                </div> 
            </div>
            
        </div>
    )
}