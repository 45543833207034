import React from 'react';
import './wallettags.scss';

export default function WalletTags({tags,tagsIndex,getTagesIndex}){

    const setTags = (index)=>{
        getTagesIndex(index)
    }

    return (
        <div className='tags-list'>
            {
                tags.map((item,index)=>{
                    return <span key={index} onClick={()=>setTags(index)} className={`tags-item ${tagsIndex===index?'active':''}`}>{item.title}</span>
                })
            }
        </div>
    )
}