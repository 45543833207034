import React from 'react';
import './couponitem.scss';


export default function CouponItem({info}){
    return (
        <div className='coupon-item'>
            <h4 className='title'>{info.title}</h4>
            <div className='price'><i className='fuhao'>$</i>{info.price}</div>
            <p className='desc'>Expiration：Valid for 30 days after receiving</p>
            {
                info.isClaim ? <button className='claim-btn ls-btn'>Claim Now</button> : <button className='claim-btn ban'>Claim Now</button>
            }
        </div>
    )
}